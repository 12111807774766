.page--404 {

    display: flex;
    align-items: center;
    height: 100vh;  
    padding: 0 !important;

    .description {
      font-weight: 300 !important;
    }

}
