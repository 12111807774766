.boxesGrid {
  position: relative;

  .bgText{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    opacity: 0.6;
    pointer-events: none;

    &__inner {
      position: absolute;

      &--01{
        top: -5em;
        right: -1em;

        @media (max-width: $breakpoint-xs) {
          top: -3em;
        }
      }

      &--02{
        top: 34%;
        left: -1em;
        transform: translateY(-50%);
      }

      &--03{
        bottom: 7em;
        right: -2em;
      }

      p{
        font-size: 6vw;
        letter-spacing: -0.03em;
        font-weight: 500;
        margin: 0;
        padding: 0;
        line-height: 120%;
        color: #eaeaea;
        white-space: nowrap;

        @media (max-width: $breakpoint-md) {
          font-size: 7vw;
        }

        @media (max-width: $breakpoint-sm) {
          font-size: 10vw;
        }

        @media (max-width: $breakpoint-sm) {
          font-size: 13vw;
        }
      }
    }

    
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8em;
    padding-bottom: 5em;
    position: relative;
    z-index: 2;

    @media (max-width: $breakpoint-md) {
      margin: 0 -5em;
    }

    @media (max-width: $breakpoint-sm) {
      margin: 0 -2em;
    }
  }

  &__item {
    width: 25%;
    padding: 0 2em;
    margin-bottom: 5em;

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3),
    &:nth-last-child(4){
      margin-bottom: 0;
    }

    @media (max-width: $breakpoint-md) {
      padding: 0 1em;
    }

    @media (max-width: $breakpoint-sm) {
      width: 33.33333%;

      &:nth-last-child(3),
      &:nth-last-child(4){
        margin-bottom: 5em;
      }
    }
    
    @media (max-width: 600px) {
      margin-bottom: 1.5em;
      width: 50%;
      
    }
  }

  &__item--expandMobile {
    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }
}

.capabilities-intro{
  width: 100%;
  padding-bottom: 10em;

  @media (max-width: $breakpoint-xs) {
    padding-bottom: 5em;
  }

  &Cont{
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .title{
    flex: 0 0 auto;
    margin: 0 1.3em 0 0;
    padding: 0 1.5em 0 0;
    position: relative;
    background-color: #fff;

    @media (max-width: $breakpoint-md) {
      padding: 0 1.3em 0 0;
    }

    @media (max-width: $breakpoint-xs) {
      padding: 0 0 0.5em 0;
      margin: 0;
      width: 100%;

      &:after{
        display: none;
      }
    }

    strong{
      color: inherit;
      font-weight: 400;
    }

    &:after{
      content: "";
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      border-right: 1px solid #000;
      height: 90%;
      position: absolute;
    }
  }

  .description{
    flex: 1 1 auto;
    min-width: 0.1em;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.capability {
  width: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;
  

  &:hover {
    .capability__icon {
      svg {
        transform: scale(1.4);

        &.inverse {
          fill: $color-black-main !important;
        }
      }
    }

    .btnLink--arrowed {
      .arrow-icon--circle {
        stroke-dashoffset: 0;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  &__title {
    font-weight: 700;
    font-size: 1.4em;
    text-transform: uppercase;
    color: $color-black-main;
    margin: 0 0 1em;
    transition: color 0.25s ease 0s;
    position: relative;
    letter-spacing: 0.08em;

    @media (max-width: $breakpoint-md) {
      font-size: 1.3em;
    }
  }

  &__arrow {
    display: block;
    width: 40px;
    margin: 0 auto;
  }

  .btnLink--arrowed.capability__arrow:hover {
    .arrow-icon {
      transform: translate3d(0px,0,0);
    }
  }

  &__icon {
    width: 45%;
    margin: 0 auto 4em;

    @media (max-width: $breakpoint-s) {
      margin-bottom: 2em;
    }

    svg {
      width: 100%;
      display: block;
      transition: all 0.5s cubic-bezier(0.11, 0.76, 0.42, 0.99);
      transform: scale(1);

      g {
        transition: none;
      }
    }
  }
}

.capabilities {
  &Detail {
    &:not(:last-child) {
      border-bottom: 1px solid #dddddd;
      margin: 0 0 5em;
    }

    .btnLink {
      display: flex;
      align-items: center;
    }

    &__title {
        font-size: 3.6em;
        font-weight: 100;
        letter-spacing: -0.03em;
        margin: 0.67em 0 1.5em;
        line-height: 135%;
        @media (max-width: $breakpoint-xs) {
            font-size: 3em;
        }
        strong{
            font-weight: 400;
            color: $color-black-main;
        }
    }

    &__subtitle {
        font-size: 2.4em;
        font-weight: 100;
        line-height: 1.5em;
        margin: 0 0 2.29em;
        padding-left: 11.55%;

        @media (max-width: $breakpoint-sm) {
            padding-left: 8%;
        }
        
        @media (max-width: $breakpoint-xs) {
            padding-left: 0;
            font-size: 2em;
        }

        strong{
            font-weight: 100;
            color: $color-primary;
        }
    }

    &__content {
        font-size: 1.7em;
        line-height: 1.77em;
        margin: 0 0 5em;
        padding-left: 11.55%;
        color: rgba(0,0,0,0.5);
        font-weight: 300;
        letter-spacing: -.01em;
        
        @media (max-width: $breakpoint-sm) {
            padding-left: 8%;
        }
        
        @media (max-width: $breakpoint-xs) {
            padding-left: 0;
        }

        p, 
        ul {
          margin: 0 0 1.4em;
        }

        ul {
          padding: 0;

          li{
            list-style: none;

            &:before {
              content: ' ';
              background-color: $color-primary;
              margin-right: 10px;
              width: 5px;
              height: 5px;
              display: inline-block;
              border-radius: 100%;
            }
          }
        }
    }

    &__outsideImg {
        width: calc(15vw + 100%);
        height: auto;
        margin: 0 0 6em -15vw;
        @media (max-width: $breakpoint-sm) {
            width: calc(100% + 4em);
            margin-left: -2em;
        }
    }

    &__icon {
      fill: $color-black-main;
    }
  }

  &Row {
    padding: 3em 0 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    @media (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
      position: relative;
      overflow: hidden;
      color: #d4d4d4;

      @media (max-width: $breakpoint-sm) {
        flex: 1 0 33.3%;
        margin: 1em 0;
      }

      @media (max-width: $breakpoint-xs) {
        flex: 1 0 50%;
        margin: 1em 0;
      }

      &--active {
        .capabilitiesRow__icon {
          svg {
            transform: scale(1);
            fill: $color-black-main;
          }
        }

        .btnLink--arrowed {
          svg {
            transform: rotate(-90deg);
          }
          * {
              stroke: $color-gray-secondary;
          }
           .arrow-icon--circle {
              stroke-dashoffset: 0;
          }
        }
      }

      &:hover {
        .capabilitiesRow__icon {
          svg {
            transform: scale(1);
            fill: $color-black-main;
          }
        }
        
        .btnLink--arrowed {
          * {
              stroke: $color-gray-secondary;
          }
           .arrow-icon--circle {
              stroke-dashoffset: 0;
          }
        }
      }
    }

    &__icon {
      margin: 0 0 1em;

      svg {
        fill: #d4d4d4;
        transform: scale(0.7);
        transition: all .2s ease;
        max-width: 80px;
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: 1.3em;
      letter-spacing: 2.3px;
      color: $color-black-main;
    }

    .btnLink {
      margin: 1.5em 0 0;
    }

    .btnLink {
      display: block;
      width: 40px;
      margin: 15px auto 0;

      &--arrowed {
        &:hover {
          .arrow-icon {
            transform: translate3d(0px,0,0);
          }
        }
      }
    }
  }

  &__holder {
    position: relative;
    padding-bottom: 220px;
    .capabilitiesRow {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}


//PDF Viewer
.page-pdf-wiewer {

  #canvas-pdf {
    display:block !important;
    width: 100% !important;
    background: #000;
  }

  .pdf-pagination {
    padding: 0 0 5px;
    text-align: center;
    button {
      background: transparent;
      border: 0;
      &:first-child {
        margin-right: 10px;
        color: #000;
        text-indent: 0;
        .back {
          opacity: 1;
          filter: none;
        }
      }
    }
    .pagination-pages {
      display: inline-block;
      margin-left: 15px;
      font-size: 1.2em;
    }
  }

  .pdf-download {
    margin: 40px 0;
    display: block;
    text-align: center;
    .btn-download {
      background: $color-primary;
      padding: 12px 20px;
      text-align: center;
      font-size: 1.2em;
      text-transform: uppercase;
      letter-spacing: .1em;
      font-weight: 700;
      color: #fff;
      width: 300px;
      display: inline-block;
    }
  }

  @media (max-width: $breakpoint-sm) {
    padding-bottom: 0;

    .pdf-pagination, #canvas-pdf {
      display: none !important;
    }

    .pdf-download {
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        width: 100%;
      }
    }
  }

}

.page-capabilities{
  min-height: auto;
}
