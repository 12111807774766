.contact {
  &__form {
    margin: 0 0 3em;
  }
  &__social {
    margin: 3em 0;
  }
  &__address {
    @media (max-width: $breakpoint-xs) {
      margin-top: 1.5em;
    }
  }
}

.page-contact-us {

  .display-flex {
    @media (max-width: $breakpoint-xs) {
      flex-wrap: wrap;
      [class^='col-'] {
        flex: 0 1 100%;
      }
      .social__item--mobileIcons {
        width: 100%;
        font-size: 1.3em;
        margin: 4px 0;
        .social__text {
          display: block;
        }
      }
    }
  }

}
