h2,h3,h4,h5 {
  margin: 0;
}

a,
a:focus,
a:hover {
    color: inherit;
    text-decoration: none;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none;
}

.img-responsive {
  width: 100%
}

.hideScroll {
    overflow-y: hidden;
}

.margin-0 {
  margin: 0 !important;
}

.billboard {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #333;
    font-size: 6em;
    transform: translate(-50%,-50%);
}

.billboard__links {
    font-size: 0.5em;
    padding: 0;
    margin: 1em 0 0;
    list-style: none;
    text-align: center;
}

.title {
    font-size: 4.5em;
    letter-spacing: -0.02em;
    font-weight: 100;
    margin: 0 0 0.8em;
    line-height: 120%;
    @media(max-width: $breakpoint-s) {
        font-size: 3.1em;
    }
    strong {
      font-weight: 300;
    }

    &.title__large{
      font-size: 3.6em;

      @media(max-width: $breakpoint-md) {
          font-size: 3.4em;
      }
      @media(max-width: $breakpoint-sm) {
          font-size: 4em;
      }
      @media(max-width: $breakpoint-s) {
          font-size: 3em;
      }
    }
}

.title__highlight {
  font-weight: 300;
}

.description {
    font-size: 2em;
    line-height: 140%;
    margin: 0 0 2em;
    letter-spacing: 0.02em;
    font-weight: 300;
    color: rgba(0,0,0,.7);
    font-style: normal;
    @media(max-width: $breakpoint-xs) {
        font-size: 1.8em;
    }
    strong,
    span {
      font-weight: 400;
      color: inherit;
    }

}

.description__highlight {
    font-weight: 300;
}

.description__color {
    font-weight: 300;
    color: $color-primary;
}

.description--separator {
    margin-bottom: 5.16em;
}

.btnLink {
    display: inline-block;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    outline: none !important;

    &--arrowed {
        display: inline-block;
        .arrow-icon {
            position: relative;
            top: -1px;
            transition: transform 0.3s ease;
            vertical-align: middle;
            &--circle {
                transition: stroke-dashoffset 0.3s ease;
                stroke-dasharray: 95;
                stroke-dashoffset: 95;
            }
        }
    }
    &:hover {
      .arrow-icon {
          transform: translate3d(5px, 0, 0);
          &--circle {
              stroke-dashoffset: 0;
          }
      }
    }
    &--hover {
      .arrow-icon {
          transform: translate3d(5px, 0, 0);
          &--circle {
              transition: all 3s ease .5s;
              stroke-dashoffset: 0;
          }
      }
    }

    &--back {
        display: inline-block;
        font-size: 1.2em;
        text-transform: uppercase;
        font-weight: 700;
        position: relative;
        padding: .5em 0;
        min-width: 15px;
        color: $color-black-main;

        .back {
          transform-origin: center;
          transform: rotate(180deg);
          display: inline-block;
          margin-left: -10px;

          svg {
            top: 1px;
          }
        }
    }


    &--light {
      color: #fff;
      text-transform: none;
      font-weight: 300;
      font-size: 2em;
      letter-spacing: 0;
      padding-left: 1em;

      .arrow-icon {
        left: -0.5em;
      }

      &:hover {
        color: #fff;
      }
    }
}

.display-flex {
  display: flex;
  align-items: flex-end;
}

.grecaptcha-badge {
  opacity: 0;
  z-index: -1;
}
