section{
  &.fixed{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }

  &.spacer{
    width: 100%;
    position: relative;
    pointer-events: none;
    height: 100vh;
    scroll-snap-align: start;
    top: 0;
  }
}

/* Splash */
.splash {
    background: rgb(233,85,40);
    background: linear-gradient(52deg, rgba(233,85,40,1) 0%, rgba(255,98,0,1) 58%, rgba(240,165,58,1) 100%, rgba(240,165,58,1) 100%);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.slider{
    height: 100vh;
    width: 100%;
    background-color: #000;
    position: relative;
    overflow: hidden;
}

/* CTA Book */
.ctaBook {
    position: relative;
    background: #f1f1f1;

    &:before {
        content: "";
        width: 50%;
        height: 100%;
        background-color: #2f2f2f;
        position: absolute;
        top: 0;
        left: 0;
        @media(max-width: $breakpoint-xs) {
            display: none;
        }
    }

    &__info {
        padding-top: 6em;
        padding-bottom: 3em;
        @media(max-width: $breakpoint-xs) {
            padding-top: 2em;
            padding-bottom: 2em;
            width: 100%;
        }
    }

    &__description {
        font-size: 2em;
        letter-spacing: -0.02em;
        line-height: 1.5em;
        font-weight: 300;
        color: rgba(0,0,0,.5);
        margin-bottom: 3em;
    }

    &__image {
        position: relative;
        //background-color: #2f2f2f;
        width: 50%;
        padding-top: 6em;
        padding-bottom: 4.5em;
        -webkit-transform: translateX(50px);
        -moz-transform: translateX(50px);
        -ms-transform: translateX(50px);
        transform: translateX(50px);
        @media(max-width: $breakpoint-md) {
          padding-top: 8em;
        }
        @media(max-width: $breakpoint-sm) {
          padding-top: 11em;
          -webkit-transform: translateX(40px);
          -moz-transform: translateX(40px);
          -ms-transform: translateX(40px);
          transform: translateX(40px);
        }
        @media(max-width: $breakpoint-xs) {
            width: 100%;
            box-sizing: content-box;
            padding-top: 6em;
            padding-bottom: 0;
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
            overflow: hidden;
        }
    }

    &__img {
        display: block;
        margin-left: auto;
        max-width: 100%;
        height: auto;
        @media(max-width: $breakpoint-xs) {
            max-width: 65%;
            margin-right: auto;
            -webkit-transform: translateX(-16px);
            -moz-transform: translateX(-16px);
            -ms-transform: translateX(-16px);
            transform: translateX(-16px);
        }
    }

    &__info {
        position: relative;
        width: 50%;
        padding-left: 10%;
        padding-bottom: 12em;
        padding-top: 13em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        @media(max-width: $breakpoint-xs) {
            padding: 8em 3vw;
            width: 100%;
        }
    }

    &__inner {
        display: flex;
        @media(max-width: $breakpoint-xs) {
            display: block;
        }
    }

    .linkButton--ctaBook {
        margin-top: 15em;
        letter-spacing: 0.1em;
        //color: $color-primary;
        @media(max-width: $breakpoint-sm) {
            margin-top: 4.8em;
        }
        @media(max-width: $breakpoint-s) {
            margin-top: 2em;
        }
    }
}

/* About */
.homeAbout {
  background: $gradient-orange;
  padding: 5em 0;
  position: relative;
  overflow: hidden;
  @media(max-width: $breakpoint-sm) {
    padding: 4em 0 3em;
  }

  &__splash {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateX(0);
    opacity: 1;
    transition: all .5s cubic-bezier(0, 0.58, 0.08, 0.99);
  }

  &__timeline {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(150%);
    opacity: 0;
    transition: all .5s cubic-bezier(0, 0.58, 0.08, 0.99);
    .btnLink--back {
      position: absolute;
      left: 1.5em;
      top: 50%;
      margin-top: -18px;
    }
  }


  &.start {
    .homeAbout__splash {
      transform: translateX(-150%);
      opacity: 0;
    }
    .homeAbout__timeline {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: $breakpoint-sm) {
      flex-direction: column;
      text-align: center;
    }
    .col {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 130px;
      margin: 50px 0;
      @media(max-width: $breakpoint-sm) {
        width: 100%;
        margin: 2em 0;
      }
      &:first-child {
        padding: 0;
        width: 300px;
      }
      &:nth-child(2) {
        @media(max-width: $breakpoint-sm) {
          margin-bottom: 0;
        }
      }
      &:last-child {
        border-left: 1px solid rgba(0,0,0,.2);
        @media(max-width: $breakpoint-sm) {
          display: none;
        }
      }
    }
  }

  &__logo {
    position: relative;
    width: 310px;
    height: 130px;
    overflow: visible;

    @media(max-width: $breakpoint-sm) {
      transform: scale(.8);
    }

    @media(max-width: $breakpoint-xs) {
      float: none;
      margin: 0 auto;
      transform-origin: initial;
    }

    &--white{
      padding: 8px 0 0;
      width: 150px;
      height: auto;

      img{
        width: 100%;
        height: auto;
      }

      & > svg{
        width: 100%!important;
        height: auto!important;
        display: block!important;
      }

      @media(max-width: $breakpoint-sm) {
        transform: none;
        padding: 0 0 20px;
        width: 120px;
        margin: 0 auto;
      }
    }

    div {
      height: 130px;
      position: absolute;
      &:nth-child(1) {
        left: 0;
        top: 0;
        svg {
          opacity: .3;
        }
      }
      &:nth-child(2) {
        left: 103px;
        top: -50px;
        height: 120px;
        svg {
          height: 120px;
        }
      }
      &:nth-child(3) {
        left: 170px;
        top: -2px;
        height: 134px;
        svg {
          height: 134px;
        }
      }
      &:nth-child(4) {
        left: 215px;
        top: -2px;
        svg {
          opacity: .3;
        }
      }
    }

    svg {
      fill: #ffffff;
      height: 130px;
    }
  }

  &__title {
    font-size: 4em;
    letter-spacing: -0.02em;
    font-weight: 300;
    line-height: 120%;
    margin: 0;
    color: rgba(0, 0, 0, 0.7);
    @media(max-width: $breakpoint-sm) {
      font-size: 3.6em;
      margin-bottom: 5px;
    }
    strong {
      font-weight: 300;
      color: #ffffff;
    }
  }
  &__label {
    font-size: 1.8em;
    letter-spacing: -0.01em;
    font-weight: 300;
    line-height: 120%;
    color: rgba(255, 255, 255, .7);
    margin: 0;
  }

  &__cta {
    margin: 2em 0 0 35px;
    letter-spacing: 3px;
    height: 25px;
    font-size: 1.3em;
    display: none;
  }

  &__description {
      padding-left: 1em;
      font-size: 1.7em;
      line-height: 1.5em;
      letter-spacing: -.02em;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.7);
      font-style: normal;
      margin: 0;
      @media(max-width: $breakpoint-sm) {
          line-height: 1.77em;
          padding: 0;
          border: none;
      }
      a {
        color: rgba(255,255,255, 1);
        font-weight: 400;
        //border-bottom: 1px solid;
      }
  }

}

.homeAboutV2 {
  background: #000;
  position: relative;
  overflow: hidden;

  &__splash {
    width: 100%;
    height: 100%;
    padding-top: 8em;
    padding-bottom: 8em;

    .container{
      position: relative;
      z-index: 2;
    }

    @media(max-width: $breakpoint-sm) {
      padding-top: 5em;
      padding-bottom: 5em;
    }
  }

  &__circlesBack{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    opacity: 0.36;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;

    .circlesBackItem {
      position: absolute;
      height: auto;
      width: 130em;

      @media(max-width: $breakpoint-md) {
        width: 110em;
      }

      img{
        width: 100%;
        height: auto;
        display: block;
      }

      &--blur1{
        bottom: -26em;
        left: 7em;

        @media(max-width: $breakpoint-md) {
          bottom: -20em;
          leftt: -20em;
        }

        @media(max-width: $breakpoint-sm) {
          bottom: 0em;
          left: -48em;
        }

        @media(max-width: $breakpoint-s) {
          bottom: 20em;
          left: -55em;
        }
      }

      &--blur2{
        top: -34em;
        right: -34em;

        @media(max-width: $breakpoint-md) {
          top: -23em;
          right: -23em;
        }

        @media(max-width: $breakpoint-sm) {
          top: -2em;
          right: -38em;
        }

        @media(max-width: $breakpoint-s) {
          top: 8em;
          right: -35em;
        }
      }
    }
  }

  .flex {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media(max-width: $breakpoint-sm) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .col {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @media(max-width: $breakpoint-sm) {
        width: 100%;
        justify-content: center;
      }

      @media(max-width: $breakpoint-s) {
        flex-direction: column;
      }

      &-half{
        align-items: stretch;
        flex: 1 1 auto;
        width: 100%;
        min-width: 0.1em;
      }
    }
  }

  &__logo{
    &Circle {
      position: relative;
      width: 13em;
      height: auto;
      margin-right: 2.8em;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }

      @media(max-width: $breakpoint-md) {
        width: 12em;
      }

      @media(max-width: $breakpoint-s) {
        width: 10em;
        margin: 0 0 1em;
      }
    }

    &Text {
      font-size: 3.8em;
      letter-spacing: -0.02em;
      font-weight: 300;
      line-height: 120%;
      margin: 0;
      color: #fff;

      @media(max-width: $breakpoint-md) {
        font-size: 3.5em;
      }

      @media(max-width: $breakpoint-sm) {
        font-size: 3.3em;
      }

      @media(max-width: $breakpoint-s) {
        font-size: 3em;
        text-align: center;
      }

      span {
        display: inline-block;

        &.medium{
          font-weight: 500;
        }

        &.thin{
          font-weight: 100;
        }
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10em;

    @media(max-width: $breakpoint-md) {
      padding-left: 2em;
    }

    @media(max-width: $breakpoint-sm) {
      padding-left: 0;
      padding-top: 4.5em;
    }

    @media(max-width: $breakpoint-s) {
      flex-direction: column;
      padding-top: 3.5em;
    }

    &Item{
      padding: 2em 3em 0.5em;
      width: 45%;
      border-left: 1px solid #AFAFAF;
      position: relative;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -ms-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      flex: 0 0 auto;

      &:first-child{
        width: 55%;

        @media(max-width: $breakpoint-sm) {
          width: 50%;
        }

        @media(max-width: $breakpoint-s) {
          width: 100%;
        }
      }

      @media(max-width: $breakpoint-md) {
        padding: 1.5em 1.5em 0.5em;
      }

      @media(max-width: $breakpoint-sm) {
        text-align: center;
        width: 50%;

        &:first-child{
          border: none;
        }
      }

      @media(max-width: $breakpoint-sm) {
        padding: 0.5em 2.5em;
      }

      @media(max-width: $breakpoint-s) {
        padding: 0 0 3em;
        margin: 0 0 3em;
        border-left: none;
        width: 100%;

        &:first-child{
          &:after{
            content:"";
            width: 70%;
            background-color: #AFAFAF;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &:last-child{
          padding: 0;
          margin: 0;
        }
      }

      h3 {
        font-size: 1.5em;
        line-height: 120%;
        letter-spacing: 0.05em;
        font-weight: 900;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 1em;
        position: relative;
        z-index: 2;
      }

      p {
        font-size: 1.5em;
        line-height: 135%;
        letter-spacing: -0.02em;
        font-weight: 300;
        color: #fff;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 19em;

        @media(max-width: $breakpoint-sm) {
          max-width: 100%;
        }
      }

      span{
        position: absolute;
        top: -0.2em;
        left: 0.65em;
        width: 100%;
        font-size: 4.5em;
        line-height: 120%;
        letter-spacing: 0;
        font-weight: 900;
        color: rgba(255, 255, 255, 0.1);
        text-transform: uppercase;
        white-space: nowrap;
        pointer-events: none;
        user-select: none;

        @media(max-width: $breakpoint-md) {
          left: 0.4em;
          font-size: 3.5em;
        }

        @media(max-width: $breakpoint-sm) {
          left: 50%;
          transform: translateX(-50%);
          top: -0.3em;
          font-size: 2.7em;
        }

        @media(max-width: $breakpoint-s) {
          top: -0.6em;
        }
      }
    }
  }

}

.homeAboutV3 {
  background: #000;
  position: relative;
  overflow: hidden;
  display: none;

  &__splash {
    width: 100%;
    height: 100%;

    .container{
      position: relative;
      z-index: 2;
    }
  }

  &__circlesBack{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    opacity: 0.36;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;

    .circlesBackItem {
      position: absolute;
      height: auto;
      width: 130em;

      @media(max-width: $breakpoint-md) {
        width: 110em;
      }

      img{
        width: 100%;
        height: auto;
        display: block;
      }

      &--blur1{
        bottom: -26em;
        left: 7em;

        @media(max-width: $breakpoint-md) {
          bottom: -20em;
          leftt: -20em;
        }

        @media(max-width: $breakpoint-sm) {
          bottom: 0em;
          left: -48em;
        }

        @media(max-width: $breakpoint-s) {
          bottom: 20em;
          left: -55em;
        }
      }

      &--blur2{
        top: -34em;
        right: -34em;

        @media(max-width: $breakpoint-md) {
          top: -23em;
          right: -23em;
        }

        @media(max-width: $breakpoint-sm) {
          top: -2em;
          right: -38em;
        }

        @media(max-width: $breakpoint-s) {
          top: 8em;
          right: -35em;
        }
      }
    }
  }

  .flex {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media(max-width: $breakpoint-md) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .col {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @media(max-width: $breakpoint-sm) {
        width: 100%;
        justify-content: center;
      }

      @media(max-width: $breakpoint-s) {
        flex-direction: column;
      }

      &-half{
        align-items: stretch;
        flex: 1 1 auto;
        width: 100%;
        min-width: 0.1em;
      }
    }
  }

  &__title{
    height: 100%;

    &Cont{
      height: 100%;
      position: relative;
      padding-right: 31em;

      @media(max-width: $breakpoint-md) {
        padding-right: 22em;
      }

      @media(max-width: $breakpoint-s) {
        padding-right: 17em;
      }

      h2{
        color: #fff;
        line-height: 120%;
        font-weight: 600;
        letter-spacing: -0.01em;
        padding-top: 2.3em;

        @media(max-width: $breakpoint-md) {
          padding-top: 1.2em;
        }

        @media(max-width: $breakpoint-s) {
          font-size: 2.5em;
          padding-top: 1.5em;
        }
      }
    }

    &Svg{
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;

      &Item{
        display: block;
        height: auto;

        .svg,
        svg{
          width: 100%;
          height: auto;
          display: block;
        }

        &--3{
          transform: translate(1em, -9em);
          width: 18.6em;

          @media(max-width: $breakpoint-md) {
            transform: translate(1em, 2em);
            width: 11em;
          }

          @media(max-width: $breakpoint-s) {
            width: 9em;
          }
        }
  
        &--0{
          transform: translateY(9em);
          width: 20em;

          @media(max-width: $breakpoint-md) {
            transform: translateY(6em);
            width: 11em;
            margin-left: 2em;
          }

          @media(max-width: $breakpoint-s) {
            width: 9em;
            margin-left: 1.5em;
          }
        }
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 8em 0;
    width: 100%;
    max-width: 80em;
    margin: 0 auto;

    @media(max-width: $breakpoint-md) {
      padding: 14em 0 5em;
    }

    @media(max-width: $breakpoint-xs) {
      padding: 9em 0 5em;
      flex-direction: column;
      width: 100%;
      max-width: 60em;
    }

    &Item{
      padding: 0 0 0 2.5em;
      width: 52%;
      border-left: 1px solid #AFAFAF;
      position: relative;
      display: flex;
      -ms-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      flex: 0 0 auto;

      &:first-child{
        width: 48%;
        border: none;
        padding: 0 2.5em 0 3.5em;
      }

      @media(max-width: $breakpoint-md) {
        width: 50%;

        &:first-child{
          width: 50%;
          padding-left: 0;
        }
      }

      @media(max-width: $breakpoint-xs) {
        width: 100%;
        padding: 0;
        border-left: none;
        border-top: 1px solid #AFAFAF;
        padding-top: 2em;
        margin-top: 3em;

        &:first-child{
          width: 100%;
          padding: 0;
          margin: 0;
          border: none;
        }
      }

      h3 {
        font-size: 4em;
        line-height: 120%;
        letter-spacing: 0;
        font-weight: 900;
        color: #fff;
        margin-bottom: 0.3em;
        position: relative;
        z-index: 2;

        @media(max-width: $breakpoint-s) {
          font-size: 3em;
        }
      }

      p {
        font-size: 1.4em;
        line-height: 135%;
        letter-spacing: 0.01em;
        font-weight: 200;
        color: #fff;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 19em;

        @media(max-width: $breakpoint-md) {
          max-width: 100%;
          font-size: 1.5em;
        }
      }

      &:last-child{
        p{
          max-width: 16em;

          @media(max-width: $breakpoint-md) {
            max-width: 24em;
          }

          @media(max-width: $breakpoint-xs) {
            max-width: 100%;
          }
        }
      }
    }
  }

}

/* Works */
.homeWorks {
  padding: 8em 0;

  @media (max-width: $breakpoint-sm) {
    padding: 5em 0;
  }
}

.homeCapabilities {
  padding: 8em 0 0;

  @media (max-width: $breakpoint-sm) {
    padding: 5em 0 0;
  }
  .capabilitiesRow {
    border-top: none;
    padding-top: 4em;
    @media (max-width: $breakpoint-sm) {
      padding: 0;
    }
  }
  // .capabilitiesRow__title {
  //   color: $color-black-main;
  // }

  .capabilitiesRow__item {
    &:hover {
      .capabilitiesRow__icon {
        svg {
          fill: $color-black-main;
        }
      }
    }
  }
}

.homeBook25 {
  background: linear-gradient(-120deg, #808080, #bfbfbf);
  padding: 8em 0 5em;
  @media (max-width: $breakpoint-sm) {
    padding: 3em 0;
  }
}
