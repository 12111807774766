/* Fahrenheit Sans */
@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Hairline.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Hairline.woff") format("woff");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Thin.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Thin.woff") format("woff");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Light.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Regular.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Medium.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Semibold.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Bold.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Extrabold.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Extrabold.woff") format("woff");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Extrabold.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Extrabold.woff") format("woff");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Heavy.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Heavy.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Black.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans/FahrenheitSans-Black.woff") format("woff");
	font-weight: 1000;
	font-style: normal;
	font-display: swap;
}


/* Fahrenheit Sans UI */
@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Hairline.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Hairline.woff") format("woff");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Thin.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Thin.woff") format("woff");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Light.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Regular.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Medium.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Semibold.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Bold.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Extrabold.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Extrabold.woff") format("woff");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Extrabold.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Extrabold.woff") format("woff");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Heavy.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Heavy.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fahrenheit Sans UI";
	src: url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Black.woff2") format("woff2"),
		url("https://assets-website.451.com/assets/webfonts/fahrenheit-sans/fahrenheit-sans-ui/FahrenheitSansUI-Black.woff") format("woff");
	font-weight: 1000;
	font-style: normal;
	font-display: swap;
}
