.work {
  position: relative;
  overflow: hidden;
  height: 0;
  width: auto;
  margin: 0 -10px;

  &__all {
    margin: 3em 0;
  }

  &__filter {
    white-space: nowrap;
    overflow: auto;
    padding: 0 0 3em;

    @media(max-width: $breakpoint-sm) {
      text-align: left;
      padding: 0 0 2em;
    }

    button {
      color: $color-black-main;
      position: relative;
      background: none;
      font-weight: 300;
      font-size: 1.6em;
      letter-spacing: 0.03em;
      border: 0;
      margin-right: 0.3em;
      opacity: 1;
      transition: color 600ms ease;
      display: inline-block;
      padding: 0.1em 0.9em;

      &:last-child {
        margin: 0;
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   top: 110%;
      //   left: 50%;
      //   width: 5px;
      //   height: 5px;
      //   border-radius: 50%;
      //   background-color: $color-primary;
      //   transform: translateX(-50%);
      //   opacity: 0;
      // }

      span{
        position: relative;
        z-index: 1;
      }

      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 100%;
        border-radius: 2em;
        background-color: $color-black-main;
        opacity: 0;
        transition: opacity 300ms ease, width 300ms ease;
        z-index: 0;
        transform: translateX(-50%);
      }

      &:hover {
        color: #fff;

        &:after {
          opacity: 1;
          width: 100%;
        }

        @media(max-width: $breakpoint-sm) {
          color: $color-black-main;
        
          &:after {
            opacity: 0;
            width: 0;
          }
        }
      }

      &.active {
        color: #fff;

        &:after {
          opacity: 1;
          width: 100%;
        }
      }
    }
  }

  &__description{
    width: 100%;
    padding-bottom: 4em;

    h1{
      font-size: 4.5em;
      letter-spacing: -0.03em;
      font-weight: 100;
      margin: 0 0 0.8em;
      line-height: 110%;

      @media(max-width: $breakpoint-sm) {
        font-size: 4em;
      }

      @media(max-width: $breakpoint-s) {
        font-size: 3em;
      }
    }
  }

  /* clear fix */
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.workItem {
  display: flex;
  width: 33.3%;
  height: 400px;
  padding: 10px;

  &--sizer {
    width: 33.3%;
  }

  &--w2 {
    width: 66.6%;
  }

  &--h2 {
    height: 800px;
  }

  @media(max-width: $breakpoint-sm) {
    width: 100% !important;
    height: 70vw !important;
    max-height: 600px;
  }

  @media(max-width: $breakpoint-md) {
    height: 320px;
  }

  &__link {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
      //transition: all .2s ease;
      transition-duration: 0s!important;
    }

    &--alignBottom{
      img {
        object-position: center bottom;
      }
    }

    &--alignTop{
      img {
        object-position: center top;
      }
    }
  }

  &__info {
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, .8), rgba(0, 0, 0, .5));
    opacity: 0;
    transition: opacity .3s ease 0s;
    z-index: 3;
    padding: 2em 3em;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;

    @media(max-width: $breakpoint-sm) {
      opacity: 1;
      top: auto;
      bottom: 0;
      height: auto;
      padding: 15px;
      background: rgba(0, 0, 0, .5);
    }

    &:hover {
        opacity: 1;
    }

    .title {
        color: #ffffff;
        font-size: 3.8em;
        letter-spacing: -0.03em;
        margin: 0;
        line-height: 110%;

        @media(max-width: $breakpoint-sm) {
            font-size: 2.4em;
        }
        strong {
          font-weight: 100;
          color: $color-black-highlight;
        }
    }

    .description {
        color: $color-black-highlight;
        font-weight: 100;
        margin-bottom: .5em;
        @media(max-width: $breakpoint-sm) {
            display: none;
        }
    }

    .btnLink {
      font-size: 1em;
      @media(max-width: $breakpoint-sm) {
        position: absolute;
        bottom: 8px;
        right: 10px;
        font-size: 0;
      }

    }
  }
}

.work--c4 {
  .workItem{
    width: 25%;
    height: auto;
    padding: 0;

    &__data {
      position: relative;
      width: 100%;
      height: auto;
      padding-top: 100%;
      overflow: hidden;
  
      @media(max-width: $breakpoint-sm) {
        padding: 0!important;
      }
    }

    &__link {
      position: absolute;
      top: 10px;
      left: 10px;
      bottom: 10px;
      right: 10px;
      height: auto;
      width: auto;
    }

    &--sizer {
      width: 25%;
      
      @media(max-width: $breakpoint-md) {
        width: 33.3%;
      }
    }

    &__info {
      .title {
        font-size: 3em;

        @media(max-width: $breakpoint-sm) {
            font-size: 2.2em;
        }
      }

      .description {
        font-size: 1.6em;
        line-height: 160%;
      }
    }

    &:first-child(),
    &:nth-child(6n + 6),
    &:nth-child(6n + 7){
       width: 50%;

      .workItem__data {
        padding-top: 50%;
      }
    }

    &.lastFilteredItem{
      width: 25%!important;
    }

    @media(max-width: $breakpoint-md) {
      width: 33.3%!important;
      height: auto;

      &:nth-child(6n + 6),
      &:nth-child(6n + 7){
        .workItem__data {
          padding-top: 100%;
        }
      }

      &:first-child(),
      &:nth-child(4n + 4),
      &:nth-child(4n + 5){
        width: 66.6%!important;

        .workItem__data {
          padding-top: 50%;
        }
      }

      &.lastFilteredItem{
        width: 33.3%!important;
      }
    }

    @media(max-width: $breakpoint-sm) {
      width: 100%!important;

      &:first-child(),
      &:nth-child(4n + 4),
      &:nth-child(4n + 5),
      &:nth-child(6n + 6),
      &:nth-child(6n + 7){
        width: 100%!important;

        .workItem__data {
          padding-top: 100%;
        }
      }

      &.lastFilteredItem{
        width: 100%!important;
      }
    }
  }
}

.work--sportsBranding {
  display: none;
  margin-top: 1rem;
  position: relative;
  padding: 5em 0 0;
  
  @media (min-width: $breakpoint-sm) {
    padding: 8em 0 0;
  }

  & > .container {
    z-index: 2;
    position: relative;
  }

  &:after {
    content: '';
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    background-image: url('https://assets-website.451.com/assets/img/bg_sports_branding.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
    @media (min-width: $breakpoint-sm) {
      height: 850px;
    }
  }

  &--heading {
    display: none;
  }

  h2 {
    color: $color-white;
    font-size: 3em;
    font-weight: 100;
    margin-bottom: 2em;
    
    @media (min-width: $breakpoint-md) {
      font-size: 4em;
      max-width: 85%;
    }

    span {
      font-weight: 700;
      color: $color-white;
      margin-bottom: .5em;
      display: inline-block;
      // color: #FE4601; 
      // background-image: linear-gradient(45deg, #FE4601 12%, #FE9A01 74%); 
      // background-clip: text; 
      // -webkit-background-clip: text; 
      // -webkit-text-fill-color: transparent; 
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5em;
    margin: 0 -15px;
    
    @media (min-width: $breakpoint-sm) {
      grid-gap: 3.5em;
      grid-template-columns: repeat(2, 1fr);
      margin: 0;
    }

    .workItem {
      width: auto;
      height: 460px;
      padding: 0;

      &__data {
        width: 100%;
      }
      
      &:first-child {
        @media (min-width: $breakpoint-sm) {
          grid-column: 1/3;
          height: 800px;
        }
      }
    }

  }
}
