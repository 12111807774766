.cta {
  padding: 5em 0;
  max-width: 100vw;
  position: relative;
  overflow: hidden;

  &__title {
      font-size: 3em;
      font-weight: 100;
      letter-spacing: -0.025em;
      color: #999999;
      display: block;
      @media (max-width: $breakpoint-xs) {
          font-size: 2.4em;
      }
  }

  &__title strong {
      font-weight: inherit;
      //color: $color-black-main;
  }

  .btnLink {
    margin: 1em 0 0;
  }

  &--dark {
    background-color: $color-black-main;
    text-align: right;
  }

  &--footer {
    background: $gradient-orange;
    //margin-top: 8em;
    text-align: left;
    padding: 5em 0 4em;
    .title {
      margin: 0 0 .25em;
    }
    .description {
      color: rgba(0,0,0,.6);
      line-height: 120%;
      margin: 0;
    }
    .intro__img {
      @media (max-width: $breakpoint-sm) {
        margin: 0 0 3em -2em;
        max-width: 160px;
      }
    }
  }
}
