.social {
    list-style: none;
    padding: 0;
    margin: 0;

    &__title {
      color: $color-primary;
      font-weight: 300;
      font-size: 1.8em;
      margin: 0 0 10px;
    }

    &__item--mobileIcons {
        @media (max-width: $breakpoint-xs) {
            display: inline-block;
            margin: 1em;
            font-size: 1.6em;
            opacity: 0.7;
        }
        strong {
            font-size: 1.6em;
            opacity: 1;
            font-weight: 400;
            @media (max-width: $breakpoint-xs) {
                font-size: 1.4em;
            }
        }
    }

    &__item:not(:last-child) {
        margin-bottom: 1em;
    }

    &__link {
        font-size: 1.6em;
        opacity: 0.7;
        transition: opacity 0.3s ease 0s;
        letter-spacing: 0.01em;
        
        @media (max-width: $breakpoint-xs) {
            font-size: 1.4em;
        }
    }

    &__link:hover {
        opacity: 1;
    }

    &__link--featured,
    &__link--featured:active,
    &__link--featured:hover {
        color: $color-primary;
        font-weight: 700;
    }

    &__icon {
        display: none;
        @media (max-width: $breakpoint-xs) {
            display: block;
        }
    }

    &__text {
        @media (max-width: $breakpoint-xs) {
            display: none;
        }
    }

}
