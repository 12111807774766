.scrollDownArrow {
    position: absolute;
    left: 50%;
    bottom: 2em;
    margin-left: -20px;
    width: 38px;
    height: 60px;
    opacity: 1;
    z-index: 3;
    transition: opacity 1.5s ease;
    @media (max-width: $breakpoint-sm) {
        display: none;
    }
    &__mouse {
        width: 3px;
        padding: 10px 15px;
        height: 35px;
        border: 2px solid rgba(0,0,0,.2);
        border-radius: 15px;
        opacity: 1;
        box-sizing: content-box;
    }
    &__scroll {
        width: 3px;
        height: 10px;
        border-radius: 25%;
        background-color: rgba(0,0,0,.6);
        animation-name: scroll;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(.15,.41,.69,.94);
        animation-iteration-count: infinite;
    }
    &--hide {
        opacity: 0;
    }

    &--white {
      .scrollDownArrow__mouse {
        border: 2px solid rgba(255, 255, 255, .4);
      }
      .scrollDownArrow__scroll {
        background-color: #fff;
      }
    }

    &--w25 {
      left: 27vw;
    }
}

@keyframes scroll {
    0% {
        opacity: 0;
    }

    10% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(15px);
        opacity: 0;
    }
}
