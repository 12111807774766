html{
    // &.touchevents{
    //     &.snap{
    //         scroll-snap-type: y mandatory;
    //         scroll-behavior: smooth;
    //     }
    // }
    &.snap{
        scroll-snap-type: y mandatory;
        scroll-behavior: smooth;
    }
}

body {
    position: relative;
    font-size: 10px;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    font-weight: 300;
    overflow-y: scroll;
    background-color: #fff;
    min-width: 320px;
    &.overflow-hidden {
        overflow: hidden;
    }
}

#Page {
    @media(max-width: $breakpoint-sm) {
        font-size: 0.9em;
    }

    .titleModule,
    .innerIcon451,
    .float-title,
    .aboutUs-textQuotes{
        display: none;
    }

    &.newFontTest{
        font-family: 'Fahrenheit Sans UI', sans-serif;
        
        .header{
            &__inner{
                display: flex;
                justify-content: flex-end;
            }

            .menu-animation {
                transition: transform 1.3s cubic-bezier(.165,.84,.44,1);
                transform: translate3d(0, 110%, 0);

                &.animate{
                    transform: translate3d(0, 0, 0);
                }
            }

            &__logo {
                order: 2;

                &Svg{
                    &.menu-animation {
                        transition-delay: 0.5s;
                    }
                }
            }

            &__toggleMenu{
                order: 1;
                left: 1.5em;
                right: auto;
            }

            .nav{
                order: 1;

                @media (min-width: $breakpoint-sm) {
                    justify-content: flex-start;
                }

                &__inner {
                    @media (min-width: $breakpoint-sm) {
                        justify-content: flex-start;
                        margin-left: -15px;
                        margin-right: 0;
                    }
                }

                &__menu{
                    &__item{
                        &.menu-animation {
                            &:nth-child(1){
                                transition-delay: 0s;
                            }

                            &:nth-child(2){
                                transition-delay: 0.1s;
                            }

                            &:nth-child(3){
                                transition-delay: 0.2s;
                            }

                            &:nth-child(4){
                                transition-delay: 0.3s;
                            }

                            &:nth-child(5){
                                transition-delay: 0.4s;
                            }
                        }
                    }
                    
                    // &__links{
                    //     overflow: hidden;
                    // }

                    &__link {
                        padding: 0.45em 0.8em 0.4em;
                        font-weight: 300;
                        display: block;
        
                        &:after{
                            border-radius: 0.4em;
                        }
                    }
                }
            }

            &--darkLeft {
                .header__logo {
                    svg {
                        .change-path {
                            fill: #fff;

                            @media (max-width: $breakpoint-sm) {
                                fill: $color-black-main;
                            }
                        }
                    }
                }

                .nav{
                    &__menu{
                        &__link {
                            color: $color-black-main;

                            &--active, 
                            &:hover {
                                color: #fff;
                            }

                            &:after {
                                background-color: $color-black-main;
                            }

                            @media (max-width: $breakpoint-sm) {
                                color: #fff;
                            }
                        }
                    }
                }

                &.header--scrolled {
                    .nav{
                        &__menu{
                            &__link {
                                color: #fff;

                                &--active, 
                                &:hover {
                                    color: $color-black-main;

                                    &:after {
                                        background-color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .circleAnimated {
            &__secondaryText {
                .circleAnimated__moveText{
                    p{
                        letter-spacing: -5px;

                        @media (max-width: $breakpoint-md) {
                            letter-spacing: -4.5px;
                        }

                        @media (max-width: $breakpoint-xs) {
                            letter-spacing: -3px;
                        }

                        @media (max-width: $breakpoint-s) {
                            letter-spacing: -2.5px;
                        }

                        span {
                            font-weight: 100;
                        }
                    }
                    
                    &--small {
                        p {
                            letter-spacing: -2px;
                        }
                    }
                }
            }

            &__text {
                &Cont {
                    line-height: 100%;
                    letter-spacing: -12px;
                    font-weight: 100;
                    // overflow: hidden;

                    @media (max-width: $breakpoint-md) {
                        letter-spacing: -11px;
                    }

                    @media (max-width: $breakpoint-xs) {
                        letter-spacing: -7px;
                    }

                    @media (max-width: $breakpoint-s) {
                        letter-spacing: -4px;
                    }
                }

                &Part {
                    &.circleAnimated__textPoint {
                        span{
                            &.circleAnimated__point {
                                margin-bottom: 0.24em;
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        
        .mainSliderInterval{
            .mainSlider__info{
                &HideCont {
                    p{
                        font-weight: 200;
                    }
    
                    a {
                        font-weight: 300;
                        padding: 0.65em 1.5em 0.6em;
                    }
                }

                &Text {
                    h3 {
                        font-weight: 300;
                    }

                    p{
                        font-weight: 100;
                    }
                }

                &Link{
                    .btnLink--arrowed {
                        .arrow-icon--circle {
                            stroke: #fff;
                        }
                    }
                }
            }
        }

        .title {
            letter-spacing: 0;

            strong{
                font-weight: 100;
            }
        }

        .description {
            font-weight: 200;
        }

        .homeAboutV2 {
            display: none;
        }

        .homeAboutV3 {
            display: block;
        }

        .work{
            &__description{
                display: none;
            }

            &__filter {
                button{
                    padding: 0.25em 0.8em 0.2em;
                    font-size: 2.1em;
                    font-weight: 200;

                    &:after {
                        border-radius: 0.4em;
                    }
                }
            }
        }

        .titleModule{
            display: flex;
            align-items: flex-end;
            padding-bottom: 10em;
            position: relative;

            @media(max-width: $breakpoint-sm) {
                flex-direction: column;
                padding-bottom: 7em;
            }

            @media (max-width: $breakpoint-xs) {
                padding-bottom: 5em;
            }

            &__main{
                width: 100%;
                flex: 1 1 auto;
                min-width: 0.1em;

                h1{
                    font-size: 4.5em;
                    letter-spacing: -0.01em;
                    font-weight: 100;
                    margin: 0;
                    padding: 0;
                    line-height: 120%;

                    @media (max-width: $breakpoint-md) {
                        font-size: 3.9em;
                    }

                    @media (max-width: $breakpoint-xs) {
                        font-size: 3.5em;

                        br{
                            display: none;
                        }
                    }

                    @media (max-width: $breakpoint-xs) {
                        font-size: 3em;
                    }

                    & > span{
                        display: block;
                        line-height: 1.1;

                        // &.noMargin{
                        //     margin-bottom: 0;
                        // }
                    }

                    &.big{
                        font-size: 8em;

                        @media (max-width: $breakpoint-md) {
                            font-size: 6em;
                        }

                        @media (max-width: $breakpoint-xs) {
                            font-size: 5em;
                        }

                        @media (max-width: $breakpoint-s) {
                            font-size: 4em;
                        }
                    }
              
                    strong{
                        display: block;
                        font-size: 1.35em;
                        font-weight: 900;
                        line-height: 90%;
                        letter-spacing: -0.02em;
                        color: $color-gray-highlight;

                        span.break{
                            word-break: break-all;
                        }

                        &.small{
                            font-size: 0.95em;
                            line-height: 120%;
                        }

                        &.medium{
                            font-size: 1.3em;
                            line-height: 100%;
                        }

                        @media (max-width: $breakpoint-xs) {
                            font-size: 1.35em;
                            line-height: 100%;

                            span.break{
                                word-break: normal;
                            }

                            &.small{
                                font-size: 1em;
                                line-height: 100%;
                            }
    
                            &.medium{
                                font-size: 1.2em;
                                line-height: 100%;
                            }
                            
                            br{
                                display: none;
                            }
                        }
                    }
                }

                &--gradient{
                    h1{
                        background: rgb(251,67,20);
                        background: linear-gradient(40deg, rgba(251,67,20,1) 0%, rgba(253,212,41,1) 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        line-height: 120%;
                        padding: 0 4px 0 0;
                        display: inline-block;
                    }
                }
            }

            &__description{
                width: 61em;
                margin: 0 0 0 2.5em;
                padding: 0.5em 0 0 2.5em;
                border-left: 1px solid $color-black-main;

                @media(max-width: $breakpoint-sm) {
                    width: 100%;
                    margin: 3em 0 0 0;
                    padding: 0;
                    border: none;
                }

                &.mb{
                    margin-bottom: 1em;

                    @media (max-width: $breakpoint-md) {
                        margin-bottom: 0.5em;
                    }
                }

                p{
                    font-size: 2em;
                    font-weight: 200;
                    line-height: 130%;
                    margin: 0;
                    padding: 0;

                    @media (max-width: $breakpoint-md) {
                        font-size: 1.8em;
                    }

                    @media (max-width: $breakpoint-sm) {
                        font-size: 2em;
                    }

                    strong.highlight{
                        display: inline-block;
                        position: relative;
                        padding: 0 0.3em;
                        margin: 0 0.2em;

                        span{
                            color: #fff;
                            position: relative;
                            z-index: 1;
                            font-weight: 100;
                        }
  
                        &:before{
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 103%;
                            height: 100%;
                            transform: translate(-50%, -50%);
                            background-color: $color-black-main;
                            border-radius: 0.3em;
                        }
                    }
                }
            }

            &--mbSmall{
                padding-bottom: 5em;

                @media (max-width: $breakpoint-xs) {
                    padding-bottom: 2em;
                }
            }

            &--mbExtraSmall{
                padding-bottom: 3em;

                @media (max-width: $breakpoint-xs) {
                    padding-bottom: 2em;
                }
            }

            &--column{
                flex-direction: column;
                align-items: flex-start;

                .titleModule__main{
                    margin-bottom: 3em;
                }

                .titleModule__description{
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    border: none;

                    &.mb{
                        margin-bottom: 1em;
                    }
                }
            }
        }

        .capabilities{
            &Detail{
                &__icon{
                    fill: #D4D4D4;
                }

                &__subtitle{
                    margin: 0 0 1.6em;
                }

                &__subtitle,
                &__content{
                    font-size: 2em;
                    font-weight: 200;
                    line-height: 130%;
                    color: $color-gray-secondary;

                    @media (max-width: $breakpoint-xs) {
                        font-size: 1.9em;
                    }
                }
            }
        }

        .intro{
            &__text{
                .title{
                    font-size: 4.5em;
                    letter-spacing: -0.03em;
                    font-weight: 700;
                    margin: 0;
                    padding: 0;
                    line-height: 120%;
                    color: $color-gray-highlight;
                    margin-bottom: 0.5em;
                }
    
                .description{
                    font-size: 2em;
                    letter-spacing: -0.03em;
                    font-weight: 100;
                    margin: 0;
                    padding: 0;
                    line-height: 140%;
                    max-width: 18em;
    
                    strong.highlight{
                        display: inline-block;
                        position: relative;
                        padding: 0 0.3em;
                        margin: 0 0.2em;
    
                        span{
                            color: #fff;
                            position: relative;
                            z-index: 1;
                            font-weight: 100;
                        }
    
                        &:before{
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 103%;
                            height: 100%;
                            transform: translate(-50%, -50%);
                            background-color: $color-black-main;
                            border-radius: 0.3em;
                            margin-top: -0.1em;
                        }
                    }
                }
            }

            &__navTitle {
                font-weight: 700;
                font-size: 2em;
                margin-bottom: 1.6em;
                letter-spacing: 0.01em;
            }

            &__navLink {
                &.btnLink {
                    text-transform: uppercase;
                    font-size: 1.35em;
                    font-weight: 600;

                    @media (min-width: $breakpoint-sm) {
                        font-size: 1.2em;
                    }
    
                    .arrow-icon {
                        g{
                            stroke: $color-black-main;
                        }
                    }
                }
                
    
                span{
                    font-weight: 200;
                }
            }
        }

        .btnLink {
            text-transform: capitalize;
            font-size: 1.5em;
            font-weight: 600;
            letter-spacing: 0.03em;

            .arrow-icon {
                g{
                    stroke: $color-primary;
                }
            }
        }

        .page{
            &-careers{
                .intro__pattern{
                    background-color: #2B2B2B;
                    overflow: hidden;

                    .pattern{
                        display: none;
                    }

                    &Circle{
                        position: absolute;
                        width: 200%;
                        height: auto;
                        opacity: 0.3;
                        z-index: 1;

                        &--top{
                            bottom: -10em;
                            left: 0;
                        }

                        &--bottom{
                            top: 22%;
                            left: 0%;
                        }

                        svg{
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }
    
                    .float-title{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        width: 100%;
                        padding-top: 10em;
                        z-index: 2;
                        position: relative;

                        h2{
                            color: #fff;
                            font-weight: 900;
                            font-size: 8em;
                            line-height: 110%;
                        }
                    }
                }
            }

            &-about-us{
                 .aboutUs{
                    &-chartMainTitle {
                        h3 {
                            font-size: 2.5em;
                            letter-spacing: -0.03em;
                            font-weight: 900;
                        }
                    }
                 }
            }

            &-contact-us{
                .contact{
                    &__address{
                        &.description{
                            color: #000;
                            margin: 0;
    
                            strong{
                                font-weight: 700;
                            }

                            @media (max-width: $breakpoint-xs) {
                                margin: 0;
                            }
                        }
                    }

                    &__form{
                        max-width: 36em;
                        width: 100%;
                        margin: 0 0 0 auto;

                        @media (max-width: $breakpoint-xs) {
                            max-width: 100%;
                        }
                    }

                    &__social {
                        margin: 3em 0 0;

                        @media (max-width: $breakpoint-xs) {
                            margin: 4em 0;
                        }
                    }
                }

                .form{
                    &__select, 
                    &__textInput, 
                    &__textarea{
                        border-color: $color-gray-highlight;
                        color: $color-black-main;
                        width: 100%;
                        height: 2.8em;
                        font-size: 1.6em;
                        font-weight: 300;
                        letter-spacing: 0.02em;
                        border-radius: 0.5em;
                        padding: 0 1em;
                        background: #fff!important;
                        outline: 2px solid transparent;
                        transition: outline 0.3s ease;
                        outline-offset: -2px;
                        border: 1px solid #dcdcdc;
        
                        &:focus{
                            outline-color: $color-gray-highlight;
                            border-color: #dcdcdc;
                        }
        
                        &.error{
                            outline-color: red;
                            border-color: #dcdcdc;
                        }
                    }
        
                    &__textarea{
                        padding-top: 0.6em;
                    }
                }
            }

            &-news-detail{
                .form{
                    &__select, 
                    &__textInput, 
                    &__textarea{
                        border-color: $color-gray-highlight;
                        color: $color-black-main;
                        width: 100%;
                        height: 2.8em;
                        font-size: 1.6em;
                        font-weight: 300;
                        letter-spacing: 0;
                        border-radius: 0.5em;
                        padding: 0 1em;
                        background: #fff!important;
                        outline: 2px solid transparent;
                        transition: outline 0.3s ease;
                        outline-offset: -2px;
                        border: 1px solid #dcdcdc;
        
                        &:focus{
                            outline-color: $color-gray-highlight;
                            border-color: #dcdcdc;
                        }
        
                        &.error{
                            outline-color: red;
                            border-color: #dcdcdc;
                        }
                    }
        
                    &__textarea{
                        padding-top: 0.6em;
                    }
                }
            }

            &--circlePattern{
                &::before {
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0.5;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                    background-image: url('https://assets-website.451.com/assets/img/patterns/pattern09.svg');
                }
            }

            &--circlePatternFull{
                .circlePatternFull{
                    position: absolute;
                    top: 30em;
                    left: 0;
                    width: 100%;
                    z-index: 1;

                    &::before {
                        content: " ";
                        position: relative;
                        width: 100%;
                        height: auto;
                        padding-top: 105%;
                        opacity: 1;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: top center;
                        background-image: url('https://assets-website.451.com/assets/img/patterns/pattern10.png');
                        z-index: 0;
                        display: block;
                    }

                    &::after {
                        content: " ";
                        position: absolute;
                        width: 100%;
                        height: 70%;
                        opacity: 1;
                        background-repeat: no-repeat;
                        z-index: 1;
                        display: block;
                        background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
                        bottom: 0;
                        left: 0;
                    }
                }

                .container{
                    position: relative;
                    z-index: 2;
                }
            }

            &--padding {
                padding-top: 11em;
                
                @media (min-width: $breakpoint-sm) {
                    padding-top: 15em;
                }
            }
        }

        .form{
            &__submit{
                border-radius: 0.8em;
            }

            &__textarea{
                resize: none;
            }

            &__info {
                @media (max-width: $breakpoint-xs) {
                    top: 0;
                    bottom: auto;
                }
            }
        }

        .aboutUs{
            width: 100%;
            display: block;

            &-quotes{
                position: absolute;
                z-index: 2;
                width: 12rem;
                height: auto;
                opacity: 0.12;
                top: 50%;
                transform: translateY(-50%);

                @media(max-width: $breakpoint-md) {
                    width: 9rem;
                }

                @media(max-width: $breakpoint-sm) {
                    width: 7rem;
                }

                @media(max-width: $breakpoint-xs) {
                    width: 5rem;
                }

                svg{
                    width: 100%;
                    height: auto;
                    display: block;
                }
                
                &Top{
                    left: -6.5rem;
                    margin-top: -5rem;

                    @media(max-width: $breakpoint-md) {
                        left: -5.5rem;
                        margin-top: -4rem;
                    }

                    @media(max-width: $breakpoint-sm) {
                        left: -1rem;
                        margin-top: -3rem;
                    }

                    @media(max-width: $breakpoint-xs) {
                        margin-top: -4rem;
                    }

                    @media(max-width: $breakpoint-s) {
                        left: -0.5rem;
                        margin-top: -2rem;
                    }
                }

                &Bottom{
                    right: -3.2rem;

                    @media(max-width: $breakpoint-md) {
                        right: -2.5rem;
                    }

                    @media(max-width: $breakpoint-sm) {
                        right: -2rem; 
                    }

                    @media(max-width: $breakpoint-xs) {
                        right: -1.5rem;
                    }
                }
            }

            &-textQuotes{
                width: 100%;
                display: block;
                padding: 10em 0;
                position: relative;

                @media(max-width: $breakpoint-xs) {
                    padding: 4em 0 6em;
                }
    
                &Cont{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;

                    @media(max-width: $breakpoint-sm) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
    
                &Left{
                    flex: 0 0 auto;
                    position: relative;
                    z-index: 3;
    
                    h2{
                        font-size: 14.5em;
                        color: #fff;
                        line-height: 83%;
                        letter-spacing: -0.02em;
                        font-weight: 900;
                        text-align: right;
                        
                        span{
                            display: inline-block;
                            position: relative;
                        }

                        @media(max-width: $breakpoint-md) {
                            font-size: 12.5em;
                        }

                        @media(max-width: $breakpoint-sm) {
                            font-size: 10.5em;
                            text-align: left;

                            br{
                                display: none;
                            }
                        }

                        @media(max-width: $breakpoint-s) {
                            font-size: 7.5em;
                        }
                    }
                }
    
                &Right{
                    flex: 1 1 auto;
                    min-width: 0.1em;
                    width: 100%;
                    padding-top: 0.3em;
                    padding-left: 2.5em;
                    position: relative;
                    z-index: 3;

                    @media(max-width: $breakpoint-md) {
                        padding-top: 0.5em;
                    }

                    @media(max-width: $breakpoint-sm) {
                        padding-left: 0.5em;
                    }

                    @media(max-width: $breakpoint-s) {
                        padding-left: 0.2em;
                    }
    
                    h3{
                        font-size: 7em;
                        color: #fff;
                        line-height: 95%;
                        letter-spacing: -0.01em;
                        font-weight: 900;
                        padding-bottom: 0.3em;

                        span{
                            display: inline-block;
                            position: relative;
                        }

                        @media(max-width: $breakpoint-md) {
                            font-size: 5.8em;
                        }

                        @media(max-width: $breakpoint-sm) {
                            font-size: 4.8em;
                            letter-spacing: -0.01em;

                            br{
                                display: none;
                            }
                        }

                        @media(max-width: $breakpoint-s) {
                            font-size: 4em;
                        }
                    }
    
                    p{
                        font-size: 1.8em;
                        color: #B1B1B1;
                        line-height: 120%;
                        font-weight: 200;
                        padding: 0;
                        margin: 0;

                        @media(max-width: $breakpoint-md) {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .news {
            &Article {
                &__tag {
                    letter-spacing: 0.03em;
                    font-size: 1.5em;
                }

                &__header {
                    padding: 2em 0 .5em;
                }

                .form__info {
                    top: 0;
                }

                &__form {
                    margin: 2em 0;

                    p {
                        font-size: 1.8em;
                        letter-spacing: 0;
                        font-weight: 200;
                        line-height: 130%;
                        // color: $color-gray-highlight;
                    }
                }

                &__subtitle,
                &__content {
                    font-size: 2em;
                    letter-spacing: 0;
                    font-weight: 200;
                    line-height: 1.4;
                    // color: $color-gray-highlight;
                    
                    @media (max-width: $breakpoint-xs) {
                        font-size: 1.9em;
                    }

                    ul {
                        padding-left: 3rem;

                        @media (max-width: $breakpoint-s) {
                            padding-left: 2.5rem;
                        }

                        li {
                            padding: 0 0 6px 6px;
                        }
                    }

                    p{
                        strong {
                            font-weight: 600;
                        }

                        a {
                            text-decoration: underline;
                        }
                    }
                }

                &__content{
                    @media (max-width: $breakpoint-xs) {
                        margin: 0 0 3em;
                    }
                }

                &:not(:last-child) {
                    @media (max-width: $breakpoint-xs) {
                        margin: 0 0 2em;
                    }
                }
            }
        }

        .caseHeader{
            &__text {
                .title {
                    margin: 0 0 0.1em;

                    strong {
                        font-weight: 900;
                        letter-spacing: -0.01em;
                        color: $color-gray-highlight;
                    }
                }
            }
        }

        .footer{
            &__form{
                &Module {
                    .form {
                        &__inputWrapper {
                            .form__select, 
                            .form__textInput, 
                            .form__textarea {
                                font-weight: 200;
                                border-radius: 0.5em;
                            }
    
                            .form__select, 
                            .form__textInput{
                                padding-top: 0;
                            }
                        }
    
                        label{
                            &.error {
                                font-weight: 300;
                            }
                        }

                        &__submit{
                            &.btnLink--arrowed {
                                .arrow-icon {
                                    top: 0;
                                }
                            }
                        }
                    }
                }

                &Info {
                    max-width: 42em;

                    .description {
                        strong {
                            font-weight: 200;
                        }
                    }
                }
            }

            &__nav {
                ul {
                    li {
                        padding-bottom: 1.3em;

                        a {
                            font-weight: 200;
                            padding: 0.45em 0.8em 0.25em;

                            &:after{
                                border-radius: 0.4em;
                            }
                        }
                    }
                }
            }

            &__copy {
                font-weight: 300;
                letter-spacing: 0.035em;
            }

            .title {    
                strong{
                    font-weight: 900;
                    letter-spacing: -0.01em;
                }
            }
        }

        // .aboutUs-introCont,
        // .aboutUs-introSecondary,
        // .capabilities-introCont,
        // .aboutUs-textSecondary,
        // .capabilitiesDetail__title,
        // .page-contact-us h1.title{
        //     display: none;
        // }

        .aboutUs-intro,
        .capabilities-intro{
            padding-bottom: 0;
        }
    }

    .breadcrumb {
        display: flex;
        align-items: center;
        background-color: transparent;
        padding: 0;
        
        & > li {
            font-size: 1.5em;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            
            @media (min-width: $breakpoint-md) {
                font-size: 1.4em;
            }

            & + li {
                &:before {
                    display: block;
                    width: 1.2rem;
                    height: 1.2rem;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDcgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1LjVMMSAxMCIgc3Ryb2tlPSIjRkQ1RTAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K');
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: '';
                    margin-right: .5rem;
                    transform: translateY(-1px);
                }
            }

            a {
                &:hover {
                    &:not(.active) {
                        opacity: 0.8;
                    }
                }

                &.active {
                    font-weight: 600;
                    pointer-events: none;
                }
            }
        }
    }
}

/*Cookiebot
*************************************************/
#CybotCookiebotDialogTabContent,
#CybotCookiebotDialogBodyButtons {
	font-family: $font-family-main !important;
	font-weight: 400 !important;
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonDescription {
    font-weight: 500 !important;
}

/*Branding on the banner*/
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/*Branding on the Privacy trigger*/
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none;
}

#CybotCookiebotDialog {
    h2 {
        margin-bottom: 0 !important;
    }

    .CybotCookiebotScrollContainer {
        min-height: auto !important;
    }

}


#CybotCookiebotDialog.CybotEdge {
    @media (min-width: $breakpoint-md) {
        padding: 16px !important;
    }

    #CybotCookiebotDialogBodyEdgeMoreDetails {
        a:after {
            margin-left: .5em !important;
        }
    }

    .CybotCookiebotDialogContentWrapper {
        @media (min-width: $breakpoint-md) {
            max-width: 1170px !important;
        }
    }
    
    #CybotCookiebotDialogHeader {
        display: none;
    }

    .CybotCookiebotDialogBodyBottomWrapper {
        @media (min-width: $breakpoint-md) {
            margin-top: .5em !important;
        }
    }

    #CybotCookiebotDialogFooter {
        @media (min-width: $breakpoint-md) {
            position: relative !important;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.CybotCookiebotDialogBodyButton {
    border-radius: .8em !important;
    letter-spacing: 0.15em !important;
    margin-bottom: 0 !important;
}