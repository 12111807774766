.form {
    position: relative;
    overflow: hidden;

    &__fields {
      &.sent {
        transform: translateX(100%);
      }
    }

    &__info{
      position: absolute;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0s ease;
      &.active {
        opacity: 1;
        visibility: visible;
        transition: all .5s ease;
      }
    }

    &__message {
      p {
        color: rgba(0, 0, 0, 0.55);
        font-size: 1.8em;
        font-weight: 300;

        strong {
          font-size: 1.3em;
          font-weight: 300;
          margin: 0 0 1em;
          display: inline-block;
          font-weight: 300;
          color: $color-black-highlight;
        }
      }
    }

    &__icon {
      height: 70px;
      width: 70px;
      margin: 0 0 2em;

      svg {
        fill: $color-gray-secondary;
      }
    }

    &__inputRow {
        margin-bottom: 2em;
        position: relative;
        &:last-child {
          margin: 0;
        }
    }

    &__inputFile {
      padding: 0 0 30px;

      small {
        font-size: 12px;
        color: #666;
        float: right;
        letter-spacing: 0.01em;
      }

      .error{
        text-transform: none;
        letter-spacing: 0;
      }

      &__field {
        width: 100%;
        position: relative;

        input {
          opacity: 0;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          cursor: pointer;
        }
      }

      &__btn {
        margin: 0 0 5px;
        cursor: pointer;
        border: 2px dashed #adadad;
        background-color: #ffffff;
        color: #adadad;
        width: 100%;
        height: 80px;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.1em;
        line-height: 120%;
        font-weight: 700;
        padding-top: 8px;
        position: relative;
        letter-spacing: .1em;
        transition: all .2s ease;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

          .btnLink--arrowed {
              font-size: 0;
              height: 32px;
              transform: rotate(-90deg) translateX(10px);
              * {
                  stroke: #adadad;
              }
          }

          &:hover {
              border: 2px solid $color-black-main;
              background-color: #ffffff;
              color: $color-black-main;

              .btnLink--arrowed .arrow-icon {
                  //-webkit-transform: translate3d(5px, 0, 0);
                  //transform: translate3d(5px, 0, 0);
                  &--circle {
                      stroke-dashoffset: 0;
                  }
                  * {
                    stroke: $color-black-main;
                  }
              }
          }

          &.has-file {
            border: 2px solid $color-black-main;
            background-color: #ffffff;
            color: $color-black-main;
          }
      }
    }

    &__select,
    &__textInput,
    &__textarea {
        width: 100%;
        height: 3em;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #ebebeb;
        font-size: 1.5em;
        padding: 0;
        font-weight: 300;
        color: $color-black-main;
        border-radius: 0;
        background: transparent !important;

        @media(max-width: $breakpoint-md) {
            //font-size: 1.4em;
        }
    }
    
    &__select:focus,
    &__textInput:focus,
    &__textarea:focus {
        border-bottom: 1px solid #555555;
    }

    &__select.error,
    &__textInput.error,
    &__textarea.error {
        border-color: red;
    }

    &__textInput::placeholder,
    &__textarea::placeholder {
        opacity: 1;
        color: $color-black-main;
        text-transform: capitalize;
    }

    &__textInput::-webkit-input-placeholder,
    &__textarea::-webkit-input-placeholder {
        opacity: 1;
        color: rgba(0,0,0,.5);
        text-transform: capitalize;
    }

    &__textInput::-ms-input-placeholder,
    &__textarea::-ms-input-placeholder {
        opacity: 1;
        color: rgba(0,0,0,.5);
        text-transform: capitalize;
    }

    &__select {
        text-transform: capitalize;
        font-size: 1.1em;
        height: 3.81em;
    }

    &__textarea {
        //height: 16.25em;
        min-height: 10em;
        line-height: 1.7em;
        resize: vertical;
        max-height: 37em;
        padding-top: .9em;
    }

    &__submit {
        margin: 2em 0 0;
        outline: none;
        background-color: #000;
        color: #fff;
        width: 100%;
        text-align: left;
        text-transform: uppercase;
        font-size: 1.2em;
        line-height: 120%;
        padding: 0.3em 1em 0 2em;
        font-weight: 700;
        position: relative;
        letter-spacing: .15em;
        transition: all .2s ease;
        min-height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 2px solid $color-black-main;
        transition: background-color 300ms ease, color 300ms ease;
        border-radius: 5em;

        @media (max-width: $breakpoint-sm) {
            //width: auto;
            //padding-right: 10em;
        }

        &__arrow {
            font-size: 0;
            .arrow-icon {
              top: -2px;
            }

            * {
                stroke: #ffffff;
            }
        }

        &:hover {
            background-color: transparent;
            color: $color-black-main;

            .btnLink--arrowed {
              .arrow-icon {
                transform: translate3d(5px, 0, 0);
                
                * {
                  stroke: $color-black-main;
                }

                &--circle {
                    stroke-dashoffset: 0;
                }
              }
            }
        }
        &:focus {
            outline: none;
        }

        &.loading {
            .btnLink--arrowed .arrow-icon {
                &--circle {
                  stroke-dashoffset: 72;
                  animation-name: spin;
                  animation-duration: 2000ms;
                  animation-iteration-count: infinite;
                  animation-timing-function: linear;
                  transform-origin: initial;
                }
                &--arrow {
                  opacity: 0;
                }
            }
        }
    }

    label.error {
        color: red;
        margin-top: 3px;
        margin-bottom: 0;
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        bottom: -18px;
        left: 0;
        letter-spacing: 0.02em;
    }

    &__label {
      font-weight: 300;
      span {
        font-weight: 300;
      }
    }
}


@keyframes spin {
  from {
      transform: rotate(0deg);
  } to {
      transform: rotate(360deg);
  }
}