.book__form {
  max-width: 380px;
  margin-top: 5em;
  &__image {
    margin-top: 5em;
  }
}

/*
.bookHeader {
    //margin-top: 7.2em;
    //margin-bottom: 7.2em;
}

.bookHeader__description {
    font-size: 1.8em;
    font-weight: 300;
    line-height: 1.5em;
    margin: .6em 0 0;
    color: rgba(0,0,0,.5);
}

.bookHeader__img {
    width: 100%;
    height: auto;
}

.bookImage {
    position: relative;
    padding-bottom: 114.45%;
    @media(max-width: $breakpoint-sm) {
        display: none;
    }
}

.bookImage__img {
    width: 163%;
    height: auto;
    position: absolute;
    bottom: -11%;
    right: -2%;
    @media(max-width: $breakpoint-md) {
        width: 144%;
        bottom: 3%;
    }
}
*/
