/* Include Bootstrap */
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap.scss";

/* Include global */
@import './global/variables.scss';
@import './global/fonts.scss';
@import './global/global.scss';
@import './global/utilities.scss';
//@import './global/fa-all.scss';

/* Include plugins */
@import './plugins/selectric.scss';
@import './plugins/slick.scss';
@import './plugins/aos/aos.scss';
@import './plugins/video-js/video-js.scss';
@import './plugins/owl-carousel/owl.carousel.scss';

/* Include components */
@import './components/header.scss';
@import './components/nav.scss';
@import './components/footer.scss';
@import './components/social.scss';
@import './components/page.scss';
@import './components/mouse-scroll.scss';
@import './components/clients.scss';
@import './components/cta.scss';
@import './components/intro.scss';
@import './components/main-slider.scss';
@import './components/work.scss';
@import './components/case.scss';
@import './components/marquee.scss';
@import './components/timeline.scss';

/* Include sections */
@import './sections/home.scss';
@import './sections/about.scss';
@import './sections/the-book.scss';
@import './sections/careers.scss';
@import './sections/contact.scss';
@import './sections/form.scss';
@import './sections/capabilities.scss';
// @import './sections/capabilities-detail.scss';
// @import './sections/news.scss';
// @import './sections/blog.scss';
// @import './sections/news-detail.scss';
@import './sections/policies.scss';
@import './sections/policies-detail.scss';
// @import './sections/cases.scss';
@import './sections/animated.scss';
// @import './sections/circle-animation.scss';
@import './sections/text-animation.scss';
@import './sections/page-404.scss';

