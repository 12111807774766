.case {
  &Header {
    margin: 0;
    //height: 100vh;
    padding-top: 15em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @media (max-width: $breakpoint-xs) {
      padding-top: 10em;
    }

    &__text {
      padding-bottom: 5em;
      position: relative;

      &>.container {
        position: relative;
        // padding-top: 40px;
      }

      .title {
        margin: .67em 0 .25em;
      }

      .description {
        margin: 0;
      }
    }

    &__image {
      position: relative;
      overflow: hidden;
      flex: 1;
    }

    &__logoBlock {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      .container {
        position: relative;
        height: 100%;
      }
    }

    &__logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      &--yellowpepper,
      &--cartiga {
        top: 46%;
        max-width: 30em;

        @media (min-width: 768px) {
          max-width: 35em;
        }

        @media (min-width: 992px) {
          max-width: 40em;
        }

        @media (min-width: $breakpoint-md) {
          max-width: 58.2em;
        }
      }

      &--davita {
        top: 10.8%;
        left: 15px;
        transform: none;
        max-width: 30em;

        @media (min-width: 768px) {
          max-width: 35em;
        }

        @media (min-width: 992px) {
          max-width: 40em;
        }

        @media (min-width: $breakpoint-md) {
          max-width: 56.5em;
        }
      }

      &--crypto {
        height: auto;
        width: 35vw;

        svg {
          width: 100%;
          height: auto;
          display: block;
        }

        @media (min-width: $breakpoint-sm) {
          width: 26.2vw;
        }
      }

      &--exeed {
        height: auto;
        width: 33.75vw;

        svg {
          width: 100%;
          height: auto;
          display: block;
          margin-bottom: 5vw;
        }

        @media (min-width: $breakpoint-sm) {
          width: 30vw;
        }
      }


      &--salvio {
        height: auto;
        width: 17vw;

        svg {
          width: 100%;
          height: auto;
          display: block;
          margin-bottom: 5vw;
        }

        @media (min-width: $breakpoint-sm) {
          width: 13vw;
        }
      }

      &--ficohsasite {
        top: 46%;
        max-width: 30em;

        @media (min-width: 768px) {
          max-width: 35em;
        }

        @media (min-width: 992px) {
          max-width: 40em;
        }

        @media (min-width: $breakpoint-md) {
          max-width: 58.2em;
        }
      }

      img {
        position: relative;
        width: 100%;
        height: auto;
      }
    }

    &__img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      min-height: 380px;
    }

    // .btnLink--back {
    //   position: absolute;
    //   top: 0;
    //   left: 15px;
    // }
  }

  &SubHeader {
    &__image {
      position: relative;
      overflow: hidden;
      flex: 1 1 auto;
      width: 100%;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  &Preview {
    max-height: 55em;
    overflow: hidden;

    .btnLink {
      position: absolute;
      bottom: 0;
      right: 1em;
      transition: opacity 0.5s ease 0s;

      .arrow-icon {
        transform: rotate(90deg);
      }

      &:hover {
        .arrow-icon {
          transform: translate3d(0, 5px, 0) rotate(90deg);
        }
      }
    }

    &--expanded {
      max-height: 0;
      min-height: calc(var(--vh, 1vh) * 100);

      .btnLink {
        opacity: 0;
      }
    }
  }

  &Content {
    position: relative;
    z-index: 9;

    @media (max-width: $breakpoint-sm) {
      text-align: center;
    }

    img {
      max-width: 100%;
      height: auto;
      position: relative;

      &.full {
        object-fit: cover;
        height: 100%;
        width: 100%;
        min-height: 380px;
      }
    }

    .img {

      &--rotateLeft {
        transform: rotate(-9deg);
        z-index: 2;
      }

      &--rotateRight {
        transform: rotate(9deg);
        z-index: 1;
        margin-top: -15em;
      }

      &__card {
        border-radius: 35px;

        &--shadow {
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.05),
            0 0 2em rgba(0, 0, 0, 0.05),
            0 0 4em rgba(0, 0, 0, 0.05),
            0 0 8em rgba(0, 0, 0, 0.05);
        }
      }

      &__phone {
        border-radius: 30px;

        &--shadow {
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.05),
            0 0 2em rgba(0, 0, 0, 0.05),
            0 0 4em rgba(0, 0, 0, 0.05),
            0 0 8em rgba(0, 0, 0, 0.05);
        }
      }

      &__banner {
        border-radius: 15px;

        &--shadow {
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.05),
            0 0 2em rgba(0, 0, 0, 0.05),
            0 0 4em rgba(0, 0, 0, 0.05),
            0 0 8em rgba(0, 0, 0, 0.05);
        }
      }

      &__book {
        &--shadow {
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.05),
            0 0 2em rgba(0, 0, 0, 0.05),
            0 0 4em rgba(0, 0, 0, 0.05),
            0 0 8em rgba(0, 0, 0, 0.05);
        }
      }

      &__circle {
        border-radius: 100%;
        max-width: 80vw;

        &--shadow {
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.05),
            0 0 2em rgba(0, 0, 0, 0.05),
            0 0 4em rgba(0, 0, 0, 0.05),
            0 0 8em rgba(0, 0, 0, 0.05);
        }
      }

      &__screen {
        border-radius: 20px;
        margin-top: 4em !important;
        margin-bottom: 4em !important;

        &--shadow {
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.05),
            0 0 2em rgba(0, 0, 0, 0.05),
            0 0 4em rgba(0, 0, 0, 0.05),
            0 0 8em rgba(0, 0, 0, 0.05);
        }

        &--out {
          position: absolute;
          border-radius: 0;
          left: 50%;
          bottom: -13em;
          margin-left: -6em !important;
          min-width: 34.8em !important;

          @media (min-width: $breakpoint-xs) {
            bottom: -13em;
            margin-left: 14em !important;
            min-width: auto !important;
          }

          @media (min-width: $breakpoint-sm) {
            bottom: -11.7em;
            margin-left: 19em !important;
            min-width: auto !important;
          }

          @media (min-width: $breakpoint-md) {
            bottom: auto;
            top: 31.4em;
            margin-left: 37em !important;
            min-width: auto !important;
          }
        }
      }
    }

    .description {
      strong {
        font-weight: 400;
      }
    }

    section {
      &+section {
        margin-top: 10em;

        &.nomargin {
          margin: 0;
        }
      }
    }

    .grid-photos {
      display: flex;
      margin: 0 -1em;
      justify-content: center;

      img {
        padding: 1em;
      }

      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
      }
    }

    .flex-photos {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
      }

      img {
        margin: 1em 0;
      }

      &--nomargin {
        img {
          margin: 0;
        }
      }
    }

    .carousel-photos {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      text-align: center;

      img {
        margin: 0 1em;
        display: inline-block;
        max-width: 80vw;
      }
    }

    .space-photos {
      text-align: center;

      img {
        margin: 5em 0;

        @media (max-width: $breakpoint-sm) {
          max-width: 75vw;
        }
      }

      &--medium {
        img {
          margin: 1.5em 0;
        }
      }
    }

    .split-photos {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .col:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, .1);
      }

      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
      }

      img {
        padding: 5em 0;
        margin: 0 5em;

        @media (max-width: $breakpoint-sm) {
          max-width: 70vw;
        }
      }

      .col {
        @media (max-width: $breakpoint-sm) {
          border: none !important;
        }

        &--logo {
          img {
            @media (max-width: $breakpoint-sm) {
              max-width: 33em;
            }
          }
        }

        &--icon {
          img {
            @media (max-width: $breakpoint-sm) {
              max-width: 21em;
            }
          }
        }
      }

      &--nomargin {
        align-items: center;
        justify-content: space-between;

        .col {
          width: 100%;
          text-align: center;

          img {
            margin: 0;
          }
        }
      }

      &--noborder {
        .col {
          border: none !important;
        }
      }

      &--nopadding {
        img {
          padding: 0;
        }
      }
    }

    .flex-img-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .flex-img {
        flex: 0 0 auto;
      }

      .flex-text {
        width: 100%;
        min-width: 1px;
        flex: 1 1 auto;

        @media (max-width: $breakpoint-sm) {
          margin-top: 3rem;
        }
      }

      @media (min-width: 992px) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }
    }

    .floatBackground {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      z-index: 1;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 65em;

      &--bottom {
        top: auto;
        bottom: 0;
      }

      &--rightAlign {
        background-position: right center;
      }

      &--leftAlign {
        background-position: left center;
      }

      @media (min-width: $breakpoint-xs) {
        height: 70em;
      }

      @media (min-width: $breakpoint-sm) {
        height: 75em;
      }

      @media (min-width: $breakpoint-md) {
        height: 91.6em;
      }
    }
  }

  &Disclamer {
    font-size: 1.1em;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 3em;
    letter-spacing: 0.03em;

    p {
      margin: 0 0 3px;
    }
  }

  &_axxets-brand {
    &--tablet {
      .flex-img-text {
        margin-top: -27em;

        .flex-text {
          padding-left: 0;

          p {
            &.description {
              margin: 0;

              @media (min-width: $breakpoint-sm) {
                margin: 0 0 0.5em;
              }
            }
          }

          @media (min-width: $breakpoint-sm) {
            padding-left: 5em;
          }

          @media (min-width: $breakpoint-md) {
            padding-left: 10em;
          }
        }

        .flex-img {
          max-width: 35em;
          margin: 0 0 3em 1em;

          @media (min-width: $breakpoint-xs) {
            max-width: 40em;
          }

          @media (min-width: $breakpoint-sm) {
            max-width: 100%;
            margin: 0;
          }
        }
      }
    }

    &--description {
      margin-bottom: 5em;

      p {
        &.description {
          margin: 0;
        }
      }

      @media (min-width: $breakpoint-sm) {
        margin-bottom: 7em;
      }
    }

    &--centerImg {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--process {
      margin-top: 5em !important;

      @media (min-width: $breakpoint-xs) {
        margin-top: 8em !important;
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 10em !important;
      }
    }

    &--logo {
      padding-top: 0;
      margin-top: 8em !important;

      @media (min-width: $breakpoint-xs) {
        padding-top: 5em;
      }

      @media (min-width: $breakpoint-sm) {
        padding-top: 7.5em;
      }

      @media (min-width: $breakpoint-md) {
        margin-top: 10em !important;
      }
    }

    &--colorProcess,
    &--brands {
      padding-top: 0;
      margin-top: 8em !important;

      @media (min-width: $breakpoint-xs) {
        padding-top: 5em;
      }

      @media (min-width: $breakpoint-sm) {
        padding-top: 7.5em;
      }

      @media (min-width: $breakpoint-md) {
        margin-top: 10em !important;
      }
    }

    &--colorProcess {
      margin-top: 5em !important;

      @media (min-width: 580px) {
        margin-top: 8em !important;
      }

      @media (min-width: $breakpoint-md) {
        margin-top: 10em !important;
      }
    }

    &--colorPalette {
      padding-top: 0;
      margin-top: 8em !important;
      position: relative;
      padding-bottom: 48vw;

      @media (min-width: $breakpoint-xs) {
        padding-top: 5em;
        padding-bottom: 39em;
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 8em !important;
        padding-top: 7.5em;
        padding-bottom: 46em;
      }

      @media (min-width: $breakpoint-md) {
        margin-top: 10em !important;
        padding-top: 6em;
        padding-bottom: 52em;
      }
    }

    &--paletteImg {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;

      .imgItem {
        margin-bottom: 7em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--compoundImg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1030px;
      width: 100%;
      margin: 0 auto;

      .imgItem {
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }

        &:nth-child(3) {
          order: 4;
        }

        &:nth-child(4) {
          order: 3;
        }

        @media (min-width: 580px) {
          width: 50%;

          &:nth-child(3) {
            order: 3;
          }

          &:nth-child(4) {
            order: 4;
          }
        }
      }

      @media (min-width: 580px) {
        flex-direction: row;
      }
    }

    &--justifyImg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 103em;
      margin: 0 auto;

      .imgItem {
        width: auto;
        margin: 0 auto 8em;
        display: block;
        max-width: 46em;

        &:last-child {
          margin: 0 auto;
          max-width: 32em;
        }

        @media (min-width: $breakpoint-xs) {
          margin: 0 auto 13em;
          max-width: 100%;

          &:last-child {
            max-width: 100%;
          }
        }

        @media (min-width: $breakpoint-sm) {
          margin: 0;

          &:last-child {
            margin: 0;
          }
        }
      }

      @media (min-width: $breakpoint-sm) {
        flex-direction: row;
      }
    }

    &--website {
      position: relative;
      padding-bottom: 2em;
      margin-top: 8em !important;
      padding-top: 8em;

      @media (min-width: $breakpoint-sm) {
        margin-top: 10em !important;
        padding-top: 10em;
      }

      @media (min-width: $breakpoint-md) {
        margin-top: 18em !important;
        padding-top: 18em;
      }
    }

    &--websiteImg {
      z-index: 2;
      position: relative;

      .imgBlockFlex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;

        .imgBlock {
          width: 100%;

          .imgItem {
            display: inline-block;
            width: 100%;
            max-width: 40em;
            margin-bottom: 5em;
            margin-right: 1em;

            img {
              margin: 0 auto;
              display: block;

              @media (min-width: 580px) {
                margin: 0 0 0 auto;
              }
            }

            &--full {
              width: 100%;
              max-width: 100%;
              margin-right: 0;
              box-shadow: 0 0 16px 7px rgba(0, 0, 0, 0.15);

              img {
                width: 100% !important;
                height: auto;
                margin: 0 auto;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            @media (min-width: 580px) {
              margin-right: 0;
              max-width: 100%;
            }

            @media (min-width: $breakpoint-xs) {
              margin-bottom: 8em;
            }

            @media (min-width: $breakpoint-sm) {
              margin-bottom: 10em;
            }

            @media (min-width: $breakpoint-md) {
              margin-bottom: 24.5em;
            }
          }

          @media (min-width: 580px) {
            width: calc(50% - 2.6em);
          }
        }

        @media (min-width: 580px) {
          flex-direction: row;
        }
      }
    }
  }

  &_davita {
    &--laptop {
      .flex-img-text {
        margin-top: -15em;

        .flex-text {
          padding-left: 0;

          p {
            &.description {
              margin: 0;
            }
          }

          @media (min-width: $breakpoint-sm) {
            padding-left: 5em;
          }

          @media (min-width: $breakpoint-md) {
            padding-left: 7.5em;
          }
        }

        .flex-img {
          max-width: 45em;
          margin: 0 0 3em;

          @media (min-width: $breakpoint-xs) {
            max-width: 60em;
          }

          @media (min-width: $breakpoint-sm) {
            max-width: 50em;
            margin: 0;
          }

          @media (min-width: $breakpoint-md) {
            max-width: 100%;
            margin: 0 0 0 -4em;
          }
        }

        @media (min-width: $breakpoint-xs) {
          margin-top: -20em;
        }

        @media (min-width: $breakpoint-sm) {
          margin-top: -8em;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -15em;
        }
      }
    }

    &--flexItemsImg {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      .imgItem {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          margin: 0 auto;
          display: block;
        }

        &--full {
          img {
            width: 100%;
            height: auto;
          }
        }

        .imgBlock {
          width: auto;
          display: inline-block;
          border-radius: 0.8em;
          box-shadow: 0 0 16px 7px rgba(0, 0, 0, 0.1);
          overflow: hidden;

          &--small {
            margin: 0;

            @media (min-width: $breakpoint-xs) {
              margin: 22% 0;
            }

            @media (min-width: $breakpoint-md) {
              margin: 20% 0;
            }
          }
        }

        .imgColor {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin: 0 0 4.25em 0;

          &:after,
          &:before {
            content: "";
            position: relative;
            width: 4.8em;
            height: 4.8em;
            border-radius: 100%;
            display: inline-block;
          }

          &:before {
            margin-right: -0.4em;
          }

          &:after {
            margin-left: -0.4em;
            background-color: #283865;
            outline: 1px solid rgba(255, 255, 255, 0.3);
          }

          &--redBlue {
            &:before {
              background-color: #d20e53;
            }
          }

          &--yellowBlue {
            &:before {
              background-color: #ffc125;
            }
          }

          &--orangeBlue {
            &:before {
              background-color: #ed7f03;
            }
          }

          &--blueLightBlue {
            &:before {
              background-color: #138094;
            }
          }
        }

        .imgText {
          padding-top: 4.5em;
        }

        @media (min-width: 400px) {
          width: 50%;
        }

        @media (min-width: $breakpoint-sm) {
          width: 25%;
        }
      }

      &Justify {
        justify-content: space-between;

        .imgItem {
          width: auto;

          img {
            margin: 0;
          }
        }
      }

      &Max {
        max-width: 104em;
        margin: 0 auto;
      }

      &Space {
        margin: 0 -1em;

        .imgItem {
          padding: 0 1em;
        }
      }

      &2Cols {
        .imgItem {
          &:first-child {
            padding-right: 1em;
          }

          &:last-child {
            padding-left: 1em;
          }
        }
      }

      @media (min-width: $breakpoint-sm) {
        flex-wrap: nowrap;
      }
    }

    &--centerItemsImg {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--description {
      margin-bottom: 2.5em;

      p {
        &.description {
          margin: 0;
        }
      }
    }

    &--bookImg {
      .imgItem {
        width: 100%;

        img {
          display: block;
          margin: 0 auto;
        }
      }
    }

    &--school {
      position: relative;

      .full {
        max-height: 46em;
        position: absolute;
        top: 13em;
        left: 0;

        @media (min-width: 400px) {
          max-height: 72em;
        }

        @media (min-width: $breakpoint-xs) {
          top: 23em;
          max-height: 68em;
        }

        @media (min-width: $breakpoint-sm) {
          max-height: 70.5em;
        }

        @media (min-width: $breakpoint-md) {
          max-height: 72em;
        }
      }
    }

    &--schoolImg {
      padding-top: 7em;

      .case_davita--flexItemsImgJustify {
        justify-content: center;
      }

      .case_davita--flexItemsImgSpace {
        margin: 0;

        .imgItem {
          padding: 0;
          margin-bottom: 4em;

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }

      @media (min-width: 400px) {
        .case_davita--flexItemsImgSpace {
          margin: 0 -1em;

          .imgItem {
            padding: 0 1em;
          }
        }
      }

      @media (min-width: 554px) {
        .case_davita--flexItemsImgSpace {
          .imgItem {
            &:nth-last-child(2) {
              margin-bottom: 0;
            }
          }
        }
      }

      @media (min-width: $breakpoint-sm) {
        .case_davita--flexItemsImgJustify {
          justify-content: space-between;
        }

        .case_davita--flexItemsImgSpace {
          .imgItem {
            margin-bottom: 0;


          }
        }
      }
    }

    &--infoImg {
      position: relative;
      z-index: 2;

      .case_davita--flexItemsImg {
        flex-direction: column;

        .imgItem {
          flex: 1 1 0;
          min-width: 1em;
          padding: 0;

          &--arrow {
            flex: 0 0 auto;
            padding: 6em 0;

            img {
              transform: rotate(90deg);

              @media (min-width: $breakpoint-xs) {
                transform: none;
              }
            }

            @media (min-width: $breakpoint-xs) {
              width: 6em;
              padding: 32.5% 1em 0;
            }

            @media (min-width: $breakpoint-sm) {
              width: auto;
              padding: 29.8% 1em 0;
            }

            @media (min-width: $breakpoint-md) {
              padding: 28.5% 1em 0;
            }
          }
        }

        @media (min-width: $breakpoint-xs) {
          flex-direction: row;

          .imgItem {
            &:first-child {
              padding-right: 2em;
            }
          }
        }
      }
    }

    &--websiteImg {
      padding-top: 8.5em;
      position: relative;
      z-index: 2;

      .imgBlockFlex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;

        .imgBlock {
          width: 100%;

          .imgItem {
            display: inline-block;
            width: 100%;
            max-width: 100%;
            margin-bottom: 3.8em;
            box-shadow: 0 0 16px 7px rgba(0, 0, 0, 0.15);

            img {
              img {
                width: 100% !important;
                height: auto;
                margin: 0 auto;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          @media (min-width: 580px) {
            width: calc(50% - 2.6em);
          }
        }

        @media (min-width: 580px) {
          flex-direction: row;
        }
      }
    }

    &--website {
      z-index: 2;
      position: relative;

      &:after {
        content: "";
        height: 179em;
        background-color: #ebebeb;
        width: 100%;
        position: absolute;
        left: 0;
        top: 10em;
        z-index: 1;

        @media (min-width: $breakpoint-xs) {
          height: 91.6em;
          top: 14em;
        }
      }

      &Background {
        margin-top: -10em !important;
        z-index: 1;
        position: relative;

        @media (min-width: $breakpoint-sm) {
          margin-top: -21em !important;
        }
      }
    }

    &--icons {
      padding: 0;

      .case_davita--flexItemsImg {
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 0;
        }

        .imgItem {
          width: 50%;
          padding: 0 1em;
          margin-bottom: 3em;

          svg {
            max-width: 100%;
            height: auto;
          }

          &:nth-child(19),
          &:nth-child(20) {
            margin-bottom: 0;
          }
        }
      }

      @media (min-width: 400px) {
        padding: 2em 0;

        .case_davita--flexItemsImg {
          .imgItem {
            width: 33.333%;
            padding: 0 2em;
          }
        }
      }

      @media (min-width: 553px) {
        .case_davita--flexItemsImg {
          .imgItem {
            width: 25%;

            &:nth-child(17),
            &:nth-child(18) {
              margin-bottom: 0;
            }
          }
        }
      }

      @media (min-width: $breakpoint-xs) {
        .case_davita--flexItemsImg {
          .imgItem {
            width: 20%;

            &:nth-child(16) {
              margin-bottom: 0;
            }
          }
        }
      }

      @media (min-width: $breakpoint-sm) {
        padding: 5em 0;

        .case_davita--flexItemsImg {
          .imgItem {
            width: 10%;
            padding: 0 1em;
            margin-bottom: 0;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10) {
              margin-bottom: 9em;
            }
          }
        }
      }
    }

    &--logos {
      margin-top: 5em !important;

      .case_davita--flexItemsImg {
        flex-direction: column;

        .imgItem {
          max-width: 100%;
          padding: 0;
          margin-bottom: 3em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @media (min-width: $breakpoint-xs) {
        margin-top: 8em !important;
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 10em !important;

        .case_davita--flexItemsImg {
          flex-direction: row;

          .imgItem {
            max-width: 45em;
            margin: 0;

            &:first-child {
              padding-right: 1em;
            }

            &:last-child {
              padding-left: 1em;
            }
          }
        }
      }

      @media (min-width: $breakpoint-md) {
        .case_davita--flexItemsImg {
          .imgItem {
            max-width: 100%;
          }
        }
      }
    }

    &--logos02 {
      margin-top: 6em !important;

      .case_davita--flexItemsImg {
        .imgItem {
          margin-bottom: 3em;

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }

      @media (min-width: 400px) {
        .case_davita--flexItemsImg {
          .imgItem {

            &:nth-last-child(1),
            &:nth-last-child(2) {
              margin-bottom: 0;
            }
          }
        }
      }

      @media (min-width: $breakpoint-xs) {
        margin-top: 8em !important;
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 10em !important;

        .case_davita--flexItemsImg {
          .imgItem {
            margin-bottom: 0;
          }
        }
      }
    }

    &--logos03 {
      margin-top: 4em !important;

      @media (min-width: $breakpoint-xs) {
        margin-top: 8em !important;
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 10em !important;
      }
    }

    &--tables {
      .case_davita--flexItemsImgJustify {
        justify-content: center;
      }

      .case_davita--flexItemsImg2Cols {
        .imgItem {
          &:first-child {
            margin-bottom: 5em;
            padding-right: 0;
          }

          &:last-child {
            padding-left: 0;
          }
        }
      }

      @media (min-width: $breakpoint-sm) {
        .case_davita--flexItemsImgJustify {
          justify-content: space-between;
        }

        .case_davita--flexItemsImg2Cols {
          .imgItem {
            &:first-child {
              margin-bottom: 0;
              padding-right: 1em;
            }

            &:last-child {
              padding-left: 1em;
            }
          }
        }
      }
    }
  }

  &_askhr {
    .caseHeader__img {
      min-height: 0;
    }

    &--laptop {
      .flex-img-text {
        margin-top: -6em;
        justify-content: flex-start;

        @media (min-width: 1300px) {
          margin-top: -9em;
        }

        .flex-text {
          padding-left: 0;

          p {
            &.description {
              margin: 0;
            }
          }

          @media (min-width: $breakpoint-sm) {
            padding-left: 3em;
            margin-bottom: 4em;
          }

          @media (min-width: $breakpoint-md) {
            max-width: 50em;
            padding-left: 6em;
          }

          @media (min-width: 1300px) {
            margin-bottom: 6em;
          }
        }

        .flex-img {
          max-width: 45em;
          margin: 0 0 4em 0;

          @media (min-width: $breakpoint-xs) {
            max-width: 60em;
          }

          @media (min-width: $breakpoint-sm) {
            max-width: 50em;
            margin: 0;
          }

          @media (min-width: $breakpoint-md) {
            max-width: 55em;
          }

          @media (min-width: 1300px) {
            margin: 0 0 0 -4em;
            max-width: 100%;
          }
        }
      }
    }

    &--title {
      width: 100%;

      h3 {
        color: rgba(0, 0, 0, 0.7);
        font-size: 2em;
        font-weight: 300;
        line-height: 140%;
        margin: 0;
        padding: 0;
        letter-spacing: -0.02em;
      }

      p {
        color: rgba(0, 0, 0, 0.7);
        font-size: 2em;
        font-weight: 300;
        line-height: 140%;
        margin: 0;
        padding: 0;
        letter-spacing: -0.02em;
      }
    }

    &--text {
      width: 100%;

      &Items {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 6em;

        @media (min-width: $breakpoint-sm) {
          flex-direction: row;
        }
      }

      &Item {
        position: relative;
        width: 100%;
        padding: 0 0 5em;
        font-size: 9px;
        text-align: left;
        max-width: 70em;
        margin: 0 auto;

        &:last-child {
          padding-bottom: 0;
        }

        @media (min-width: $breakpoint-sm) {
          width: 33.333%;
          padding: 0 2em;
          max-width: 100%;
          margin: 0;
        }

        @media (min-width: $breakpoint-md) {
          padding: 0 4em;
        }

        &Info {
          font-family: "Open Sans", sans-serif;
          position: relative;
        }

        &Fav {
          position: absolute;
          top: 0;
          right: 0;
          width: 3em;
          height: auto;
          display: block;
          opacity: 0.5;

          &-blue {
            opacity: 1;

            svg {
              path {
                fill: #1434cb;
              }
            }
          }

          svg {
            width: 100%;
            height: auto;
            display: block;
          }
        }

        &Icon {
          width: 5.5em;
          height: auto;
          margin-bottom: 1.5em;
        }

        &Date {
          width: 100%;
          padding-bottom: 0.5em;

          p {
            font-size: 1.8em;
            line-height: 145%;
            font-weight: 500;
            color: #000;
            letter-spacing: -0.04em;
            margin: 0;

            strong {
              font-weight: 700;
            }
          }
        }

        &Title {
          width: 100%;
          margin-bottom: 1.5em;

          h4 {
            font-size: 2.65em;
            font-weight: 700;
            line-height: 110%;
            color: #1434cb;
            letter-spacing: -0.05em;
          }
        }

        &Description {
          width: 100%;

          p {
            font-size: 1.8em;
            line-height: 125%;
            font-weight: 400;
            letter-spacing: -0.03em;
            color: #5d5d5d;
            margin: 0;
          }
        }
      }
    }

    &--topics {
      width: 100%;

      &Blocks {
        width: 100%;
        padding-top: 5em;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (min-width: $breakpoint-sm) {
          padding-top: 1em;
          flex-direction: row;
        }
      }

      &Block {
        width: 100%;
        max-width: 70em;

        @media (min-width: $breakpoint-sm) {
          width: 50%;
          max-width: 100%;
        }

        &:last-child {
          display: flex;
          justify-content: center;
          padding-top: 6em;

          @media (min-width: $breakpoint-sm) {
            justify-content: flex-end;
            padding-top: 0;
          }
        }
      }

      &Items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.8em;
      }

      &Item {
        width: 50%;
        padding: 0 0.8em;
        margin-bottom: 1.6em;

        &Cont {
          box-shadow: 0 0 3em rgba(0, 0, 0, 0.2);
          width: 100%;
          border-radius: 0.6em;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        &:nth-last-child(1),
        &:nth-last-child(2) {
          margin-bottom: 0;
        }

        &:nth-last-child(3) {
          margin-bottom: 1.6em;
        }

        @media (min-width: $breakpoint-s) {
          width: 33.3333%;
          margin-bottom: 3.8em;

          &:nth-last-child(3) {
            margin-bottom: 0;
          }
        }
      }

      &Website {
        transform: translateX(0.7em);

        @media (min-width: $breakpoint-sm) {
          transform: translateX(1.4em);
        }
      }
    }

    &--resources {
      background-color: #f0f0f0;
      margin-top: -16em !important;
      padding: 23em 0 10em 0;
      width: 100%;

      &Items {
        display: flex;
        flex-wrap: wrap;
        padding-top: 5em;
        max-width: 70em;
        width: 100%;
        margin: 0 auto;

        @media (min-width: $breakpoint-sm) {
          max-width: 100%;
          margin: 0 -0.8em;
        }
      }

      &Item {
        width: 50%;
        padding: 0 0.8em;
        margin-bottom: 1.6em;

        @media (min-width: $breakpoint-s) {
          width: 33.3333%;
        }

        @media (min-width: $breakpoint-sm) {
          width: 16.6666%;
          margin-bottom: 0;
        }

        &Cont {
          box-shadow: 0 0 3em rgba(0, 0, 0, 0.2);
          width: 100%;
          overflow: hidden;
          border-radius: 0.6em;
          display: block;

          img {
            width: 100%;
          }
        }
      }
    }

    &--banner {
      margin: 0 !important;
      padding: 0 !important;
      background-color: #ebebeb;
      overflow: hidden;
      position: relative;

      @media (min-width: $breakpoint-sm) {
        background-color: #1434cb;
      }

      &Cont {
        @media (min-width: $breakpoint-sm) {
          transform: translateX(-25vw);
        }

        img {
          width: 100%;
        }
      }

      &Widgets {
        position: relative;
        width: 100%;
        margin-top: -16em;

        @media (min-width: $breakpoint-sm) {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          height: 100%;
          margin-top: 0;
        }

        &Cont {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &Img {
          max-width: 55em;

          @media (min-width: $breakpoint-sm) {
            transform: translateX(17vw);
          }

          @media (min-width: $breakpoint-md) {
            max-width: 100%;
          }
        }
      }
    }

    &--websiteDesk {
      background-color: #ebebeb;
      padding-top: 4em;
      position: relative;
      margin: -1px 0 0 !important;

      @media (min-width: $breakpoint-sm) {
        padding-top: 7em;
      }

      .container {
        position: relative;
        z-index: 1;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 16em;
        background-color: #fff;
        z-index: 0;
      }
    }

    &--websiteLaptop {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;

      @media (min-width: $breakpoint-sm) {
        justify-content: flex-end;
        flex-direction: row;
      }

      &Img {
        max-width: 80em;
        width: 100%;
        margin: 0 auto;

        @media (min-width: $breakpoint-md) {
          max-width: 100em;
        }

        img {
          width: 100%;
        }
      }

      &Text {
        position: relative;
        padding-bottom: 4em;

        @media (min-width: $breakpoint-sm) {
          position: absolute;
          left: 0;
          top: 0;
          padding: 0;
        }

        .case_askhr--title {
          max-width: 100%;

          @media (min-width: $breakpoint-sm) {
            max-width: 40em;
          }

          h3 {
            padding-bottom: 1em;

            @media (min-width: $breakpoint-sm) {
              padding-bottom: 4em;
            }
          }
        }
      }
    }

    &--modules {
      width: 100%;

      &Blocks {
        width: 100%;
        padding-top: 1em;
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-sm) {
          flex-direction: row;
        }
      }

      &Block {
        width: 100%;

        &:last-child {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          @media (min-width: $breakpoint-sm) {
            padding-left: 6em;
          }
        }

        @media (min-width: $breakpoint-sm) {
          width: 50%;
        }
      }

      &Website {
        width: 100%;
        max-width: 63em;
        margin: 0 auto;

        @media (min-width: $breakpoint-sm) {
          max-width: 100%;
          margin: 0;
        }
      }

      &Info {
        padding-top: 6em;

        .case_askhr--title {
          padding-bottom: 3em;
        }

        &Cont {
          filter: drop-shadow(0 0 2em rgba(0, 0, 0, 0.2))
        }
      }

      &Topics {
        padding-top: 8em;

        &Cont {
          padding-top: 3em;
        }
      }

      &Info,
      &Topics {
        width: 100%;

        @media (min-width: $breakpoint-sm) {
          max-width: 47em;
        }
      }
    }
  }

  &_rappi {
    .caseHeader__img {
      min-height: 0;
    }

    &--tablet {
      .flex-img-text {
        margin-top: -3em;
        justify-content: flex-start;

        @media (min-width: $breakpoint-s) {
          margin-top: -5em;
        }

        @media (min-width: $breakpoint-xs) {
          margin-top: -10em;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -13.5em;
        }

        .flex-text {
          padding-left: 0;
          padding-top: 3em;

          p {
            &.description {
              margin: 0;
            }
          }

          @media (min-width: $breakpoint-sm) {
            padding-left: 4em;
            margin-bottom: 3em;
            padding-top: 0;
          }

          @media (min-width: $breakpoint-md) {
            padding-left: 15em;
            max-width: 70em;
            margin-bottom: 4em;
          }
        }

        .flex-img {
          max-width: 25em;
          margin: 0 0 0 1em;

          @media (min-width: $breakpoint-s) {
            max-width: 35em;
          }

          @media (min-width: $breakpoint-xs) {
            max-width: 100%;
          }

          @media (min-width: $breakpoint-sm) {
            margin: 0 0 0 -0.4em;
          }
        }
      }
    }

    &--website {
      background: rgb(223, 223, 223);
      background: linear-gradient(135deg, rgba(223, 223, 223, 1) 0%, rgba(208, 208, 208, 1) 100%);
      position: relative;
      padding: 4em 0;
      margin-top: 6em !important;

      .container {
        position: relative;
        z-index: 1;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 16em;
        background-color: #fff;
        z-index: 0;
      }

      .full {
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &Img {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @media (min-width: $breakpoint-sm) {
          flex-direction: row;
        }

        .imgBlock {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;

          @media (min-width: $breakpoint-sm) {
            width: 50%;
          }

          .imgItem {
            flex: 1 1 auto;
            width: 100%;
            min-width: 0.1em;

            img {
              width: 100%;
              height: auto;
              display: block;
            }
          }

          &:first-child {
            .imgItem {
              width: 100%;

              @media (min-width: $breakpoint-sm) {
                transform: translateX(-3em);
              }

              @media (min-width: $breakpoint-md) {
                width: 104%;
                transform: translateX(-3.5em);
              }
            }
          }

          &:last-child {
            .imgItem {
              &:first-child {
                padding-top: 0;
                max-width: 35em;
                margin: 0 auto;

                @media (min-width: $breakpoint-s) {
                  max-width: 45em;
                  padding-top: 4em;
                }

                @media (min-width: $breakpoint-xs) {
                  max-width: 50em;
                }

                @media (min-width: $breakpoint-sm) {
                  padding-top: 6em;
                  transform: translateX(2em);
                  max-width: 44em;
                  margin: 0;
                }

                @media (min-width: $breakpoint-md) {
                  max-width: 100%;
                }

                @media (min-width: 1400px) {
                  transform: translateX(10em);
                }
              }

              &:last-child {
                width: 100%;
                padding-top: 0;

                @media (min-width: $breakpoint-sm) {
                  transform: translateX(2em);
                }

                @media (min-width: $breakpoint-md) {
                  width: 105%;
                  transform: translateX(0);
                }

                @media (min-width: $breakpoint-md) {
                  padding-top: 3em;
                }
              }
            }
          }
        }
      }
    }

    &--cards {
      margin-top: 0em !important;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .imgItem {
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }

        @media (min-width: $breakpoint-sm) {
          width: 50%;
        }
      }

      @media (min-width: $breakpoint-sm) {
        flex-direction: row;
      }
    }

    &--benefits {
      position: relative;
      padding: 0;
      margin-top: 0 !important;
      background: rgb(242, 242, 242);
      background: linear-gradient(90deg, rgba(242, 242, 242, 1) 0%, rgba(243, 243, 243, 1) 100%);
      max-height: 100%;
      z-index: 1;


      @media (min-width: $breakpoint-sm) {
        max-height: 170em;
        padding: 35em 0 0;
      }

      @media (min-width: $breakpoint-md) {
        padding: 40em 0 0;
      }

      @media (min-width: 1500px) {
        padding: 58em 0 0;
      }

      .container {
        position: relative;
        z-index: 1;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 16em;
        background-color: #fff;
        z-index: 0;

        @media (min-width: $breakpoint-sm) {
          display: none;
        }
      }

      .full {
        position: relative;
        top: auto;
        left: auto;
        width: 100% !important;
        max-width: 100% !important;
        height: auto !important;
        object-fit: cover !important;
        min-height: 380px !important;

        @media (min-width: $breakpoint-sm) {
          position: absolute;
          top: 0;
          left: 0;
          object-fit: inherit !important;
          min-height: 0 !important;
        }
      }

      &Img {
        padding: 3em 0 0;

        @media (min-width: $breakpoint-sm) {
          padding: 0;
        }

        .imgBenefits {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 0 6% 0;
          margin: 0 -1.5em;

          @media (min-width: $breakpoint-sm) {
            padding: 0;
          }

          &Item {
            width: 50%;
            padding: 0 1em;
            margin-bottom: 3em;

            @media (min-width: $breakpoint-xs) {
              width: 33.333%;
              padding: 0 1.5em;
            }

            @media (min-width: $breakpoint-sm) {
              width: 20%;
              margin-bottom: 0;
            }

            &Cont {
              box-shadow: 0 0 3em rgba(0, 0, 0, 0.2);
              overflow: hidden;
              border-radius: 0.8em;
              display: block;
              position: relative;
              width: 100%;
              padding-top: 102%;

              @media (min-width: $breakpoint-xs) {
                box-shadow: 0 0 5em rgba(0, 0, 0, 0.2);
              }

              @media (min-width: $breakpoint-sm) {
                box-shadow: 0 0 8em rgba(0, 0, 0, 0.3);
              }

              img {
                position: absolute;
                width: 100%;
                height: auto;
                top: 0;
                left: 0;
              }
            }
          }
        }

        .imgBlock {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-top: 3em;

          @media (min-width: $breakpoint-sm) {
            padding-top: 5em;
          }

          @media (min-width: $breakpoint-md) {
            padding-top: 15em;
          }

          .imgItem {
            width: 100%;

            img {
              width: 100%;
              max-width: 100%;
              height: auto;

              @media (min-width: $breakpoint-md) {
                width: 104%;
                max-width: 104%;
              }
            }

            &:first-child {
              transform: translateX(-0.5em);

              @media (min-width: $breakpoint-sm) {
                transform: translateX(-2.5em);
              }

              @media (min-width: $breakpoint-md) {
                transform: translateX(-3.5em);
              }

              img {
                width: 102%;
                max-width: 102%;

                @media (min-width: $breakpoint-md) {
                  width: 104%;
                  max-width: 104%;
                }
              }
            }

            &:last-child {
              transform: translateX(0.4em);

              @media (min-width: $breakpoint-sm) {
                transform: translateX(2em);
              }

              @media (min-width: $breakpoint-md) {
                transform: translateX(0);
              }
            }

            @media (min-width: $breakpoint-sm) {
              width: 50%;
            }
          }

          @media (min-width: $breakpoint-sm) {
            flex-direction: row;
          }
        }

        .imgText {
          font-family: "Manrope", sans-serif;
          max-width: 47em;
          font-size: 6px;
          position: relative;
          padding-bottom: 8em;
          margin: 0 auto;

          h5 {
            font-size: 5.8em;
            font-weight: 600;
            letter-spacing: -0.017em;
            line-height: 100%;
            color: #848997;

            @media (min-width: $breakpoint-sm) {
              line-height: 87%;
            }

            span {
              color: #000;
            }
          }

          p {
            font-size: 2em;
            font-weight: 400;
            padding-top: 0.8em;
            letter-spacing: 0.008em;
            color: #848997;
            line-height: 115%;
            margin: 0;
          }

          @media (min-width: $breakpoint-s) {
            font-size: 8px;
          }

          @media (min-width: $breakpoint-sm) {
            top: -30em;
            margin: 0;
            padding-bottom: 0;
            position: absolute;
            left: 15px;
          }

          @media (min-width: $breakpoint-md) {
            top: -28em;
            font-size: 9px;
          }

          @media (min-width: 1500px) {
            top: -33em;
            font-size: 10px;
          }
        }
      }
    }

    &--banner {
      padding-top: 5em;
      margin-top: 0 !important;

      img {
        width: 100% !important;
        height: auto !important;
      }

      @media (min-width: $breakpoint-sm) {
        padding-top: 10em;
      }

      @media (min-width: $breakpoint-md) {
        padding-top: 60em;
      }
    }
  }

  &_visa-ready {
    .caseHeader__img {
      min-height: 265px;
      object-position: 85.5%;
    }

    &--book {
      .flex-img-text {
        margin-top: -14em;
        justify-content: flex-start;

        @media (min-width: $breakpoint-xs) {
          margin-top: -12em;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -10em;
        }

        .flex-text {
          padding-left: 0;

          h2 {
            &.title {
              color: #003da9;
              font-weight: 300;
              margin: 0 0 0.2em;
            }
          }

          p {
            &.description {
              margin: 0;
            }
          }

          @media (min-width: $breakpoint-sm) {
            padding-left: 4em;
            margin-bottom: 18em;
          }

          @media (min-width: $breakpoint-md) {
            padding-left: 13em;
            max-width: 52em;
          }
        }

        .flex-img {
          max-width: 35em;

          @media (min-width: $breakpoint-s) {
            max-width: 45em;
          }

          @media (min-width: $breakpoint-xs) {
            max-width: 100%;
          }
        }
      }
    }

    &--colors {
      margin-top: 8em !important;

      &Back {
        &--desk {
          display: none;

          @media (min-width: 1400px) {
            display: block;
          }
        }

        &--mob {
          display: block;

          @media (min-width: 1400px) {
            display: none;
          }
        }
      }

      &Block {
        position: relative;
        width: 100%;
      }

      &Hexa {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &Item {
          height: auto;
          position: absolute;

          &:nth-child(1) {
            width: 11em;
            top: 17%;
            left: 13%;

            @media (min-width: $breakpoint-s) {
              width: 14em;
              top: 11%;
              left: 16%;
            }

            @media (min-width: 1400px) {
              width: 7%;
              top: 13%;
              left: 27%;
            }
          }

          &:nth-child(2) {
            width: 11em;
            top: 46%;
            left: 28%;

            @media (min-width: $breakpoint-s) {
              width: 14em;
              top: 45%;
              left: 39%;
            }

            @media (min-width: 1400px) {
              width: 7%;
              top: 47%;
              left: 52%;
            }
          }

          &:nth-child(3) {
            width: 10em;
            top: 78%;
            left: 14%;

            @media (min-width: $breakpoint-s) {
              width: 13em;
              top: 74%;
              left: 6%;
            }

            @media (min-width: 1400px) {
              width: 6.8%;
              top: 65%;
              left: 12%;
            }
          }

          &:nth-child(4) {
            width: 12em;
            top: 65%;
            left: 66%;

            @media (min-width: $breakpoint-s) {
              width: 16em;
              top: 63%;
              left: 70%;
            }

            @media (min-width: 1400px) {
              width: 7.2%;
              top: 65%;
              left: 72%;
            }
          }

          svg {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }

    &--fonts {
      width: 100%;
      margin-top: 9em !important;

      @media (min-width: $breakpoint-md) {
        margin-top: 13em !important;
      }

      &Block {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @media (min-width: $breakpoint-md) {
          flex-direction: row;
        }
      }

      &Item {
        width: 100%;
        height: auto;
        display: block;
        position: relative;

        &:first-child {
          max-width: 45%;

          @media (min-width: 580px) {
            max-width: 26.6em;
          }
        }

        &:last-child {
          max-width: 60.5em;
          margin-top: 2em;
          padding-top: 2.5em;

          @media (min-width: $breakpoint-s) {
            margin-top: 3em;
            padding-top: 3.5em;
          }

          @media (min-width: $breakpoint-md) {
            margin-top: 0;
            padding-top: 0;
            margin-left: 6.2em;
            padding-left: 6em;
          }

          &:after {
            content: "";
            height: 1;
            width: 100%;
            border-top: 1px solid #58595b;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            opacity: 0.4;

            @media (min-width: $breakpoint-md) {
              height: 100%;
              width: 1px;
              left: 0;
              top: 50%;
              border-top: none;
              border-left: 1px solid #58595b;
              transform: translateY(-50%);
            }
          }
        }

        svg {
          width: 100%;
          height: auto;
          display: block;

          &.desktop {
            display: none;

            @media (min-width: $breakpoint-md) {
              display: block;
            }
          }

          &.mobile {
            display: block;

            @media (min-width: $breakpoint-md) {
              display: none;
            }
          }
        }
      }
    }

    &--patterns {
      width: 100%;

      &Block {
        width: 100%;
        max-width: 920px;
        margin: 0 auto;
      }

      &Items {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -3em;

        @media (min-width: $breakpoint-xs) {
          margin: 0 -2em;
        }
      }

      &Item {
        width: 50%;
        padding: 0 3em;
        margin-bottom: 6em;

        @media (min-width: $breakpoint-xs) {
          width: 25%;
          margin: 0;
          padding: 0 2em;
        }

        &:nth-child(1),
        &:nth-child(2) {
          img {
            width: 18.5em;

            @media (min-width: $breakpoint-s) {
              width: 23.5em;
            }

            @media (min-width: $breakpoint-xs) {
              width: 13.5em;
            }
          }
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin: 0;

          img {
            width: 18.3em;

            @media (min-width: $breakpoint-s) {
              width: 23.3em;
            }

            @media (min-width: $breakpoint-xs) {
              width: 13.3em;
            }
          }
        }

        &:nth-child(1),
        &:nth-child(3) {
          img {
            margin: 0 0 0 auto;

            @media (min-width: $breakpoint-xs) {
              margin: 0 auto;
            }
          }
        }

        &:nth-child(2),
        &:nth-child(4) {
          img {
            margin: 0 auto 0 0;

            @media (min-width: $breakpoint-xs) {
              margin: 0 auto;
            }
          }
        }

        img {
          display: block;
        }
      }
    }

    &--roller,
    &--cupBoxes,
    &--ready,
    &--stationery {
      width: 100%;

      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    &--roller {
      margin-top: 8em !important;

      @media (min-width: $breakpoint-xs) {
        margin-top: 12em !important;
      }
    }

    &--cupBoxes {
      margin-top: 1em !important;

      @media (min-width: $breakpoint-xs) {
        margin-top: 3em !important;
      }
    }

    &--ready {
      margin-top: 8em !important;

      @media (min-width: $breakpoint-xs) {
        margin-top: 15em !important;
      }
    }

    &--stationery {
      margin-top: 0 !important;
    }

    &--standBooks {
      width: 100%;
      margin-top: 8em !important;

      @media (min-width: $breakpoint-xs) {
        margin-top: 15em !important;
      }

      &Block {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media (min-width: $breakpoint-xs) {
          flex-direction: row;
        }
      }

      &Item {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;

        @media (min-width: $breakpoint-xs) {
          flex-direction: row;
        }

        &:first-child {
          order: 2;

          @media (min-width: $breakpoint-xs) {
            order: 1;
          }

          .case_visa-ready--standBooksImg {
            max-width: 40em;

            @media (min-width: $breakpoint-xs) {
              max-width: 100%;
            }
          }
        }

        &:last-child {
          order: 1;
          margin-bottom: 3em;

          .case_visa-ready--standBooksImg {
            margin-top: 7em;
          }

          @media (min-width: $breakpoint-xs) {
            order: 2;
            margin: 0;
          }
        }
      }

      &Logo {
        position: absolute;
        top: 0;
        right: 0;
        width: 17.3em;
        height: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &--backgrounds {
      width: 100%;
      margin-top: 0 !important;

      &Block {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (min-width: $breakpoint-xs) {
          flex-direction: row;
        }
      }

      &Item {
        width: 100%;
        flex: 1 1 auto;

        @media (min-width: $breakpoint-xs) {
          width: 33.33333%;
        }
      }
    }
  }

  &_src {
    &--logo {
      width: 100%;

      .flex-svg-text {
        margin-top: -11em;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        flex-direction: column;

        @media (min-width: $breakpoint-sm) {
          flex-direction: row;
        }

        .flex-text {
          padding-left: 0;

          p {
            &.description {
              margin: 0;
            }
          }

          @media (min-width: $breakpoint-sm) {
            padding-left: 5em;
            margin-bottom: 2em;
          }

          @media (min-width: $breakpoint-md) {
            padding-left: 10em;
            margin-bottom: 5em;
            max-width: 57em;
          }
        }

        .flex-svg {
          width: 25em;
          height: auto;
          max-width: 100%;
          background-color: #fff;
          border-radius: 4em;
          margin-top: -11em;
          box-shadow: 0 0 3.3em 0.4em rgba(0, 0, 0, 0.2);
          margin: 0 auto 3em;

          @media (min-width: $breakpoint-s) {
            width: 30em;
          }

          @media (min-width: $breakpoint-xs) {
            width: 35em;
          }

          @media (min-width: $breakpoint-sm) {
            width: 130em;
            margin: 0;
          }

          @media (min-width: $breakpoint-md) {
            width: 40em;
          }

          &-icon {
            padding-top: 100%;
            height: auto;
            display: block;
            position: relative;

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 58%;
              height: auto;
              display: block;
              transform: translate(-50%, -50%);
              margin-left: 0.8em;
            }
          }
        }
      }
    }

    &--design {
      width: 100%;
      margin-top: 7em !important;

      @media (min-width: $breakpoint-xs) {
        margin-top: 10em !important;
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 15em !important;
      }

      &Block {
        display: flex;
        flex-direction: row;
        height: 58em;

        @media (min-width: $breakpoint-s) {
          height: 75em;
        }

        @media (min-width: 650px) {
          height: 60em;
        }

        @media (min-width: $breakpoint-xs) {
          flex-direction: column;
          height: auto;
        }
      }

      &Items,
      &SubItems {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin: 0;
        height: 100%;

        @media (min-width: $breakpoint-xs) {
          flex-direction: row;
          margin: 0 -2em;
          height: auto;
        }
      }

      &Item,
      &SubItem {
        width: 10em;
        height: 20%;
        padding: 0;
        display: flex;
        align-items: center;
        flex: 0 0 auto;

        @media (min-width: $breakpoint-s) {
          width: 15em;
        }

        @media (min-width: 650px) {
          width: 25em;
        }

        @media (min-width: $breakpoint-xs) {
          width: 20%;
          height: auto;
          display: block;
          padding: 0 2em;
        }

        &:nth-child(1) {
          .case_src--designIcon {
            width: 55%;
          }

          .case_src--designSubCont {
            &:nth-child(1) {
              .case_src--designSubIcon {
                width: 42%;
              }
            }

            &:nth-child(2) {
              .case_src--designSubIcon {
                width: 56%;
              }
            }

            &:nth-child(3) {
              .case_src--designSubIcon {
                width: 60%;
              }
            }
          }
        }

        &:nth-child(2) {
          .case_src--designIcon {
            width: 68%;
            margin-left: 0.5em;

            @media (min-width: $breakpoint-xs) {
              margin-left: 1em;
            }
          }

          .case_src--designSubCont {
            &:nth-child(1) {
              .case_src--designSubIcon {
                width: 53%;
              }
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              .case_src--designSubIcon {
                width: 42%;
              }
            }
          }
        }

        &:nth-child(3) {
          .case_src--designIcon {
            width: 43%;
            margin-left: 1em;
          }

          .case_src--designSubCont {

            &:nth-child(1),
            &:nth-child(2) {
              .case_src--designSubIcon {
                width: 46%;
              }
            }

            &:nth-child(3) {
              .case_src--designSubIcon {
                width: 53%;
              }
            }
          }
        }

        &:nth-child(4) {
          .case_src--designIcon {
            width: 37%;
          }

          .case_src--designSubCont {
            &:nth-child(1) {
              .case_src--designSubIcon {
                width: 37%;
              }
            }

            &:nth-child(2) {
              .case_src--designSubIcon {
                width: 60%;
              }
            }
          }
        }

        &:nth-child(5) {
          .case_src--designIcon {
            width: 61%;
          }

          .case_src--designSubCont {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              .case_src--designSubIcon {
                width: 33%;
              }
            }
          }
        }

        &:nth-child(6) {
          .case_src--designSubCont {

            &:nth-child(1),
            &:nth-child(2) {
              .case_src--designSubIcon {
                width: 35%;
              }
            }
          }
        }

        &:nth-child(7) {
          .case_src--designSubCont {
            &:nth-child(1) {
              .case_src--designSubIcon {
                width: 41%;
              }
            }

            &:nth-child(2) {
              .case_src--designSubIcon {
                width: 35%;
              }
            }
          }
        }

        &:nth-child(9) {
          .case_src--designSubCont {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              .case_src--designSubIcon {
                width: 56%;
              }
            }
          }
        }
      }

      &Item {
        flex-direction: column;

        @media (min-width: 650px) {
          flex-direction: row;
        }
      }

      &Cont,
      &SubCont {
        width: 100%;
        position: relative;
        height: auto;
        padding-top: 45%;
      }

      &Cont {
        padding-top: 50%;
        margin-bottom: 0.5em;

        @media (min-width: 650px) {
          padding-top: 45%;
          margin-bottom: 0;
        }
      }

      &Icon,
      &SubIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        display: block;
        transform: translate(-50%, -50%);

        svg {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      &SubItems {
        width: 100%;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        min-width: 0.1em;
        padding: 2% 0 9% 1em;
        height: 100%;
        margin: 0;

        @media (min-width: 650px) {
          padding: 4% 0;
        }

        @media (min-width: $breakpoint-xs) {
          height: auto;
        }

        @media (min-width: $breakpoint-md) {
          padding: 3em 4.3% 0;
          margin: 0 -1em;
          width: auto;
        }
      }

      &SubItem {
        width: 100%;
        height: 11.1111%;
        padding: 0;

        @media (min-width: $breakpoint-xs) {
          width: 11.1111%;
          height: auto;
        }

        @media (min-width: $breakpoint-md) {
          padding: 0 1em;
        }
      }

      &SubCont {
        width: 20%;
        padding-top: 10%;

        @media (min-width: $breakpoint-xs) {
          width: 100%;
          padding-top: 90%;
        }
      }

      &Text {
        padding-top: 0;
        padding-left: 0;
        text-align: center;
        width: 100%;

        @media (min-width: 650px) {
          padding-left: 1em;
          text-align: left;
        }

        @media (min-width: $breakpoint-xs) {
          padding-top: 2em;
          padding-left: 0;
          text-align: center;
        }

        @media (min-width: $breakpoint-md) {
          padding-top: 4em;
        }

        span {
          font-size: 1.7em;
          line-height: 110%;
          letter-spacing: -0.02em;
          font-weight: 300;
          color: rgba(0, 0, 0, 0.9);
          display: block;
        }
      }
    }

    &--axis {
      width: 100%;
      padding: 7em 0 1em;
      background-color: #f0f0f0;
      margin-top: 3em !important;

      @media (min-width: $breakpoint-sm) {
        padding: 10em 0;
        margin-top: 10em !important;
      }

      &Cont {
        width: 100%;
        position: relative;
      }

      &Items {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;

        @media (min-width: $breakpoint-sm) {
          -ms-flex-direction: row;
          -webkit-flex-direction: row;
          flex-direction: row;
        }

        &:after {
          content: "";
          display: none;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          width: 1px;
          border-left: 1px solid #a9abaf;
          height: 100%;
          transform: translateX(-50%);
          z-index: 1;

          @media (min-width: $breakpoint-sm) {
            display: block;
          }
        }

        &:before {
          content: "";
          display: none;
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          width: 100%;
          border-top: 1px solid #a9abaf;
          height: 1px;
          transform: translateY(-50%);
          z-index: 1;

          @media (min-width: $breakpoint-sm) {
            display: block;
          }
        }
      }

      &Item {
        width: 100%;
        padding: 0;

        @media (min-width: $breakpoint-sm) {
          width: 50%;
        }

        &:nth-child(1) {
          padding-bottom: 7em;

          @media (min-width: $breakpoint-sm) {
            padding: 1em 2em 2em 7em;
          }
        }

        &:nth-child(2) {
          padding-bottom: 1em;

          @media (min-width: $breakpoint-sm) {
            padding: 1em 8em 2em 2em;
          }
        }

        &:nth-child(3) {
          padding-right: 0.7em;
          padding-bottom: 1em;

          @media (max-width: $breakpoint-sm) {
            transform: translateX(-0.5em);
          }

          @media (min-width: $breakpoint-sm) {
            padding: 2em 2.5em 1em 7em;
          }
        }

        &:nth-child(4) {
          @media (min-width: $breakpoint-sm) {
            padding: 2.7em 8em 1em 2.5em;
          }
        }
      }

      &Text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        display: none;

        @media (min-width: $breakpoint-sm) {
          display: block;
        }

        span {
          white-space: nowrap;
          position: absolute;
          display: block;
          font-size: 1.7em;
          line-height: 100%;
          letter-spacing: -0.02em;
          font-weight: 500;
          color: #52575f;

          &:nth-child(1) {
            left: 0;
            top: calc(50% - 1.5em);
            transform: translateY(-50%);
          }

          &:nth-child(2) {
            left: 50%;
            bottom: 100%;
            transform: translateX(-50%);
            padding-bottom: 0.5em;
          }

          &:nth-child(3) {
            right: 0;
            top: calc(50% - 1em);
            transform: translateY(-50%);
          }

          &:nth-child(4) {
            left: 50%;
            top: 100%;
            transform: translateX(-50%);
            padding-top: 0.5em;
          }
        }
      }

      &Title {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 2em;

        @media (min-width: $breakpoint-sm) {
          display: none;
        }

        span {
          font-size: 2em;
          padding-right: 1em;
          line-height: 100%;
          letter-spacing: -0.02em;
          font-weight: 500;
          color: #52575f;
          display: block;
          flex: 0 0 auto;
        }

        &:after {
          content: "";
          position: relative;
          width: 100%;
          border-top: 1px solid #a9abaf;
          height: 1px;
          min-width: 0.1em;
          flex: 1 1 auto;
        }
      }
    }

    &--block {
      width: 100%;
      margin-top: 8em !important;

      @media (min-width: $breakpoint-xs) {
        margin-top: 10em !important;
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 15em !important;
      }

      &Items {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;

        @media (min-width: $breakpoint-s) {
          margin: 0 -1em;
        }

        @media (min-width: $breakpoint-xs) {
          margin: 0 -2em;
        }
      }

      &Item {
        width: 100%;
        height: auto;
        padding: 0;
        margin-bottom: 2em;

        @media (min-width: $breakpoint-s) {
          width: 50%;
          padding: 0 1em;
          margin-bottom: 2em;
        }

        @media (min-width: $breakpoint-xs) {
          width: 33.333%;
          padding: 0 2em;
          margin-bottom: 4em;
        }

        &.gray {
          .case_src--blockCont {
            background-color: #e7e7e8;
          }
        }

        &.white {
          .case_src--blockCont {
            background-color: #fff;
            outline: 2px solid #a7a7a7;
            outline-offset: -2px;
          }
        }

        &.blue {
          .case_src--blockCont {
            background-color: #1334ca;
          }
        }

        &:nth-child(1) {
          .case_src--blockIcon {
            width: 49%;
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          .case_src--blockIcon {
            width: 51%;
          }
        }

        &:nth-child(4) {
          .case_src--blockIcon {
            width: 40%;
          }
        }

        &:nth-child(5),
        &:nth-child(6) {
          .case_src--blockIcon {
            width: 44%;
          }
        }
      }

      &Cont {
        width: 100%;
        position: relative;
        height: auto;
        padding-top: 100%;
      }

      &Icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        display: block;
        transform: translate(-50%, -50%);

        svg,
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }

    &--sharp {
      width: 100%;
      margin-top: 8em !important;
      padding-bottom: 0;

      @media (min-width: $breakpoint-xs) {
        margin-top: 10em !important;
        padding-bottom: 5em;
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 15em !important;
        padding-bottom: 10em;
      }

      &Items {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 100%;
        max-width: 110em;
      }

      &Item {
        width: 100%;
        height: auto;
        padding: 0 2em;
        margin-bottom: 10em;

        @media (min-width: $breakpoint-xs) {
          width: 50%;
          margin-bottom: 12em;
        }

        &:nth-child(1) {
          .case_src--sharpIcon {
            max-width: 33em;
          }
        }

        &:nth-child(2) {
          margin-bottom: 6em;

          @media (min-width: $breakpoint-xs) {
            margin-bottom: 12em;
          }

          .case_src--sharpIcon {
            max-width: 32.3em;
            transform: translateY(-9%);
          }
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 6em;

          @media (min-width: $breakpoint-xs) {
            margin-bottom: 0;
          }

          .case_src--sharpIcon {
            max-width: 33em;
            transform: translateX(-4%);

            @media (min-width: $breakpoint-xs) {
              max-width: 32.4em;
              transform: translateX(-5%);
            }
          }
        }

        &:nth-child(4) {
          margin-bottom: 0;

          @media (min-width: $breakpoint-xs) {
            margin-bottom: 0;
          }
        }
      }

      &Cont {
        width: 100%;
        position: relative;
        height: auto;
      }

      &Icon {
        position: relative;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;

        svg,
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      &Text {
        white-space: nowrap;
        position: absolute;
        display: block;
        font-size: 1.7em;
        line-height: 110%;
        letter-spacing: -0.02em;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.9);

        &--01 {
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
          margin-top: -22%;
          margin-left: -21%;

          @media (min-width: $breakpoint-sm) {
            top: calc(50% - 9.5%);
            transform: translateY(-50%);
            padding-left: 0.5em;
            margin: 0;
          }
        }

        &--02 {
          top: 100%;
          left: calc(50% + 7%);
          transform: translateX(-50%);
          padding-top: 0.3em;
        }
      }
    }

    &--inverted {
      width: 100%;

      &Items {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        @media (min-width: $breakpoint-xs) {
          -ms-flex-direction: row;
          -webkit-flex-direction: row;
          flex-direction: row;
        }
      }

      &Item {
        width: 100%;
        height: auto;

        @media (min-width: $breakpoint-xs) {
          width: 50%;
        }

        &:first-child {
          background-color: #f0f0f0;
        }

        &:last-child {
          background-color: #000000;
        }
      }

      &Cont {
        width: 100%;
        position: relative;
        height: auto;
        padding-top: 78.5%;
      }

      &Icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: auto;
        display: block;
        transform: translate(-50%, -50%);

        svg {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }

    &--group {
      margin: 0 !important;
      padding: 4em 0 5em;
      background: rgb(20, 21, 45);
      background: linear-gradient(30deg, rgba(20, 21, 45, 1) 0%, rgba(0, 0, 255, 1) 100%);

      @media (min-width: $breakpoint-xs) {
        padding: 10em 0;
      }

      &Item {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-xs) {
          padding-left: 8em;
          display: block;
        }
      }

      &Icons {
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      &Text {
        width: 100%;
        display: block;
        padding-bottom: 1em;
        margin-bottom: 3em;
        display: flex;
        align-items: center;
        flex-direction: row;
        position: relative;

        @media (min-width: $breakpoint-xs) {
          left: 0;
          top: 0;
          position: absolute;
          height: 100%;
          width: 8em;
          border: none;
          padding: 0;
          margin: 0;
        }

        &:after {
          content: "";
          position: relative;
          width: 100%;
          border-top: 1px solid #00aced;
          height: 1px;
          min-width: 0.1em;
          flex: 1 1 auto;

          @media (min-width: $breakpoint-xs) {
            display: block;
            height: 110%;
            width: 1px;
            border-left: 1px solid #00aced;
            border-top: none;
            position: absolute;
            left: 55%;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.8;
            flex: 0 0 auto;
          }
        }

        span {
          font-size: 2em;
          line-height: 110%;
          letter-spacing: -0.02em;
          font-weight: 300;
          color: #fff;
          display: block;
          padding-right: 1em;

          @media (min-width: $breakpoint-xs) {
            position: absolute;
            left: 7%;
            top: 50%;
            transform-origin: center;
            transform: translate(-50%, -50%) rotate(-90deg);
            font-size: 1.7em;
            padding-right: 0;
          }
        }
      }
    }

    &--use {
      width: 100%;
      margin: 0 !important;
      padding-top: 8em;
      padding-bottom: 5em;
      position: relative;

      @media (min-width: $breakpoint-xs) {
        padding-top: 15em;
      }

      &:after {
        z-index: 1;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        background-color: #f0f0f0;
        top: 0;
        height: 49.5%;

        @media (min-width: $breakpoint-xs) {
          height: 59.5%;
        }
      }

      .container {
        position: relative;
        z-index: 2;
      }

      &Items {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
      }

      &Item {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 5em;

        @media (min-width: $breakpoint-xs) {
          flex-direction: row;
          margin-bottom: 10em;
        }

        &:last-child {
          margin-bottom: 0;

          .case_src--useImage {

            &:nth-child(1),
            &:nth-child(2) {
              @media (min-width: $breakpoint-xs) {
                padding-bottom: 8.3em;
              }
            }
          }
        }
      }

      &Image {
        margin-bottom: 2em;

        @media (min-width: $breakpoint-xs) {
          margin-right: 4.5em;
          margin-bottom: 0;

          &.smallMargin {
            margin-right: 2em;
          }

          &.mediumMargin {
            margin-right: 2.5em;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  &_ficohsasite {

    &--flex {
      margin-top: 7em;

      .flex-img-text {
        margin-top: -18em;

        @media (min-width: $breakpoint-md) {
          margin-top: -8em;
        }

        .flex-img {
          max-width: 45em;

          @media (min-width: $breakpoint-md) {
            max-width: 100%;
            margin-left: -10em;
          }
        }

        .flex-text {
          .description {
            letter-spacing: -0.02em;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.55);
            margin: 0;
            font-style: normal;
            font-size: 1.8em;
            line-height: 1.77em;

            @media (min-width: 768px) {
              font-size: 2em;
              line-height: 1.6em;
            }

            @media (min-width: 992px) {
              font-weight: 100;
              color: #52575f;
              padding-left: 2em;
              margin: 0 0 1.3em;
              font-size: 1.6em;
            }

            @media (min-width: $breakpoint-md) {
              padding-left: 0;
              margin: 0 0 4em;
              letter-spacing: 0.003em;
              font-size: 1.8em;
            }
          }
        }

        @media (min-width: 992px) {
          margin-top: -12em;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -15em;
        }
      }
    }

    &--grey-gradient {
      margin-top: 10em !important;
      //background: linear-gradient(45deg, #e5e5e5, #bfbfbf);
      background-color: #f0f0f0;

      @media (min-width: 580px) {
        margin-top: 20em !important;
      }

      .imgCards {
        margin: -14% 0 5em;


        @media (min-width: 580px) {
          margin: -14% 0 10em;
        }
      }
    }

    &--website {
      position: relative;
      padding-bottom: 2em;
      margin-top: 15em !important;

      @media (min-width: 580px) {
        margin-top: 20em !important;
      }


      .split-photos {
        position: relative;
        flex-direction: row;
        margin-bottom: -10em;
        margin-left: -10px;
        margin-right: -10px;
        flex-wrap: wrap;
        z-index: 9;

        @media (min-width: 580px) {
          flex-wrap: nowrap;
        }

        .col {
          width: 50%;
          margin-bottom: 2em;

          @media (min-width: 580px) {
            width: auto;
          }

          img {
            max-width: none;
            width: 90%;
            height: auto;
            padding: 0;
          }
        }

      }

      .floatBackground {
        border-top: 25em solid #fff;

        &.websiteBanner {
          top: -54em;
          bottom: auto;
          border-top: 30em solid #fff;

          @media (min-width: 580px) {
            bottom: 0;
            top: auto;
            border-top: 25em solid #fff;
          }
        }
      }

      &Cols {
        padding-bottom: 30em;

        @media (min-width: 580px) {
          padding-bottom: 0;
        }

        .floatBackground {
          margin-bottom: 0;
          border-top: 0;

          @media (min-width: 580px) {
            margin-bottom: 60em;
          }

        }
      }

    }


    &--websiteImg {
      z-index: 2;
      position: relative;

      .imgBlockFlex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;

        .imgBlock {
          width: 100%;
          margin-bottom: 3em;

          .imgItem {
            display: inline-block;
            width: 100%;
            max-width: 40em;
            margin-bottom: 5em;
            margin-right: 1em;

            img {
              margin: 0 auto;
              display: block;
            }

            &--full {
              width: 100%;
              max-width: 100%;
              margin-right: 0;
              box-shadow: 0 0 16px 7px rgba(0, 0, 0, 0.15);

              img {
                width: 100% !important;
                height: auto;
                margin: 0 auto;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            @media (min-width: 580px) {
              margin-right: 0;
              max-width: 100%;
            }

            @media (min-width: $breakpoint-xs) {
              margin-bottom: 8em;
            }

            @media (min-width: $breakpoint-sm) {
              margin-bottom: 10em;
            }
          }

          @media (min-width: 580px) {
            width: calc(50% - 2.6em);
          }

          &--double {
            display: flex;
            justify-content: space-between;

            .imgItem {
              img {
                margin: 0;
                width: calc(100% - 0.5em);

                @media (min-width: 580px) {
                  width: calc(100% - 2.6em);
                }
              }

              &:last-child {
                img {
                  margin-left: auto;
                }
              }
            }
          }
        }

        @media (min-width: 580px) {
          -ms-flex-direction: row;
          -webkit-flex-direction: row;
          flex-direction: row;
        }

      }

    }
  }

  &_salvio {
    &--mobile {
      .flex-img-text {
        margin-top: -13em;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        position: relative;

        .flex-text {
          padding-left: 0;
          position: relative;
          margin-top: -4em;

          p {
            &.description {
              margin: 0;
            }
          }

          @media (min-width: $breakpoint-xs) {
            margin-top: -8em;
          }

          @media (min-width: $breakpoint-sm) {
            position: absolute;
            right: 0;
            bottom: 15em;
            width: 54%;
            margin-bottom: 8em;
            margin-top: 0;
          }

          @media (min-width: $breakpoint-md) {
            margin-bottom: 9em;
            width: 51%;
          }
        }

        .flex-img {
          max-width: 50em;
          margin: 0;

          @media (min-width: $breakpoint-xs) {
            max-width: 70em;
          }

          @media (min-width: $breakpoint-sm) {
            margin: 0 0 0 -23em;
            max-width: 100%;
          }
        }

        @media (min-width: $breakpoint-sm) {
          margin-top: -20em !important;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -23em;
        }
      }
    }

    &--branding {
      margin-top: 2em !important;

      @media (min-width: $breakpoint-sm) {
        margin-top: -13em !important;
      }

      .salvioBrand {
        &_logos {
          width: 100%;

          &Block {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 6em 0;
            position: relative;

            @media (min-width: $breakpoint-xs) {
              flex-direction: row;
              padding: 8em 0;
            }

            &:first-child {
              &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
                border-bottom: 1px solid #cbcbcb;
              }
            }
          }

          &Item {
            width: 100%;
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            margin-left: 0;
            padding-left: 0;
            box-sizing: content-box;
            margin-bottom: 5em;

            &:last-child {
              margin-bottom: 0;
            }

            @media (min-width: $breakpoint-xs) {
              margin-left: 7em;
              margin-bottom: 0;
            }

            &:first-child {
              margin-left: 0;
            }

            &--1 {
              max-width: 15em;

              @media (min-width: $breakpoint-xs) {
                max-width: 18em;
              }

              @media (min-width: $breakpoint-md) {
                max-width: 27em;
              }
            }

            &--2,
            &--3,
            &--4 {
              max-width: 12em;

              @media (min-width: $breakpoint-xs) {
                max-width: 14em;
              }

              @media (min-width: $breakpoint-md) {
                max-width: 18em;
              }
            }

            &--5 {
              max-width: 30em;

              @media (min-width: $breakpoint-xs) {
                max-width: 40em;
              }

              @media (min-width: $breakpoint-md) {
                max-width: 46em;
              }
            }

            &--6,
            &--8 {
              max-width: 13em;
            }

            &--7,
            &--9 {
              max-width: 11em;
            }

            &--10 {
              max-width: 14em;
            }

            svg {
              width: 100%;
              height: auto;
              display: block;
              object-fit: contain;
            }
          }
        }

        &_backgrounds {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-top: 9em;
          flex-direction: column;

          @media (min-width: $breakpoint-xs) {
            padding-top: 11em;
            flex-direction: row;
            justify-content: center;
          }

          &Item {
            width: 100%;
            min-width: 0.1em;
            flex: 1 1 auto;
            display: block;

            img {
              width: 100%;
              height: auto;
              display: block;
            }
          }
        }

        &_press {
          width: calc(100% + 4em);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-top: 9em;
          flex-direction: column;
          flex-wrap: wrap;
          margin: 0 -2em;
          margin-bottom: 10em;

          @media (min-width: $breakpoint-xs) {
            padding-top: 11em;
            flex-direction: row;
            justify-content: center;
          }

          &Item {
            width: calc(100% - 4em);
            margin: 2em;

            @media (min-width: $breakpoint-xs) {
              width: calc(50% - 4em);
            }

            @media (min-width: $breakpoint-md) {
              width: calc(33.33% - 4em);
            }

            img {
              filter: drop-shadow(-3px 4px 5px rgba(0, 0, 0, 0.25));

            }
          }

        }
      }
    }

    &--banner {
      padding-top: 0 !important;
      margin-top: 0 !important;

      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    &--mockup {
      .salvioBrand {
        &_mockup {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin: 0;
          padding-bottom: 10em;


          @media (min-width: $breakpoint-xs) {
            flex-direction: row;
            justify-content: center;
            margin: 0;
          }

          &Item {
            width: 100%;
            display: block;
            padding: 0;
            position: relative;

            @media (min-width: $breakpoint-xs) {
              width: 33.33%;
            }

            img {
              width: 100%;
              height: auto;
              display: block;
            }

          }
        }
      }
    }

    &--devices {
      padding-top: 0 !important;
      margin-top: -2em !important;

      @media (min-width: $breakpoint-md) {
        margin-top: -32em !important;
      }

      @media (min-width: $breakpoint-xs) {
        margin-top: -12em !important;
      }


      .salvioBrand {
        &_screens {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 0;
          padding-top: 10em;

          @media (min-width: $breakpoint-xs) {
            flex-direction: row;
            justify-content: center;
            margin: 0 -2em;
          }

          &Item {
            width: 100%;
            display: block;
            padding: 0 0 2em;
            position: relative;

            @media (min-width: $breakpoint-xs) {
              width: 33.33%;
              padding: 0 2em;
            }

            img {
              width: 100%;
              height: auto;
              display: block;
            }

          }
        }

        &_devices {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media (min-width: $breakpoint-md) {
            bottom: -64.5%;
          }

          &--laptop {
            width: 100%;
            text-align: center;
          }

          &--mobile {
            width: 125%;
            flex: 0 0 auto;
            margin-top: -20em;
            margin-right: -5em;

            @media (min-width: $breakpoint-xs) {
              width: 182%;
              margin-top: -20em;
              margin-right: 10em;
            }

            @media (min-width: $breakpoint-md) {
              width: 220%;
              margin-top: -20em;
              margin-right: 18em;
            }
          }


          &--tablet {
            width: 100%;
            text-align: center;
          }


          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }
  }

  &_exeed {
    &--jersey {
      .flex-img-text {
        margin-top: -13em;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        position: relative;

        .flex-text {
          padding-left: 0;
          position: relative;
          margin-top: -4em;

          p {
            &.description {
              margin: 0;
            }
          }

          @media (min-width: $breakpoint-xs) {
            margin-top: -8em;
          }

          @media (min-width: $breakpoint-sm) {
            position: absolute;
            right: 0;
            bottom: 15em;
            width: 54%;
            margin-bottom: 8em;
            margin-top: 0;
          }

          @media (min-width: $breakpoint-md) {
            margin-bottom: 9em;
            width: 51%;
          }
        }

        .flex-img {
          max-width: 50em;
          margin: 0;

          @media (min-width: $breakpoint-xs) {
            max-width: 70em;
          }

          @media (min-width: $breakpoint-sm) {
            margin: 0 0 0 -23em;
            max-width: 100%;
          }
        }

        @media (min-width: $breakpoint-sm) {
          margin-top: -20em !important;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -23em;
        }
      }
    }

    &--branding {
      margin-top: 2em !important;

      @media (min-width: $breakpoint-sm) {
        margin-top: -13em !important;
      }

      .exeedBrand {
        &_logos {
          width: 100%;

          &Block {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 4em 0;
            position: relative;

            @media (min-width: $breakpoint-xs) {
              flex-direction: row;
              padding: 3em 0;
            }

            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 1px;
              border-bottom: 1px solid rgba(120, 120, 120, 0.4);
            }

            &:nth-child(3) {
              @media (min-width: $breakpoint-xs) {
                transform: translateX(3.5em);

                &:after {
                  transform: translateX(-3.5em);
                }
              }
            }

            &:nth-child(4) {
              @media (min-width: $breakpoint-xs) {
                transform: translateX(-4.8em);

                &:after {
                  transform: translateX(4.8em);
                }
              }
            }

            &:last-child {
              &:after {
                display: none;
              }
            }
          }

          &Item {
            width: 100%;
            min-width: 0.1em;
            flex: 1 1 auto;
            min-height: auto;
            border-left: none;
            display: flex;
            align-items: center;
            margin-left: 0;
            padding-left: 0;
            box-sizing: content-box;
            margin-bottom: 5em;

            &:last-child {
              margin-bottom: 0;
            }

            @media (min-width: $breakpoint-xs) {
              margin-left: 7em;
              padding-left: 7em;
              border-left: 1px solid rgba(120, 120, 120, 0.4);
              min-height: 8.8em;
              margin-bottom: 0;
            }

            &:first-child {
              border: none;
              padding-left: 0;
              margin-left: 0;
            }

            &--1 {
              max-width: 21.4em;
            }

            &--2 {
              max-width: 16.1em;
            }

            &--3 {
              max-width: 23.8em;

              svg {
                @media (min-width: $breakpoint-xs) {
                  margin-bottom: 1em;
                }
              }
            }

            &--4 {
              max-width: 31em;
            }

            &--5 {
              max-width: 32.1em;
            }

            &--6 {
              max-width: 22.6em;

              svg {
                @media (min-width: $breakpoint-xs) {
                  margin-bottom: 0.7em;
                }
              }
            }

            svg {
              width: 100%;
              height: auto;
              display: block;
            }
          }
        }

        &_guidelines {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 10em;

          @media (min-width: $breakpoint-xs) {
            flex-direction: row;
          }

          &Item {
            width: 100%;
            min-width: 0.1em;
            flex: 1 1 auto;
            max-width: 49.6em;
            display: block;
            box-sizing: content-box;
            margin-bottom: 5em;

            @media (min-width: $breakpoint-xs) {
              margin-bottom: 0;
            }

            img {
              width: 100%;
              height: auto;
              display: block;
            }

            &:last-child {
              padding-left: 4em;

              @media (min-width: $breakpoint-xs) {
                transform: translateY(-39.6%);
                padding-left: 7em;
              }
            }
          }
        }

        &_backgrounds {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-top: 9em;
          flex-direction: column;

          @media (min-width: $breakpoint-xs) {
            padding-top: 11em;
            flex-direction: row;
            justify-content: center;
          }

          &Item {
            width: 100%;
            min-width: 0.1em;
            flex: 1 1 auto;
            display: block;

            img {
              width: 100%;
              height: auto;
              display: block;
            }
          }
        }

        &_banner {
          width: 100%;
          padding-top: 4em;

          &Cont {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin: 0;

            @media (min-width: $breakpoint-xs) {
              flex-direction: row;
              justify-content: center;
              margin: 0 -1em;
            }
          }

          &Item {
            width: 100%;
            display: block;
            padding: 0;
            margin-bottom: 5em;

            @media (min-width: $breakpoint-xs) {
              width: 50%;
              padding: 0 1em;
              margin-bottom: 0;
            }

            &:first-child {
              transform: translateX(-0.3em);

              @media (min-width: $breakpoint-xs) {
                transform: translateX(-0.7em);
              }
            }

            img {
              width: 100%;
              height: auto;
              display: block;
            }
          }
        }
      }
    }

    &--banner {
      padding-top: 0;
      margin-top: 9em !important;
      background-color: #adadad;
      position: relative;
      overflow: hidden;

      @media (min-width: $breakpoint-xs) {
        margin-top: 13em !important;
      }

      .exeedBrand {
        &_bannerLight {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          background: rgb(255, 255, 255);
          background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 60%);
          transform: translateY(18em);

          @media (min-width: $breakpoint-sm) {
            transform: translateX(23em);
          }
        }

        &_banner {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          position: relative;
          padding: 6em 0 4em;

          @media (min-width: $breakpoint-sm) {
            flex-direction: row;
            justify-content: flex-end;
            align-items: stretch;
            padding: 10em 0 7em;
          }

          @media (min-width: $breakpoint-md) {
            padding: 15em 0 10em;
          }

          &Text {
            width: 100%;
            z-index: 2;

            @media (min-width: $breakpoint-sm) {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: auto;
            }

            p {
              font-size: 6em;
              letter-spacing: -0.05em;
              line-height: 83%;
              font-family: "Kanit", sans-serif;
              font-weight: 200;
              color: #52575f;
              margin: 0 0 0.8em;
              padding: 0;

              @media (min-width: $breakpoint-xs) {
                font-size: 7em;
              }

              @media (min-width: $breakpoint-sm) {
                margin: 0 0 0.1em;
                font-size: 8em;
              }

              @media (min-width: $breakpoint-md) {
                font-size: 11.5em;
              }

              span {
                color: #fff;
              }
            }
          }

          &Img {
            width: 100%;
            max-width: 72em;
            height: auto;
            position: relative;
            z-index: 1;

            @media (min-width: $breakpoint-sm) {
              transform: translateX(4em);
              width: 72em;
              max-width: 100%;
            }

            @media (min-width: $breakpoint-md) {
              width: 98.5em;
              transform: translateX(15em);
            }

            img {
              width: 100%;
              height: auto;
              display: block;
            }
          }
        }
      }

      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    &--banner02 {
      padding-top: 0 !important;
      margin-top: 0 !important;

      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    &--banner03 {
      position: relative;
      z-index: 1;
      margin-top: -45% !important;

      @media (min-width: $breakpoint-s) {
        margin-top: -33% !important;
      }

      @media (min-width: $breakpoint-xs) {
        margin-top: 10em !important;
      }

      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    &--banner04 {
      padding-top: 0 !important;
      margin-top: -6em !important;
      position: relative;
      z-index: 0;

      img {
        width: 100% !important;
        height: auto !important;
      }

      @media (min-width: $breakpoint-md) {
        margin-top: -11em !important;
      }
    }

    &--website {
      padding-top: 0 !important;
      margin-top: 2em !important;

      @media (min-width: $breakpoint-xs) {
        margin-top: -32em !important;
      }

      .exeedBrand {
        &_website {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 0;

          @media (min-width: $breakpoint-xs) {
            flex-direction: row;
            justify-content: center;
            margin: 0 -2.5em;
          }

          &Item {
            width: 100%;
            display: block;
            padding: 0;
            position: relative;

            @media (min-width: $breakpoint-xs) {
              width: 50%;
              padding: 0 2.5em;
            }

            &:last-child {
              padding-top: 5em;

              @media (min-width: $breakpoint-s) {
                padding-top: 10em;
              }

              @media (min-width: $breakpoint-xs) {
                padding-top: 15em;
              }
            }

            img {
              width: 100%;
              height: auto;
              display: block;
            }

            &>picture {
              img {

                @media (min-width: $breakpoint-xs) {
                  box-shadow: 0 0 3em 1em rgba(0, 0, 0, 0.2);
                }
              }
            }
          }
        }

        &_devices {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          transform: translateY(-33%);

          @media (min-width: $breakpoint-xs) {
            position: absolute;
            right: 0;
            transform: translateX(-12em);
            bottom: -55%;
          }

          @media (min-width: $breakpoint-md) {
            bottom: -64.5%;
          }

          &--mobile {
            width: 125%;
            flex: 0 0 auto;
            transform: translateX(-5em);

            @media (min-width: $breakpoint-xs) {
              width: 182%;
              transform: translateX(0);
            }

            @media (min-width: $breakpoint-md) {
              width: 190%;
            }
          }

          &--laptop {
            width: 100%;
            transform: translate(0, -8em);
            flex: 0 0 auto;

            @media (min-width: $breakpoint-xs) {
              width: 130%;
              transform: translate(4em, -8em);
            }

            @media (min-width: $breakpoint-md) {
              width: 170%;
              transform: translate(4em, -14em);
            }
          }

          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }
  }

  &_crypto {
    &--book {
      .flex-img-text {
        margin-top: -11em;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;

        .flex-text {
          padding-left: 0;

          p {
            &.description {
              margin: 0;
            }
          }

          @media (min-width: $breakpoint-sm) {
            padding-left: 3em;
            margin-bottom: 8em;
          }

          @media (min-width: $breakpoint-md) {
            padding-left: 3.5em;
            max-width: 50em;
          }
        }

        .flex-img {
          max-width: 50em;
          margin: 0 0 1em 0;

          @media (min-width: $breakpoint-xs) {
            max-width: 100%;
          }

          @media (min-width: $breakpoint-sm) {
            margin: 0 0 0 -4em;
          }
        }
      }
    }

    &--banner01 {
      position: relative;
      margin-top: 5em !important;

      .abstract-line {
        width: 100%;
        padding-top: 3.65em;

        svg {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      .abstract-hexagon {
        position: absolute;
        top: 22%;
        right: -5.6%;
        transform: translateY(-50%);
        width: 32vw;
        max-width: 60em;

        svg {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }

    &--office {
      position: relative;
      padding: 4em 0;
      margin-top: 5em !important;

      .full {
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        @media (min-width: $breakpoint-xs) {
          max-height: 65em;
        }

        @media (min-width: $breakpoint-sm) {
          max-height: 78em;
        }

        @media (min-width: $breakpoint-md) {
          max-height: 83.9em;
        }
      }

      @media (min-width: $breakpoint-xs) {
        padding: 8.65em 0 0;
      }
    }

    &--officeImg {
      .imgBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .imgItem {
          -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          width: 100%;
          min-width: 0.1em;

          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }

        @media (min-width: $breakpoint-xs) {
          flex-direction: row;
        }
      }
    }

    &--size {
      margin-top: 5em !important;

      @media (min-width: $breakpoint-sm) {
        margin-top: 10em !important;
      }
    }

    &--sizeImg {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .imgItem {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6em;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          margin: 0;
          display: block;
        }
      }

      @media (min-width: $breakpoint-sm) {
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;

        .imgItem {
          margin-bottom: 0;

          &:first-child {
            padding-right: 1.5em;
          }

          &:last-child {
            padding-left: 1.5em;
          }
        }
      }
    }

    &--officeFull {
      margin-top: 5em !important;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .imgItem {
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }

        @media (min-width: $breakpoint-sm) {
          width: 50%;
        }
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 10em !important;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
      }
    }

    &--banner02 {
      padding-top: 0;
      margin-top: 5em !important;

      .case_crypto--bannerCompImg {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .imgBlock {
          width: auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          &:first-child {
            padding-right: 0;
            margin-left: 0;
            margin-bottom: 4.5em;

            @media (min-width: $breakpoint-sm) {
              padding-right: 0.5em;
              margin-left: -4.3em;
              margin-bottom: 0;
            }

            @media (min-width: $breakpoint-md) {
              margin-left: -5.3em;
            }
          }

          &:last-child {
            padding-left: 0.5em;

            .imgItem {
              &:first-child {
                margin-top: 0;
                margin-right: 0;

                @media (min-width: $breakpoint-sm) {
                  margin-top: -3em;
                  margin-right: -6.5em;
                }

                @media (min-width: $breakpoint-md) {
                  margin-right: -7.9em;
                }
              }

              &:last-child {
                margin-top: 4.5em;
                margin-right: 0;

                @media (min-width: $breakpoint-sm) {
                  margin-top: 2.6em;
                  margin-right: -6.1em;
                }

                @media (min-width: $breakpoint-md) {
                  margin-top: 3.1em;
                  margin-right: -7.4em;
                }
              }

              img {
                @media (max-width: 990px) {
                  width: 111%;
                  max-width: 111%;
                }
              }
            }
          }

          img {
            margin: 0;
            display: block;
          }
        }

        @media (min-width: $breakpoint-sm) {
          flex-direction: row;
        }
      }

      @media (min-width: $breakpoint-sm) {
        padding-top: 3em;
        margin-top: 10em !important;
      }
    }

    &--banner03 {
      padding-top: 0;
      margin-top: 5em !important;

      .case_crypto--bannerCompImg {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .imgBlock {
          width: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 4.5em;

          &:first-child {
            padding-right: 0;

            @media (max-width: 990px) {
              img {
                width: 100%;
                height: auto;
              }
            }

            @media (min-width: $breakpoint-sm) {
              padding-right: 1.5em;
            }
          }

          &:last-child {
            padding-left: 0;
            margin-bottom: 0;

            .imgItem {
              &:last-child {
                margin-top: 4.5em;

                @media (min-width: $breakpoint-sm) {
                  margin-top: 1.3em;
                }
              }
            }

            @media (min-width: $breakpoint-sm) {
              padding-left: 1.5em;
            }
          }

          img {
            margin: 0;
            display: block;
          }

          @media (min-width: $breakpoint-sm) {
            margin-bottom: 0;
          }
        }

        @media (min-width: $breakpoint-sm) {
          flex-direction: row;
        }
      }

      @media (min-width: $breakpoint-sm) {
        padding-top: 2em !important;
        margin-top: 10em !important;
      }
    }

    &--banner04 {
      padding-top: 0;
      margin-top: 5em !important;

      img {
        width: 100% !important;
        height: auto !important;
      }

      @media (min-width: $breakpoint-sm) {
        padding-top: 5em;
        margin-top: 10em !important;
      }
    }

    &--banner05 {
      padding-top: 0;
      margin-top: 5em !important;

      .imgItem {
        display: block;
        margin-bottom: 4.5em;

        img {
          display: block;
        }

        &:nth-child(2) {
          img {
            margin: 0 0 0 auto;
          }
        }

        &:nth-child(3) {
          margin-bottom: 0;

          img {
            margin: 0 auto;
          }
        }
      }

      @media (min-width: $breakpoint-sm) {
        padding-top: 3em;
        margin-top: 10em !important;
      }
    }
  }

  &_card-customization {
    &--flex {
      .flex-img-text {
        margin-top: -6em;

        .flex-img {
          max-width: 45em;

          @media (min-width: $breakpoint-md) {
            max-width: 50%;
          }
        }

        .flex-text {
          @media (min-width: $breakpoint-md) {
            margin-bottom: 7em;
          }

          .description {
            letter-spacing: -0.02em;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.55);
            margin: 0;
            font-style: normal;
            font-size: 1.8em;
            line-height: 1.77em;

            @media (min-width: 768px) {
              font-size: 2em;
              line-height: 1.6em;
            }

            @media (min-width: 992px) {
              font-weight: 100;
              color: #52575f;
              margin: 0 0 1.3em;
              font-size: 1.6em;
            }

            @media (min-width: $breakpoint-md) {
              letter-spacing: 0.003em;
              font-size: 1.8em;
            }
          }
        }

        @media (min-width: 992px) {
          margin-top: -7em;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -8em;
        }
      }
    }

    &--iconography {
      @media(min-width: 992px) and (max-width: 1199px) {
        img {
          max-width: 95%;
        }
      }
    }

    &--books {
      position: relative;
      padding-bottom: 12em;
      overflow: hidden;

      @media (min-width: 560px) {
        padding-bottom: 22em;
      }

      @media (min-width: $breakpoint-xs) {
        padding-bottom: 30em;
      }

      @media (min-width: $breakpoint-sm) {
        padding-bottom: 34em;
      }

      @media (min-width: $breakpoint-md) {
        padding-bottom: 32em;
      }

      img {
        min-height: 200px !important;

        @media (min-width: 560px) {
          min-height: 280px !important;
        }

        @media (min-width: 768px) {
          min-height: 380px !important;
        }
      }

      .case_card-customization--booksImg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;

        img {
          min-height: 0 !important;
        }
      }

    }

    &--brochure {
      img {
        width: 100%;
        height: auto;
        padding: 0 !important;
      }
    }

    &--box {

      .container {
        margin-top: -10em;
        margin-bottom: 10em;

        @media (min-width: $breakpoint-xs) {
          margin-top: -14em;
          margin-bottom: 14em;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -20em;
          margin-bottom: 25em;
        }

        .flex-photos {
          margin-top: 2em;
        }
      }
    }

    &--screen {
      .container {
        div {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;

          picture {
            display: flex;
            justify-content: center;
            flex: 1 1 100%;

            @media (min-width: $breakpoint-xs) {
              flex: 1 1 50%;
            }

            @media (min-width: $breakpoint-md) {
              flex: 1 1 25%;
              flex-wrap: nowrap;
            }
          }
        }
      }
    }
  }

  &_cartiga {
    &--flex {
      margin-top: -5em;

      .flex-img-text {
        margin-top: -8em;

        .flex-img {
          max-width: 45em;

          @media (min-width: $breakpoint-md) {
            max-width: 55%;
          }
        }

        .flex-text {
          .description {
            letter-spacing: -0.02em;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.55);
            margin: 0;
            font-style: normal;
            font-size: 1.8em;
            line-height: 1.77em;

            @media (min-width: 768px) {
              font-size: 2em;
              line-height: 1.6em;
            }

            @media (min-width: 992px) {
              font-weight: 100;
              color: #52575f;
              padding-left: 2em;
              margin: 0 0 1.3em;
              font-size: 1.6em;
            }

            @media (min-width: $breakpoint-md) {
              padding-left: 3.3em;
              letter-spacing: 0.003em;
              font-size: 1.8em;
            }
          }
        }

        @media (min-width: 992px) {
          margin-top: -12em;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -15em;
        }
      }
    }

    &--macbook {
      position: relative;
      padding-top: 15em;
      overflow: hidden;

      img {
        min-height: 150px !important;
      }

      .case_cartiga--macbookImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        img {
          transform-origin: top center;
          transform: scale(1.07);
          min-height: 0 !important;
          display: block;
          margin: 0 auto;
        }
      }

      @media (min-width: 460px) {
        padding-top: 20em;
      }

      @media (min-width: 575px) {
        padding-top: 26em;
      }

      @media (min-width: 992px) {
        padding-top: 31em;
      }

      @media (min-width: $breakpoint-md) {
        padding-top: 23em;
      }
    }

    &--screenFlex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .case_cartiga--screenCol {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .case_cartiga--screenItem {
          margin-bottom: 2em;
        }

        @media (min-width: 768px) {
          padding: 0 1em;
        }

        @media (min-width: $breakpoint-md) {
          padding: 0 2em;
        }
      }

      @media (min-width: 768px) {
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        margin: 0 -1em;
      }

      @media (min-width: $breakpoint-md) {
        margin: 0 -2em;
      }
    }

    &--illustration {
      margin: 5em 0 -5em 0 !important;
    }

    &--icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 2em;

      .case_cartiga--iconItem {
        padding: 5em 2em;
      }

      @media (min-width: $breakpoint-md) {
        margin: 0 14em;
      }
    }

    &--solutions {
      margin-top: -5em !important;

      .case_cartiga--screenCol {
        padding: 0 1em;
        max-width: 47em;

        .case_cartiga--screenItemHalf {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: -1em;
          margin-right: -1em;

          img {
            width: calc(100% - 2em);
            margin-left: 1em;
            margin-right: 1em;
          }
        }
      }
    }

    &--contentCards {
      position: relative;
      padding-top: 30%;

      @media (min-width: 992px) {
        padding-top: 28em;
      }

      @media (min-width: $breakpoint-md) {
        padding-top: 22em;
      }

      img {
        max-width: 100%;
        min-height: auto !important;

        @media (min-width: 768px) {
          max-height: 84.2em;
        }
      }

      .case_cartiga--cards {
        position: absolute;
        top: 0em;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .container-fluid {
          width: 100%;
          max-width: 1400px;
        }

        .case_cartiga--cardsFlex {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .case_cartiga--cardsItems {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .case_cartiga--cardsItem {

              &:nth-child(2) {
                margin-bottom: 6rem;
              }

              img {
                min-height: 0 !important;
                padding: 0 0.5em;
                filter: drop-shadow(0 0 5em rgba(0, 0, 0, 0.3));

                @media (min-width: 460px) {
                  padding: 0 1em;
                }

                @media (min-width: 992px) {
                  padding: 0 2em;
                }

                @media (min-width: $breakpoint-md) {
                  padding: 0 3em;
                }
              }
            }

          }
        }

      }
    }

    &--iphones {
      margin-top: 6em !important;
      position: relative;
      padding-top: 32%;
      overflow: hidden;

      img {
        min-height: 200px !important;

        @media (min-width: 560px) {
          min-height: 280px !important;
        }

        @media (min-width: 768px) {
          min-height: 380px !important;
        }
      }

      .case_cartiga--iphoneImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;

        img {
          min-height: 0 !important;
        }
      }

      @media (min-width: 360px) {
        padding-top: 55%;
      }

      @media (min-width: 768px) {
        padding-top: 42em;
      }

      @media (min-width: $breakpoint-md) {
        padding-top: 39em;
      }
    }
  }

  &_superbowl {
    &--flex {
      margin-top: -5em;

      .flex-img-text {

        .flex-img {
          position: relative;
          max-width: 45em;
          left: -10em;
          padding-right: 5em;

          @media (min-width: $breakpoint-md) {
            max-width: 100%;
          }
        }

        .flex-text {
          .description {
            letter-spacing: -0.02em;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.55);
            margin: 0;
            font-style: normal;
            font-size: 1.8em;
            line-height: 1.77em;

            @media (min-width: 768px) {
              font-size: 2em;
              line-height: 1.6em;
            }

            @media (min-width: 992px) {
              font-weight: 100;
              color: #52575f;
              margin: 0 0 1.3em;
              font-size: 1.6em;
            }

            @media (min-width: $breakpoint-md) {
              letter-spacing: 0.003em;
              font-size: 1.8em;
            }
          }
        }

      }
    }

    &--img-middle {
      -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;

      .col {
        width: 55%;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          min-height: auto;
        }

        &:nth-child(2) {
          width: 45%;

          img {
            min-height: 100%;
            width: auto;
          }
        }

        &:nth-child(3) {
          width: 100%;
        }

        @media (max-width: 992px) {
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }

          &:nth-child(2) {
            width: 100%;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }


    }
  }

  &_yellowpepper {
    &--flex {
      .flex-img-text {
        margin-top: -7em;

        .flex-img {
          max-width: 45em;
          margin-bottom: 3em;

          @media (min-width: 992px) {
            margin-bottom: 0;
          }

          @media (min-width: $breakpoint-md) {
            max-width: 100%;
          }
        }

        .flex-text {
          .description {
            letter-spacing: -0.02em;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.55);
            margin: 0;
            font-style: normal;
            font-size: 1.8em;
            line-height: 1.77em;

            @media (min-width: 768px) {
              font-size: 2em;
              line-height: 1.6em;
            }

            @media (min-width: 992px) {
              font-weight: 100;
              color: #52575f;
              padding-left: 2em;
              margin: 0 0 1.3em;
              font-size: 1.6em;
            }

            @media (min-width: $breakpoint-md) {
              padding-left: 3.3em;
              letter-spacing: 0.003em;
              font-size: 1.8em;
            }
          }
        }

        @media (min-width: 992px) {
          margin-top: -12em;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -15em;
        }
      }
    }

    &--macbook {
      position: relative;
      padding-top: 8em;
      overflow: hidden;

      img {
        min-height: 200px !important;
      }

      .case_yellowpepper--macbookImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        img {
          transform-origin: top center;
          transform: scale(1.07);
          min-height: 0 !important;
        }
      }

      @media (min-width: 460px) {
        padding-top: 12em;
      }

      @media (min-width: 992px) {
        padding-top: 21em;
      }

      @media (min-width: $breakpoint-md) {
        padding-top: 31em;
      }
    }

    &--screenFlex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .case_yellowpepper--screenCol {

        .case_yellowpepper--screenItem {
          margin-bottom: 2em;

          @media (min-width: $breakpoint-md) {
            margin-bottom: 0 4em;
          }
        }

        @media (min-width: 768px) {
          padding: 0 1em;
        }

        @media (min-width: $breakpoint-md) {
          padding: 0 2em;
        }
      }

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        margin: 0 -1em;
      }

      @media (min-width: $breakpoint-md) {
        margin: 0 -2em;
      }
    }

    &--icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 -1em 5em;

      .case_yellowpepper--iconItem {
        padding: 0 1em;

        @media (min-width: 460px) {
          padding: 0 2em;
        }
      }

      @media (min-width: 460px) {
        margin: 0 -2em 5em;
      }

      @media (min-width: $breakpoint-md) {
        margin-bottom: 10em;
      }
    }

    &--graphicsIllus {
      display: flex;
      -ms-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column;
      align-items: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      margin: 0;

      img {
        padding: 0;
        margin-bottom: 4em;

        @media (min-width: 992px) {
          padding: 0 2em;
          margin-bottom: 0;
        }
      }

      @media (min-width: 992px) {
        flex-direction: row;
        align-items: flex-start;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        margin: 0 -2em;
      }
    }

    &--iphones {
      margin-top: 6em !important;
      position: relative;
      padding-top: 29%;
      overflow: hidden;

      img {
        min-height: 200px !important;

        @media (min-width: 560px) {
          min-height: 280px !important;
        }

        @media (min-width: 768px) {
          min-height: 380px !important;
        }
      }

      .case_yellowpepper--iphoneImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;

        img {
          min-height: 0 !important;
        }
      }

      @media (min-width: 560px) {
        padding-top: 22em;
      }

      @media (min-width: 768px) {
        padding-top: 22em;
      }

      @media (min-width: 992px) {
        padding-top: 33em;
      }

      @media (min-width: $breakpoint-md) {
        padding-top: 39em;
      }
    }

    &--contentCards {
      position: relative;

      img {
        max-width: 100%;
        position: absolute;
        left: 0;
        top: 0;

        @media (min-width: 768px) {
          max-height: 50.2em;
          min-height: 380px !important;
          position: relative;
          top: auto;
          left: auto;
        }
      }

      .case_yellowpepper--cards {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .container-fluid {
          width: 100%;
          max-width: 1400px;
        }

        .case_yellowpepper--cardsFlex {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 4em 0;

          .case_yellowpepper--cardsItems {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 4em;

            .case_yellowpepper--cardsItem {
              margin-right: 2em;

              img {
                max-height: 100%;
                min-height: 0 !important;
                position: relative;
                left: auto;
                top: auto;
              }

              &:last-child {
                margin-right: 0;
              }

              @media (min-width: 992px) {
                margin-right: 2.5em;
              }
            }

            @media (min-width: 768px) {
              margin-bottom: 0;
              -ms-flex-pack: start;
              -webkit-justify-content: flex-start;
              justify-content: flex-start;
            }
          }

          .case_yellowpepper--cardsIcon {
            margin-left: 0;
            max-width: 20em;

            img {
              max-height: 100%;
              min-height: 0 !important;
              position: relative;
              left: auto;
              top: auto;
            }

            @media (min-width: 768px) {
              margin-left: 4em;
            }

            @media (min-width: $breakpoint-md) {
              max-width: 100%;
            }
          }

          @media (min-width: 768px) {
            -ms-flex-direction: row;
            -webkit-flex-direction: row;
            flex-direction: row;
            -ms-flex-pack: justify;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            padding: 0;
          }
        }

        @media (min-width: 768px) {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &_innovation {
    &--tube {
      margin-top: -62em;

      @media (max-width: $breakpoint-md) {
        margin-top: -51em;
      }

      @media (max-width: $breakpoint-sm) {
        margin-top: -37em;

        img {
          max-height: 450px;
          width: auto;
        }
      }
    }

    &--technologies {
      margin-top: 25em !important;
      position: relative;
      z-index: 9;

      .container {
        position: absolute;
        top: -20em;
        left: 0;
        right: 0;
        z-index: 9;
      }
    }

    &--dynamic {
      position: absolute;
      top: 0;

      img {
        @media (min-width: 992px) {
          min-height: 830px !important;
        }
      }
    }

    &--banners {
      margin: 12em 0;
      flex-direction: column;

      @media (max-width: $breakpoint-sm) {
        margin: 5em 0;
      }
    }

    &--blue-ovals {
      .container>div {
        position: absolute;

        @media (max-width: $breakpoint-md) {
          position: relative;
          text-align: center;
          padding: 2em 0;
          left: auto !important;
          top: auto !important;
          right: auto !important;
        }
      }
    }

  }

  //End Case Visa Innovation

  &_metlife {
    position: relative;

    &--dynamic {
      position: absolute;
      width: 100%;
      top: 20%;
    }

    &--wall {
      img {
        min-width: 42vw;
      }
    }
  }

  &_plumslice {
    &--grey {
      background: #efefef;
      padding: 4em 0;
    }

    &_circle {
      border-radius: 100%;
      width: 80%;
      padding-bottom: 80%;
      margin: 5em 10%;

      &--purple {
        background: #870e53;
      }

      &--blue {
        background: #1b355e;
      }

      &--grey {
        background: #5d6876;
      }

      &--white {
        background: #ffffff;
      }
    }

    &--font {
      @media(min-width: 992px) and (max-width: 1199px) {
        img {
          max-width: 85%;
        }
      }
    }

    &--cards {
      img {
        min-width: calc(100vw / 3);
      }
    }
  }

  &_tokio {
    &--blue-gradient {
      background: linear-gradient(180deg, #154492, #232363);
      padding: 5em 0;
    }

    &--mobile {
      @media(max-width: $breakpoint-md) {
        max-width: 90vw;
      }
    }

    &--coffee-card {
      display: inline-block;
      margin: 0 6em;

      @media(max-width: $breakpoint-sm) {
        margin-bottom: 5em;
      }
    }

    &--logo-white {
      margin: 0 6em;
      display: inline-block;
    }
  }

  &_health {
    &--logos {
      @media(min-width: 992px) and (max-width: 1199px) {
        img {
          margin: 0 2em !important;
          max-width: 90%;
        }
      }
    }

    &--blue-gradient {
      background: linear-gradient(45deg, #25254f, #184489);
      position: relative;

      .split-photos .col:not(:last-child) {
        border-color: rgba(255, 255, 255, .3);
      }
    }

    &--blue-pattern {
      background-image: url('https://assets-website.451.com/assets/img/work/visa-health/visa-health_pattern-drop.png');
      background-repeat: repeat;
      background-size: 160px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &--grey-gradient {
      background: linear-gradient(45deg, #e5e5e5, #bfbfbf);
    }
  }

  &_eldeber {
    &--gray {
      background: #dddee0;
      padding: 2em 0;
      margin-top: 1em !important;
    }

    &--books {
      overflow-x: scroll;
      overflow-y: visible;
      white-space: nowrap;
      text-align: center;

      img {
        margin: 0 2em;
        display: inline-block;
        max-width: 60vw !important;
      }
    }

    &--badge {
      text-align: right;
      margin: 5em 0;

      @media (max-width: $breakpoint-sm) {
        text-align: center;
      }
    }
  }

  &_vod {
    &--orange {
      background: #f25c2a;
      padding: 10em 0 0;
    }

    &--white {
      background: #ffffff;
      text-align: right;
      margin: 10em 0 0;

      img {
        margin-top: -40em;

        @media (max-width: 1024px) {
          margin-top: -28vw;
        }
      }
    }
  }

  &_ficohsa {

    &--description {
      @media (max-width: $breakpoint-sm) {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }

    &--bars {
      position: absolute;
      top: 8vw;
      max-width: 50%;

      @media (max-width: $breakpoint-xs) {
        max-width: initial;
        position: relative;
        top: initial;
        margin-top: -35em;
      }
    }

    &--accounts-screenshot {
      position: absolute;
      top: -5em;
      left: 0;
      z-index: 15;

      @media (max-width: $breakpoint-sm) {
        position: relative !important;
        top: 0 !important;
        padding-top: 3em;
      }
    }

    &--acounts-summary-title {
      margin: 2em 0;
      text-align: right;
      padding: 0 15px;
      max-width: 50%;
      float: right;

      @media (max-width: $breakpoint-sm) {
        text-align: center !important;
        max-width: 100% !important;
        float: none !important;
      }
    }

  }

  &_station {

    &--description {
      margin-bottom: 0;

      @media (min-width: $screen-md-min) {
        margin-right: 2em;
      }
    }

    &--logos {
      .col:first-child {
        border-right: 2px solid #cce5f4 !important;

        @media (max-width: $breakpoint-sm) {
          border: none !important;

          img {
            border-bottom: 2px solid #cce5f4 !important;
          }
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &--grey {
      background: #fbfbfb;
      padding: 4em 0;
    }

    &_circle {
      border-radius: 100%;
      width: 60%;
      padding-bottom: 60%;
      margin: 5em 20%;
      box-shadow: -0.3em -0.3em 1em rgba(0, 0, 0, .1);

      &--sky-blue {
        background: #0089cf;
      }

      &--green {
        background: #60c3ad;
      }

      &--blue {
        background: #21409a;
      }

      &--yellow {
        background: #faa61a;
      }

      &--light-grey {
        background: #a7a9ac;
      }

      &--dark-blue {
        background: #150958;
      }

      &--cyan {
        background: #8ed8f8;
      }

      &--grey {
        background: #58595b;
      }

      &--purple {
        background: #662d91;
      }
    }

    &--gradient {
      background: rgb(86, 189, 176);
      background: linear-gradient(175deg, rgba(86, 189, 176, 1) 0%, rgba(7, 141, 204, 1) 93%);
    }

    &--bag {
      img {
        position: absolute;
        bottom: -38em;
        left: -5em;

        @media (max-width: $breakpoint-sm) {
          position: relative;
          left: initial;
          margin-top: -15em;
          bottom: -5em;
        }
      }
    }

    &--brochure {
      img {
        width: 100%;
        height: auto;
        padding: 0 !important;
      }
    }

    &--separator-gradient {
      height: 150px;
      background: rgb(86, 189, 176);
      background: linear-gradient(100deg, rgba(86, 189, 176, 1) 0%, rgba(22, 117, 194, 1) 56%, rgba(97, 49, 148, 1) 93%);
    }

    &--gradient2 {
      background: rgb(86, 189, 176);
      background: linear-gradient(176deg, rgba(86, 189, 176, 1) 0%, rgba(43, 143, 191, 1) 49%, rgba(97, 49, 148, 1) 83%);
    }

  }

  //End Case Station

  &_headtorch {

    &--logos {
      img {
        width: 100%;
        height: auto;
      }
    }

    &--logos-black {
      img {
        @media (max-width: $breakpoint-sm) {
          max-width: 100% !important;
        }
      }
    }

    &--dynamic {
      position: absolute;
      width: 100%;
      bottom: 16%;
    }

  }

  //End Case Headtorch

  &_delta {

    &--blue-gradient {
      background: rgb(0, 18, 37);
      background: linear-gradient(176deg, rgba(0, 18, 37, 1) 0%, rgba(0, 45, 92, 1) 83%);
    }

    &--progress {
      background: #e9eef3;
      height: 190px;

      @media (max-width: $breakpoint-sm) {
        height: auto;
        padding: 15px;
      }

      img {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: -10em;
        z-index: 3;

        @media (max-width: $breakpoint-sm) {
          position: relative;
          top: 0;
        }
      }
    }

    &--dynamic {
      position: absolute;
      top: 0;
    }

  }

  //End Case Delta

  &_france {

    &--description {
      margin-bottom: 0;

      @media (min-width: $screen-md-min) {
        margin-right: 2em;
      }
    }

    &--border-right {
      @media (min-width: $screen-md-min) {
        border-right: 1px solid #11357e;
        padding-right: 4em;
        margin-right: 3em;
      }
    }

    &--we-accept {
      img {
        width: 100%;
        height: auto;
        padding: 0 !important;
      }
    }

    &--dynamic {
      position: absolute;
      top: 56%;
    }

  }

  //End Case Visa France

  &_miami {

    &--green-gradient {
      background: rgb(243, 238, 39);
      background: linear-gradient(27deg, rgba(243, 238, 39, 1) 0%, rgba(135, 218, 78, 1) 85%);
      height: 240px;
    }

    &--description {
      @media (max-width: $breakpoint-sm) {
        margin: 3em 0 0 !important;
      }
    }

  }

  //End case Visa Miami

  &_wineries {
    &--wines-box {
      margin: -11em 0 0;

      @media (max-width: $breakpoint-xs) {
        margin: -6em 0 0;
      }
    }

    &--description {
      margin: 7em 0 0 -4em;

      @media (max-width: $breakpoint-sm) {
        margin: 1em 0 0 !important;
      }
    }

    &--images {
      margin: 6em 0 !important;

      @media (min-width: $breakpoint-md) {
        img {
          margin: 1em;
          padding: 0 !important;
        }
      }
    }

    &--woman-cook {
      text-align: right;

      @media (max-width: $breakpoint-sm) {
        text-align: center;
        margin-bottom: 2em;
      }
    }

    &--apron {
      @media (min-width: 992px) {
        position: absolute;
        top: -8em;
        left: 0;
        z-index: 6;
      }
    }

  }

  //End case Visa Wineries

  &_university {

    &--label {
      text-transform: uppercase;
      color: #a8a8a8;
      display: block;
      text-align: right;
      margin: 3em 0;
      font-weight: 400;
    }

    &--label-brochures {
      position: absolute;
      padding: 0 15px;
      text-transform: uppercase;
      font-weight: 400;
      right: 13vw;
      color: #000;
      top: 3vw;
      z-index: 2;

      @media (max-width: $breakpoint-md) {
        right: 0;
      }
    }

    &--label-lobby {
      text-transform: uppercase;
      color: #000;
      display: block;
      text-align: right;
      font-weight: 400;
      position: relative;
      top: 2em;
      z-index: 2;

      @media (max-width: $breakpoint-md) {
        top: 0
      }
    }

    &--blue-gradient {
      background: rgb(2, 3, 12);
      background: linear-gradient(17deg, rgba(2, 3, 12, 1) 0%, rgba(36, 54, 102, 1) 50%);
    }

  }

  //End case Visa University

  &_card-designer {
    &--box-description {
      margin: -75% 0 0;
    }

    &--description__light {
      color: rgba(255, 255, 255, 0.4);
      padding: 0 8em 2em 5.5em;

      @media (max-width: $breakpoint-sm) {
        padding: 0;
      }
    }

    &--see {
      margin: -20em 0 0;

      @media (max-width: $breakpoint-md) {
        display: none;
      }
    }

    &--grey {
      background-color: #f8f8f8;
      padding: 10em 0;

      .slick-dots {
        text-align: center;
        padding: 7em 0 4em;

        li {
          display: inline-block;

          button {
            background: #bebebe;
            text-indent: -9000px;
            border: 0;
            width: 0.9em;
            height: 0.9em;
            display: block;
            border-radius: 50%;
            line-height: 0.9em;
            padding: 0;
            margin: 0.5em 0.7em;
            transition: all 0.5s ease 0s;
          }

          &.slick-active {
            button {
              width: 1.9em;
              height: 1.9em;
              margin: 0 0.2em;
            }
          }
        }
      }

    }

    &--video {
      position: relative;

      .btnLink {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: rgba(255, 255, 255, 0.2);
        background-color: rgba(0, 0, 0, 0.85);
        transition: all 0.5s ease 0s;
        z-index: 2;

        .arrow-icon {
          margin-bottom: 1em;
        }
      }

      &:hover {
        .btnLink {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &.space-photos {
      img {
        margin: 3em 0 0;
      }
    }
  }

  //End case Visa Card Designer

  &_affluent {

    &--dynamic {
      position: absolute;
      left: 0;
      top: 12vw;
      right: 0;

      .col-md-4 {
        @media (min-width: 992px) {
          padding-left: 11px;
        }
      }

      @media (max-width: $breakpoint-sm) {
        position: relative;
        top: 0;
      }

      img {
        @media (max-width: $breakpoint-sm) {
          margin-bottom: 3em;
        }
      }
    }

    &--woman-bg {
      img {
        @media (min-width: 992px) and (max-width: 1599px) {
          min-height: 170vw !important;
        }
      }
    }

    &--cellphones {
      margin-top: -18em;

      @media (max-width: $breakpoint-sm) {
        margin-top: 5em;
      }
    }

  }

  //End case Visa Affluent

  &_card-lab {

    &--icon {
      text-align: right;
      margin: 6em 5em 6em;

      @media (max-width: $breakpoint-sm) {
        text-align: center;
      }
    }

    &--description {
      margin-bottom: 0;

      @media (min-width: 992px) {
        margin-left: -10em;
      }
    }

    &--iconography {
      @media(min-width: 992px) and (max-width: 1199px) {
        img {
          max-width: 95%;
        }
      }
    }

  }

  //End case Visa Card Lab

  &_university-online {

    &--logo {
      @media (min-width: 992px) {
        margin: -2em 0 0;
      }
    }

    &--grey-section {
      background: #efefef;
      margin: 0 !important;
      padding-top: 10em;
    }

    &--self-work-big {
      img {
        @media (min-width: $breakpoint-md) {
          max-width: 1056px;
        }
      }
    }

  }

  //End case Visa University Online

  &_hudson-yards {

    &--cash-machine {
      position: absolute;
      top: -30em;
      left: -2em;

      @media (max-width: $breakpoint-sm) {
        position: relative;
        top: -18em;

        img {
          max-width: 250px;
        }
      }
    }

    &--logo {
      margin: 5em 0;
      text-align: right;

      @media (max-width: $breakpoint-sm) {
        margin-top: -12em;
        text-align: center;
      }
    }

    &--description-light {
      color: rgba(255, 255, 255, 0.4);

      @media (min-width: 992px) {
        margin-left: 2em;
      }
    }

    &--iconography {
      text-align: right;
      padding: 10em 0;
      background-color: #957956;

      @media (max-width: 1024px) {
        text-align: center;
      }
    }

    &--dynamic {
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      z-index: 15;

      @media (max-width: $breakpoint-sm) {
        position: relative;
        margin: 5em 0;
      }
    }

    &--small-space {
      margin: 0 -0.5em !important;

      img {
        padding: 0.5em !important;
      }
    }

    &--shopping {
      img {
        @media (min-width: $breakpoint-md) {
          max-width: 1112px;
        }
      }
    }

    &--big-city {
      img {
        @media (min-width: $breakpoint-md) {
          max-width: 1130px;
        }
      }
    }

    &--agenda {
      @media (min-width: $breakpoint-md) {
        padding: 0 1em;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &--img-middle {
      img {
        width: 100%;
        height: auto;
        padding: 0 !important;
      }
    }

  } //End case Visa Hudson Yards

  &_university-brand {
    &--description {
      margin-bottom: 0;
      margin-left: 0 !important;

      @media (min-width: $screen-md-min) {
        margin-left: 1em;
      }
    }

    &--patternBack {
      position: relative;
      padding: 9em 0;
      margin-top: 0 !important;

      .flex-photos {
        &.flex-photos--max {
          max-width: 745px;
          margin: 0 auto 8.4em;

          img {
            padding: 0 1em;
            margin: 0;

            @media (max-width: $breakpoint-sm) {
              padding: 0;
            }
          }

          >*:first-child {
            img {
              @media (max-width: $breakpoint-sm) {
                margin-bottom: 5em;
              }
            }
          }

          @media (max-width: $breakpoint-sm) {
            margin: 0 auto 5em;
          }
        }

        &.flex-photos--margin {
          @media (min-width: 992px) and (max-width: 1199px) {
            margin: 0 -4em;
          }

          img {
            margin: 0;

            &.margin-top {
              margin-top: 5em;
            }

            @media (min-width: 992px) and (max-width: 1199px) {
              padding: 0 4em;
            }
          }
        }
      }
    }

    &--seal {
      margin-top: 18em !important;
      position: relative;
      z-index: 1;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .box-photos {
          text-align: center;
          position: relative;

          .box-shadow {
            position: absolute;
            top: 50%;
            left: 50%;
            pointer-events: none;
            height: 100%;
            max-width: none;
            width: auto;
            transform: translate(-50%, -50%);

            img {
              height: 100%;
              max-width: none;
              width: auto;
              transform: scale(1.9);
              transform-origin: center center;
            }
          }
        }

        >*:first-child {
          .box-photos {
            @media (max-width: $breakpoint-sm) {
              margin-bottom: 6em;
            }
          }
        }

        @media (max-width: $breakpoint-sm) {
          flex-direction: column;
          justify-content: flex-start;
        }
      }

      @media (max-width: $breakpoint-sm) {
        margin-top: 10em !important;
      }
    }

    &--patternImg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &--laptop {
      margin-top: 16em !important;

      @media (max-width: $breakpoint-sm) {
        margin-top: 10em !important;
      }
    }

    &--credentials {
      margin-top: 16em !important;
      position: relative;
      z-index: 2;

      .flex-photos {
        margin: 0 -1em;

        @media (min-width: 992px) and (max-width: 1199px) {
          margin: 0 -2em;
        }

        img {
          padding: 0 1em;
          margin: 0;

          @media (min-width: 992px) and (max-width: 1199px) {
            padding: 0 2em;
          }

          @media (max-width: $breakpoint-sm) {
            padding: 0 1em;
          }
        }

        >*:first-child {
          img {
            @media (max-width: $breakpoint-sm) {
              margin-bottom: 5em;
            }
          }
        }
      }

      @media (max-width: $breakpoint-sm) {
        margin-top: 10em !important;
      }
    }

    &--iconography {
      .flex-photos {
        max-width: 1100px;

        @media (min-width: 992px) and (max-width: 1199px) {
          margin: 0 -3em;
        }

        img {
          @media (min-width: 992px) and (max-width: 1199px) {
            padding: 0 3em;
          }
        }
      }
    }

    &--fonts {
      .flex-photos {
        margin-bottom: 2em;

        @media (min-width: 992px) and (max-width: 1199px) {
          img {
            padding: 0 2em;
          }
        }
      }
    }

    &--blue-gradient {
      background: rgb(12, 16, 33);
      background: linear-gradient(149deg, rgba(12, 16, 33, 1) 31%, rgba(25, 42, 82, 1) 61%);
      height: 50em;

      @media (min-width: $breakpoint-xs) {
        height: 70em;
        margin-top: 45em;
      }

      &>div {
        @media (min-width: $breakpoint-xs) {
          position: absolute;
          top: -34em;
          left: 0;
          right: 0;
        }
      }
    }

    &--bg-gray {
      height: 30em;
      position: absolute;
      width: 100%;
      background-color: #ededed;
    }

    &--img-middle {
      img {
        width: 100%;
        height: auto;
        padding: 0 !important;
      }
    }

  } //End case Visa University Brand Update

  

  &_vee {
    &--description {
      margin-top: 5em;

      @media (min-width: $screen-md-min) {
        margin-top: 6em;
        margin-right: -13em;
      }
    }

    &--bars {
      @media (max-width: $breakpoint-sm) {
        margin-top: 0 !important;
      }
    }

    &--headerImage {
      img {
        @media (max-width: $breakpoint-sm) {
          object-position: center left;
        }
      }

      .container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: $breakpoint-sm) {
          left: 0;
          transform: translate(-0%, -50%);

          img {
            max-width: 55%;
            height: auto;
          }
        }
      }
    }

    &--bg-blue {
      margin-top: 55em !important;

      @media (min-width: $screen-md-min) {
        margin-top: 30em !important;
      }
    }

    &--books {
      @media (min-width: $screen-md-min) {
        margin-top: -35em;
      }
    }

    &--magazines {
      margin-top: -103em;

      @media (min-width: 991px) and (max-width: 1024px) {
        margin-top: -70em;
      }
    }

    &--magazines,
    &--magazines2 {
      @media (min-width: 991px) and (max-width: 1024px) {
        img {
          max-width: 290px;
        }
      }
    }

  }  //End case Visa Economic Empowerment

  &_university-site {
    &--phone {
      margin-top: -6.5em;

      @media (min-width: $breakpoint-md) {
        margin-top: -10em;
      }
    }

    &--flex {
      margin-top: -12em;

      .flex-img-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .flex-img {
          @media (min-width: $breakpoint-md) {
            max-width: 100%;
            margin-left: -9em;
          }
        }

        .flex-text {
          .description {
            letter-spacing: -0.02em;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.55);
            margin: 0;
            font-style: normal;
            font-size: 1.8em;
            line-height: 1.77em;

            @media (min-width: 768px) {
              font-size: 2em;
              line-height: 1.6em;
            }

            @media (min-width: $breakpoint-md) {
              font-weight: 100;
              color: #52575f;
              margin: 0 0 5.1em;
              font-size: 1.6em;
            }
          }
        }

        @media (min-width: 768px) {
          text-align: center;
        }

        @media (min-width: $breakpoint-md) {
          text-align: left;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
        }
      }
    }

    &--flow {
      margin-top: 5em !important;
    }

    &--banners {
      img {
        margin: 3em 0 !important;
      }
    }

    &--screenshots {
      @media (max-width: $breakpoint-sm) {
        img {
          margin-bottom: 2.5em;
        }
      }
    }

    &--badges {
      @media (min-width: $screen-md-min) {
        margin-top: -24em;
      }
    }

    &--mastery {
      @media (min-width: $screen-md-min) {
        img {
          margin-left: -14em;
        }
      }
    }

  } //End case Visa University Site Update

  &_yazara {
    &--flex {

      .flex-img-text {
        margin-top: -25em;
      }

      .flex-text {
        @media (min-width: $breakpoint-lg) {
          margin: 0 0 4em 6em;
        }
      }
    }

    &--logo {
      margin: 3em 0 -2em !important;

      @media (min-width: $breakpoint-lg) {
        margin: 15em 0 !important;
      }

      img {
        margin: 0 auto;
        display: block;

        @media (min-width: $breakpoint-sm) {
          max-width: 55%;
        }
      }
    }

    &--bg {
      img {
        margin: 0 0 4em;

        @media (min-width: $breakpoint-lg) {
          margin: 0 0 8em;
        }
      }
    }

    &--posFuture {
      position: relative;

      @media (max-width: $breakpoint-sm) {
        margin-top: 5em !important;
      }

      .blueLines-bg {
        min-height: 600px !important;

        @media (min-width: $breakpoint-sm) {
          min-height: 850px !important;
        }

        @media (min-width: $breakpoint-lg) {
          max-height: 1100px;
        }
      }

      &Texts {
        position: absolute;
        top: 10em;
        left: 0;
        right: 0;

        @media (min-width: $breakpoint-lg) {
          top: 14em;
        }

        .textHeadquarters {
          margin: 10em 0 0 auto;
          display: block;
          max-width: 80%;

          @media (min-width: $breakpoint-sm) {
            margin: 7vw 5em 0 auto;
            max-width: 35%;
          }
        }
      }
    }

    &--laptopFull {
      img {
        @media (min-width: $breakpoint-lg) {
          max-height: 1100px;
        }
      }
    }

    &--screenshots {
      transform: translateY(-5em);

      @media (min-width: $breakpoint-lg) {
        transform: translateY(-20em);
        margin-bottom: -8em;
      }

      img {
        @media (max-width: $breakpoint-lg) {
          margin-bottom: 3em;
        }
      }
    }

    &--phones {
      img {
        z-index: 3;
        position: relative;
        margin-top: -10em;

        @media (min-width: $breakpoint-md) {
          z-index: 1;
          max-width: 70%;
          margin-top: -40em;
        }
      }
    }

    &--bgPhone {
      margin-top: -6em !important;
      position: relative;
      z-index: -1;

      @media (min-width: $breakpoint-lg) {
        margin-top: -14em !important;
      }
    }

  }

  //End case Yazara

  &_warpspd {
    &--flex {

      .flex-img-text {
        margin-top: -8em;

        @media (min-width: $breakpoint-sm) {
          margin-top: -35em;
        }

        .flex-img {
          max-width: 80%;

          @media (min-width: $breakpoint-sm) {
            max-width: 400px;
          }
        }

        .description {
          @media (max-width: $breakpoint-md) {
            margin-top: -10em;
            margin-bottom: 0;
          }
        }
      }

      .flex-text {
        @media (min-width: $breakpoint-md) {
          margin: 0 0 3em 8em;
        }

        .speedIsLife {
          transform: translate(0, -92em);
          max-width: 50%;
          margin: 0 auto;

          @media (min-width: $breakpoint-sm) {
            transform: translate(0, -50em);
          }

          @media (min-width: $breakpoint-lg) {
            transform: translate(-2em, -40em);
            display: flex;
            justify-content: end;
            max-width: 100%;
            margin: initial;
          }
        }
      }
    }

    &--logos {
      .flex-photos {
        img {
          margin: 0;

          @media (max-width: $breakpoint-sm) {
            max-width: 80%;
          }
        }
      }
    }

    &--logosGrid {
      .flex-photos {
        gap: 5rem;
      }
    }

    &--backgrounds {
      .grid-photos {
        gap: 2rem;
        margin-top: 3rem;
      }
    }

    &--laptop {
      position: relative;
      padding: 0 0 10rem;

      @media (min-width: $breakpoint-lg) {
        padding: 5rem 0 12rem;
      }

      &:after {
        content: '';
        height: 50%;
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: #ddd;
        left: 0;
        z-index: -1;
      }
    }

    &--screenshots {
      transform: translateY(-20em);
    }

    &--tablet {
      transform: translateY(-5em);
      margin-bottom: -15em;

      @media (min-width: $breakpoint-lg) {
        transform: translateY(-30em);
        margin-bottom: -50em;
        display: flex;
        justify-content: end;
      }

      img {
        @media (min-width: $breakpoint-lg) {
          max-width: 900px;
        }
      }
    }

  }  //End case Warpspd

  &_cardinal {
    &--flex {
      .flex-img-text {
        margin-top: -12em;
        
        @media (min-width: $breakpoint-md) {
          margin-bottom: 6em;
          margin-top: -30em;
        }
      }

      .flex-img {
        img {
          @media (max-width: $breakpoint-md) {
            max-width: 80%;
          }
        }
      }

      .flex-text {
        margin-top: 5em !important;

        @media (min-width: $breakpoint-md) {
          margin: 0 0 0 8em !important;
        }

        .description {
          margin: 0;
        }
      }
    }

    &--rhombs {
      position: relative;

      @media (max-width: $breakpoint-sm) {
        margin-bottom: 5em;
      }

      .gradient {
        width: 38%;
        height: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        background: linear-gradient(90deg, rgba(40, 37, 89, 1) 9%, rgba(15, 66, 140, 1) 100%);
        left: 0;
        z-index: -1;
        display: none;

        @media (min-width: $breakpoint-sm) {
          display: block;
        }
      }
    }

    &--logos {
      img {
        margin-bottom: 2em;

        @media (min-width: $breakpoint-sm) {
          margin-bottom: 5em;
        }
      }
    }

    &--iconography {
      hr {
        background-color: #212d65;
        border: 0;
        height: 1px;
        margin: 3em 0;
      }

      img {
        @media (max-width: $breakpoint-sm) {
          max-width: 100% !important;
        }
      }
    }

    &--bluePattern {
      margin-top: -17em !important;
      position: relative;
      z-index: -1;
      
      @media (min-width: $breakpoint-sm) {
        margin-top: -34em !important;
      }
    }

    &--brochures {
      margin-top: -10em !important;
      margin-bottom: 8em;
      
      @media (min-width: $breakpoint-md) {
        margin-top: -25em !important;
        margin-bottom: 15em;
      }

      .flex-photos {
        @media (min-width: $breakpoint-sm) {
          gap: 3em;
        }

        img {
          @media (max-width: $breakpoint-sm) {
            max-width: 90%;
          }
        }
      }
    }

    &--magazine {
      margin-top: -18em !important;
      
      @media (min-width: $breakpoint-md) {
        margin-top: -38em !important;
      }
    }

  } //End case Cardinal

  &_rayMedia {
    &--flex {

      .logo {
        max-width: 200px;
        transform: translate(1em, -28em);
        
        @media (min-width: $breakpoint-md) {
          max-width: 400px;
          transform: translate(25em, -60em);
        }
      }

      .flex-img-text {
        transform: translateY(-10em);
        
        @media (min-width: $breakpoint-md) {
          transform: translateY(-16em);
        }

        .flex-img {
          @media (min-width: $breakpoint-sm) {
            max-width: 55%;
          }
        }
  
        .flex-text {
          margin: 5em 0 0 0;

          @media (min-width: $breakpoint-md) {
            margin: 0 0 0 5em;
          }
        }
      }
    }

    &--screenshots {
      position: relative;
      padding-bottom: 30em;
      
      @media (min-width: $breakpoint-md) {
        padding-bottom: 65em;
      }

      .gridBg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;

        @media (max-width: $breakpoint-md) {
          object-fit: cover;
          min-height: 400px;
        }
      }
    }

    &--dashboard {
      margin-top: -5em !important;

      .flex-photos {       
        @media (min-width: $breakpoint-sm) {
          gap: 4rem;
          margin-bottom: 2.5em;
        }
      }
    }

    &--screenshotsNew {
      position: relative;

      @media (max-width: $breakpoint-md) {
        padding-bottom: 45em;
      }
    }

    &--mobileSite {
      position: absolute;
      bottom: 10em;
      left: 0;
      width: 100%;
      z-index: -1;
    }

  } //End case Ray Media

  &_teletica {
    &--flex {

      .logo {
        transform: translate(10em, -28em);
        max-width: 50%;

        @media (min-width: $breakpoint-sm) {
          transform: translate(32em, -44em);
          max-width: 35%;
        }
        
        @media (min-width: $breakpoint-md) {
          transform: translate(35em, -68em);
          max-width: 380px;
        }

        @media (min-width: $breakpoint-xl) {
          transform: translate(35em, -87em);
          max-width: 500px;
        }
      }

      .flex-img-text {
        transform: translateY(-20em);
        
        @media (min-width: $breakpoint-sm) {
          transform: translateY(-35em);
        }

        @media (min-width: $breakpoint-md) {
          transform: translateY(-38em);
        }
 
        .flex-text {
          margin: 5em 0 0 0;

          @media (min-width: $breakpoint-sm) {
            transform: translateY(8em);
          }
          
          @media (min-width: $breakpoint-md) {
            margin: 0 0 0 4em;
          }
        }
      }
    }

    &--logos {
      margin-top: -16em !important;

      .col {
        display: flex;
        flex-direction: column;
        gap: 5rem;

        @media (min-width: $breakpoint-sm) {
          flex-direction: row;
        }

        &--right {
          margin-top: 5em;
           
          @media (min-width: $breakpoint-sm) {
            justify-content: end;
            margin-top: 0;
          }
        }
      }
    }

    &--logotipos {
      .logotipo-02 {
        @media (max-width: $breakpoint-md) {
          margin-top: 5em !important;
        }
      }
    }

    &--colorPalette {
      position: relative;
      padding-top: 5em;
      
      @media (min-width: $breakpoint-md) {
        padding-top: 16em;
      }

      .phones {
        margin-top: 10em;
      }

      .bgGray {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        height: 90%;
        object-fit: cover;

        @media (min-width: $breakpoint-md) {
          height: 80%;
        }
      }
    }

    &--screenshots {
      position: relative;
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(208,208,208,1) 100%);

      &:after {
        position: absolute;
        content: '';
        height: 100%;
        display: block;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: #ddd;
        z-index: 1;
        
        @media (min-width: $breakpoint-md) {
          height: 50%;
        }
      }

      .flex-photos {
        z-index: 2;
        position: relative;
        gap: 1em;

        img {
          margin: 2em 0;

          @media (min-width: $breakpoint-md) {
            margin: 0 !important;
          }
        }
      }

    }

    &--tabletScreenshots {
      background: linear-gradient(180deg, rgba(21,60,120,1) 0%, rgba(132,191,218,1) 100%);
      padding-bottom: 20em;

      .flex-photos {
        gap: 3em;
      }

      .top {
        margin-bottom: 2rem;
        margin-top: 2em;
        
        @media (min-width: $breakpoint-md) {
          margin-top: -35em;
        }
      }

    }

    &--magazines {
      margin-top: -8em !important;

      @media (min-width: $breakpoint-md) {
        margin-top: -12em !important;
      }
    }

  } //End case Teletica

  
  &_metagol {
    &--flex {
      .flex-img-text {
        margin-top: -15em;

        .flex-img {
          @media (min-width: $breakpoint-sm) {
            margin-left: -20em;
          }

          @media (min-width: $breakpoint-md) {
            margin-left: -15em;
          }
        }

        .flex-text {
          margin-top: 0;
          margin-bottom: 5em;

          @media (min-width: $breakpoint-md) {
            transform: translateY(-8em);
          }
        }
      }
    }

    &--screenshots {
      img {
        margin-bottom: 2em;

        @media (min-width: $breakpoint-md) {
          margin-bottom: 4em;
        }
      }
    }

    &--laptop {
      img {
        @media (min-width: $breakpoint-md) {
          margin-top: -35em;
          z-index: -1;
        }
      }
    }

    &--screenshotNews {
      .screenshot01 {
        margin-top: -15em !important;

        @media (min-width: $breakpoint-md) {
          margin-top: -55em !important;
        }
      }
    }

    &--phones {
      margin-top: -15em !important;

      @media (min-width: $breakpoint-md) {
        margin-top: -45em !important;
      }
    }

  } //End case Metagol

  &_fantacycling {
    &--flex {

      .logo {
        transform: translate(1em, -30em);
        max-width: 68%;
        
        @media (min-width: $breakpoint-sm) {
          transform: translate(5em, -42em);
        }
        
        @media (min-width: $breakpoint-md) {
          max-width: initial;
          transform: translate(5em, -65em);
        }
      }

      .flex-img-text {
        margin-top: -22em;
        
        @media (min-width: $breakpoint-sm) {
          margin-top: -35em;
        }

        @media (min-width: $breakpoint-md) {
          margin-top: -48em;
        }

        // .flex-img {
        //   @media (min-width: $breakpoint-sm) {
        //     max-width: 55%;
        //   }
        // }
  
        .flex-text {
          @media (min-width: $breakpoint-sm) {
            transform: translateY(-5em);
          }
        }
      }
    }

    &--screenshots {
      @media (min-width: $breakpoint-sm) {
        margin-top: -8em !important;
      }

      img {
        margin-bottom: 2em;
        
        @media (min-width: $breakpoint-md) {
          margin-bottom: 3.5em;
        }

        &.fantacycling-isologo {
          margin-top: 5em;

          @media (min-width: $breakpoint-md) {
            margin-top: 20em;
          }    
        }

        &.fantacycling-divingSuite {
          margin-top: -14em;

          @media (min-width: $breakpoint-md) {
            max-width: 92%;
            margin: 0 auto;
          }    
        }
      }
    }

    &--phones {
      img {
        filter: drop-shadow(0 0 1.5em rgba(0,0,0,0.6));
      }
    }

    &--bgPhone {
      margin-top: -10em !important;
      position: relative;
      z-index: -1;
    }

    &--bottle {
      margin-top: -12em !important;

      @media (min-width: $breakpoint-md) {
        margin-top: -20em !important;
      }
    }

  } //End case Fantacycling
  
}

.video {
  width: 100%;
  position: relative;
  overflow: hidden;

  &__player {
    width: 100%;
  }

  &__video {
    width: 100%;
  }

  &__controls {
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    transition: all .5s ease;

    a {
      &.play {
        color: #fff;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.pause {
        color: #fff;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 5em;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        display: none;
      }
    }
  }

  &.playing {
    .video__controls {
      background: rgba(0, 0, 0, 0);

      a {
        &.play {
          display: none;
        }

        &.pause {
          display: flex;
        }
      }
    }
  }
}