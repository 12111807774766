.page-about-us{
    padding-bottom: 0;

    .bgText{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        pointer-events: none;
    
        &__inner {
            position: absolute;

            &Circle{
                top: 13em;
                left: 0;
                opacity: 0.7;
                width: 67em;
                height: auto;
                position: absolute;
                transform: translateX(-65%);

                &Cont{
                    width: 100%;

                    svg{
                        width: 100%;
                        height: auto;
                        display: block;

                        path{
                            fill: #eaeaea;
                        }
                    }
                }

                @media (max-width: $breakpoint-xs) {
                    width: 57em;
                }

                @media (max-width: $breakpoint-s) {
                    width: 47em;
                }
            }
        
            &--01{
                top: 15em;
                right: -1em;

                p{
                    font-size: 6vw!important;
                }

                @media (max-width: $breakpoint-sm) {
                    top: 12em;
                }

                @media (max-width: $breakpoint-xs) {
                    top: 7em;
                }

                @media (max-width: $breakpoint-s) {
                    top: 9em;
                }
            }
        
            &--02{
                top: 75em;
                left: -1em;
                transform: translateY(-50%);
                opacity: 0.4;

                p{
                    color: #fff;
                }

                @media (max-width: $breakpoint-xs) {
                    top: 70em;
                }

                @media (max-width: $breakpoint-s) {
                    top: 60em;
                }
            }
        
            &--03{
                top: 93em;
                right: -1em;
                opacity: 0.15;

                p{
                    text-align: right;
                    color: #fff;
                }

                @media (max-width: $breakpoint-xs) {
                    top: 83em;
                    opacity: 0.2;
                }

                @media (max-width: $breakpoint-s) {
                    top: 104em;
                    opacity: 0.3;
                }
            }
        
            p{
                font-size: 6.5vw;
                letter-spacing: -0.03em;
                font-weight: 500;
                margin: 0;
                padding: 0;
                line-height: 95%;
                color: #eaeaea;
                white-space: nowrap;
        
                @media (max-width: $breakpoint-md) {
                    font-size: 7vw;
                }

                @media (max-width: $breakpoint-xs) {
                    font-size: 8vw;
                }
            }
        }
    }

    .aboutUs {
        &-intro{
            width: 100%;
            padding-bottom: 7em;
            position: relative;
            z-index: 2;
    
            @media (max-width: $breakpoint-sm) {
                padding-bottom: 5em;
            }
        
            @media (max-width: $breakpoint-xs) {
                padding-bottom: 5em;
            }
        
            &Cont{
                display: flex;
                align-items: flex-end;
            
                @media (max-width: $breakpoint-sm) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
    
            &Secondary{
                width: 100%;
                padding-top: 3em;
    
                p{
                    font-size: 2em;
                    line-height: 120%;
                    margin: 0;
                    letter-spacing: -.02em;
                    font-weight: 300;
                    color: rgba(0, 0, 0, 0.7);
    
                    strong{
                        font-weight: 400;
                        color: #505050;
                    }
                }
            }
        
            .title{
                flex: 0 0 auto;
                margin: 0;
                padding: 0 1.5em 0 0;
                position: relative;
                width: 67%;
            
                @media (max-width: $breakpoint-md) {
                    padding: 0 1.3em 0 0;
                }
            
                @media (max-width: $breakpoint-sm) {
                    padding: 0 0 0.5em 0;
                    margin: 0;
                    width: 100%;
    
                    br{
                        display: none;
                    }
                }
    
                @media (max-width: $breakpoint-s) {
                    padding: 0 0 1em 0;
                }
            
                strong{
                    color: inherit;
                    font-weight: 300;
                }
            }
        
            .description{
                flex: 1 1 auto;
                min-width: 0.1em;
                width: 100%;
                padding: 0;
                margin: 0;
    
                strong{
                    font-weight: 400;
                    color: #505050;
                }
            }
        }
    
        &-circles {
            width: 100%;
            position: relative;
            z-index: 2;
    
            &Cont{
                width: 100%;
                max-width: 80em;
                position: relative;
                margin: 0 auto;
                height: 58em;
    
                @media (max-width: $breakpoint-sm) {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    
        &-circle{
            position: absolute;
            z-index: 1;
            z-index: 2;
            
            &--01{
                z-index: 2;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
    
                .aboutUs-circleBack{    
                    animation: circleAbout01 2s ease-in-out infinite;
                }
            }
    
            &--02{
                bottom: 0;
                left: 0;
                z-index: 1;
    
                .aboutUs-circleBack{    
                    animation: circleAbout02 3s ease-in-out infinite;
    
                    @media (max-width: $breakpoint-xs) {
                        animation: circleAbout01 3s ease-in-out infinite;
                    }
                }
            }
    
            &--03{
                bottom: 0;
                right: 0;
                z-index: 0;
    
                .aboutUs-circleBack{    
                    animation: circleAbout03 4s ease-in-out infinite;
    
                    @media (max-width: $breakpoint-xs) {
                        animation: circleAbout01 4s ease-in-out infinite;
                    }
                }
            }
    
            @media (max-width: $breakpoint-sm) {
                position: relative;
                display: flex;
                justify-content: center;
    
                &--01{
                    top: auto;
                    left: auto;
                    transform: none;
                }
        
                &--02{
                    top: auto;
                    left: auto;
                    transform: translateY(-4em);
                }
        
                &--03{
                    top: auto;
                    left: auto;
                    transform: translateY(-8em);
                }
            }
    
            &Cont{
                width: 36em;
                height: 36em;
    
                @media (max-width: $breakpoint-xs) {
                    width: 34em;
                    height: 34em;
                }
    
                @media (max-width: $breakpoint-s) {
                    width: 32em;
                    height: 32em;
                }
            }
    
            &Back{
                width: 100%;
                height: 100%;
                border-radius: 100%;
                backdrop-filter: blur(8px) brightness(85%);
                -webkit-backdrop-filter: blur(8px) brightness(85%);
                box-shadow: 7px 5px 13px 0px rgba(0, 0, 0, 0.1);
            }
    
            &Info{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
    
            &Text{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 100%;
                text-align: center;
                padding: 3em;
            }
    
            &Titles{
                p{
                    font-size: 4.5em;
                    letter-spacing: -0.02em;
                    font-weight: 300;
                    line-height: 95%;
                    color: $color-black-main;
                    width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
    
                    @media (max-width: $breakpoint-xs) {
                        font-size: 3.5em;
                        line-height: 120%;
                    }
    
                    @media (max-width: $breakpoint-s) {
                        font-size: 3em;
                    }
                }
    
                span{
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 2em;
                    font-weight: 300;
                    letter-spacing: 0;
                    display: block;
                    line-height: 100%;
                    width: 100%;
    
                    @media (max-width: $breakpoint-xs) {
                        font-size: 1.8em;
                    }
                }
            }
    
            &Description{
                position: relative;
                padding-top: 2em;
                margin-top: 1.8em;
                display: none;
    
                &:after{
                    content:"";
                    width: 90%;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 1px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    position: absolute;
                }
    
                p{
                    font-size: 1.6em;
                    line-height: 120%;
                    margin: 0;
                    padding: 0;
                    letter-spacing: -.02em;
                    font-weight: 300;
                    color: $color-black-main;
                    font-style: normal;
                }
            }
    
            &Btn{
                position: absolute;
                bottom: 3em;
                left: 50%;
                transform: translateX(-50%);
                display: block;
    
                @media (max-width: $breakpoint-xs) {
                    bottom: 2em;
                }
    
                @media (max-width: $breakpoint-s) {
                    bottom: 1em;
                }
                
                .btnLink{
                    background: transparent;
                    border: 0;
                    padding: 0;
                    display: block;
    
                    .arrow-icon {
                        top: 0;
    
                        .arrow-icon--lineV{
                            opacity: 1;
                            transition: opacity 300ms linear;
    
                            &.hide-svg{
                                opacity: 0;
                            }
                        }
                    }
    
                    &:hover {
                        .arrow-icon {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        } 
    
        // &-textSecondary{
        //     width: 100%;
        //     padding: 5em 0 7em;
        //     position: relative;
        //     z-index: 2;
        
        //     @media (max-width: $breakpoint-sm) {
        //         padding: 0 0 3em;
        //     }
        
        //     &Cont{
        //         display: flex;
        //         align-items: center;
        //         max-width: 950px;
        //         margin: 0 auto;
            
        //         @media (max-width: $breakpoint-sm) {
        //             flex-direction: column;
        //             align-items: flex-start;
        //         }
        //     }
    
        //     .subtitle{
        //         flex: 0 0 auto;
        //         margin: 0;
        //         padding: 0 0 0 1.5em;
        //         position: relative;
        //         width: 50%;
        //         color: #fff;
        //         font-size: 4.5em;
        //         letter-spacing: -0.02em;
        //         font-weight: 100;
        //         line-height: 120%;
        //         text-align: right;
            
        //         @media (max-width: $breakpoint-md) {
        //             padding: 0 0 0 1.3em;
        //         }
            
        //         @media (max-width: $breakpoint-sm) {
        //             padding: 0 0 0.5em 0;
        //             margin: 0;
        //             width: 100%;
        //             order: 1;
        //             text-align: left;
    
        //             br{
        //                 display: none;
        //             }
        //         }
    
        //         @media (max-width: $breakpoint-s) {
        //             font-size: 3em;
        //             padding: 0 0 1em 0;
        //         }
            
        //         strong{
        //             color: inherit;
        //             font-weight: 400;
        //         }
        //     }
        
        //     .description{
        //         flex: 1 1 auto;
        //         min-width: 0.1em;
        //         width: 100%;
        //         padding: 0;
        //         margin: 0;
        //         color: rgba(255, 255, 255, 1);
        //         line-height: 140%;
    
        //         strong{
        //             font-weight: 400;
        //             color: inherit;
        //         }
    
        //         @media (max-width: $breakpoint-sm) {
        //             order: 2;
        //         }
    
        //         @media (max-width: $breakpoint-xs) {
        //             color: rgba(255, 255, 255, 1);
        //         }
        //     }
        // }
    
        &-clients{
            position: relative;
            padding: 2em 0 4em;
            //touch-action: manipulation;
        
            .smoothLeft{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 5%;
                z-index: 2;
                pointer-events: none;
                display: none;
            }
        
            .smoothRight{
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 60%;
                z-index: 2;
                pointer-events: none;
                transition: opacity 500ms linear;
                display: none;
        
                &.inactive{
                    opacity: 0;
                }
            }
        
            &Slider {
                height: 11em;
                line-height: 11em;
                overflow: hidden;
                position: relative;
                z-index: 1;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    height: 100%;

                    @media (min-width: 2450px) {
                        transform: translate(0)!important;
                        margin: 0 auto;
                    }

                    li {
                        float: left;
                        width: auto;
                        height: 100%;
                        margin: 0;
                        padding: 0;
                        cursor: pointer;
                        //transition: opacity 500ms ease;
                    
                        &.clientsSlider {
                            &__logo {
                                color: #555555;
                                display: block;
                                max-height: 11em;
                                position: relative;
                                overflow: hidden;
                                padding: 0 3em;

                                @media (min-width: 2450px) {
                                    padding: 0 2.5em;
                                }
        
                                .clientsSlider__logoSvg{
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    opacity: 0.35;
        
                                    @media (max-width: $breakpoint-s) {
                                        opacity: 0.5;
                                    }
                                }
                    
                                svg {
                                    width: 100%;
                                    height: auto;
                                    font-size: 3.5px;
                    
                                    * {
                                        fill: #fff;
                                    }
                                }
                    
                                &--pg {
                                    svg {
                                        width: 22em;
                                    }
                                }
                            
                                &--yellowpepper {
                                    svg {
                                        width: 45em;
                                        margin-bottom: 2em;
                                    }
                                }
        
                                &--tradestation {
                                    svg {
                                        width: 60em;
                                    }
                                }
                            
                                &--vw {
                                    svg {
                                        width: 14em;
                                    }
                                }
                            
                                &--microsoft {
                                    svg{
                                        width: 40em;
                                    }
                                }
                                
                                &--crypto {
                                    svg {
                                        width: 30em;
                                    }
                                }
                            
                                &--adobe {
                                    svg {
                                        width: 14em;
                                    }
                                }
                        
                                &--davita {
                                    padding-right: 1.8em;
                        
                                    svg{
                                        width: 32em;
                                    }
                                }
                            
                                &--servicenow {
                                    padding-right: 2em;
                    
                                    svg{
                                        width: 40em;
                                    }
                                }
                    
                                &--pbs {
                                    svg {
                                        width: 27em;
                                    }
                                }
                    
                                &--visa {
                                    padding-left: 2em;
                        
                                    svg {
                                        width: 28em;
                                    }
                                }
                            
                                &--apple {
                                    svg {
                                        width: 10.5em;
                                        margin-bottom: 3em;
                                    }
                                }
                            
                                &--delta {
                                    svg{
                                        width: 55em;
                                    }
                                }
                            
                                &--qbe {
                                    svg {
                                        width: 30em;
                                    }
                                }
                            
                                &--cybersource {
                                    svg {
                                        width: 42em;
                                    }
                                }
                            }
                        }
                    }
                }

                &.noSlide{
                    height: auto;
                    line-height: 100%;

                    ul {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-wrap: wrap; 
                        margin: 0 -1em;
                        height: auto;

                        li{
                            float: none;
                            width: auto;
                            height: auto;
                            cursor: default;

                            &.clientsSlider{
                                &__logo {
                                    padding: 0 1em;
                                    width: 14.28%;
                                    max-height: 100%;
                                    overflow: visible;
                                    margin-bottom: 3em;

                                    svg{
                                        width: 100%;
                                    }

                                    &--pg {
                                        svg {
                                            max-width: 17em;
                                        }
                                    }
                                
                                    &--yellowpepper {
                                        svg {
                                            max-width: 40em;
                                            margin-bottom: 2em;
                                        }
                                    }
            
                                    &--tradestation {
                                        svg {
                                            max-width: 60em;
                                        }
                                    }
                                
                                    &--vw {
                                        svg {
                                            max-width: 11em;
                                        }
                                    }
                                
                                    &--microsoft {
                                        svg{
                                            max-width: 30em;
                                        }
                                    }
                                    
                                    &--crypto {
                                        svg {
                                            max-width: 23em;
                                        }
                                    }
                                
                                    &--adobe {
                                        svg {
                                            max-width: 10em;
                                        }
                                    }
                            
                                    &--davita {
                                        padding-right: 1em;
                            
                                        svg{
                                            max-width: 26em;
                                        }
                                    }
                                
                                    &--servicenow {
                                        padding-right: 1em;
                        
                                        svg{
                                            max-width: 30em;
                                        }
                                    }
                        
                                    &--pbs {
                                        svg {
                                            max-width: 22em;
                                        }
                                    }
                        
                                    &--visa {
                                        padding-left: 1em;
                            
                                        svg {
                                            max-width: 21em;
                                        }
                                    }
                                
                                    &--apple {
                                        svg {
                                            max-width: 8em;
                                            margin-bottom: 2em;
                                        }
                                    }
                                
                                    &--delta {
                                        svg{
                                            max-width: 30em;
                                        }
                                    }
                                
                                    &--qbe {
                                        svg {
                                            max-width: 22em;
                                        }
                                    }
                                
                                    &--cybersource {
                                        svg {
                                            max-width: 32em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        
            &Scrollbar{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 2em;
        
                @media (max-width: $breakpoint-xs) {
                    padding-top: 3em;
                }

                @media (min-width: 2450px) {
                    display: none;
                }
                
                &Cont{
                    padding: 2em 3em;
                    border-radius: 5em;
                    border: 1px solid #959494;
                    max-width: 20em;
                    width: 100%;

                    @media (max-width: $breakpoint-md) {
                        padding: 2.5em 4em;
                    }
                }
        
                .scrollbar {
                    height: 3px;
                    background: rgba(255, 255, 255, 0.13);
                    line-height: 0;
                    width: 100%;
                    border-radius: 1em;
                
                    .handle {
                        height: 5px;
                        background: #B9B9B9;
                        cursor: pointer;
                        border-radius: 1em;
                        position: relative;
                        top: -1px;
                
                        .mousearea {
                            position: absolute;
                            top: -7px;
                            left: 0;
                            width: 100%;
                            height: 20px;
                        }
                    }
                }
            }
        }
    
        &-chart{
            width: 100%;

            @media (max-width: $breakpoint-sm) {
                display: none;
            }

            &-mobile{
                width: 100%;
                display: none;
                padding-bottom: 5em;

                @media (max-width: $breakpoint-sm) {
                    display: block;
                }

                @media (max-width: $breakpoint-s) {
                    padding-bottom: 4em;
                }

                .aboutUs-chartCont{
                    &.animate{
                        &[data-aos]{
                            .aboutUs-chartMainCircle{
                                opacity: 0;
                                transform: translateX(10%);
                                transition: opacity 600ms linear 0s, transform 1000ms ease 0s;
                            }
        
                            .aboutUs-chartCenterLine{
                                width: 0;
                                transition: width 1800ms ease 800ms;
                            }
        
                            .aboutUs-chartCircle{
                                opacity: 0;
                                transform: scale(0.4);
                                transition: opacity 600ms linear, transform 600ms cubic-bezier(.47,1.64,.41,.8);
        
                                &--creative,
                                &--design,
                                &--ai{
                                    transition-delay: 1000ms;
                                }
        
                                &--digital,
                                &--uxui,
                                &--development{
                                    transition-delay: 1300ms;
                                }
        
                                &--execution{
                                    transition-delay: 1600ms;
                                }
                            }
        
                            .aboutUs-chartItem{
                                .aboutUs-chartTitle{
                                    opacity: 0;
                                    transform: translateY(calc(-50% - 2em));
                                    transition: opacity 600ms linear, transform 600ms ease;
                                }
        
                                .aboutUs-chartFeaturesDot{
                                    span{
                                        &.dot{
                                            opacity: 0;
                                            transition: opacity 600ms linear;
                                        }
                                    }
        
                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                height: 0;
                                                transition: height 600ms ease;
                                            }
                                        }
        
                                        p{
                                            opacity: 0;
                                            transition: opacity 600ms linear;
                                        }
                                    }
                                }
        
                                &--creative,
                                &--design,
                                &--ai{
                                    .aboutUs-chartTitle{
                                        transition-delay: 1100ms; 
                                    }
        
                                    .aboutUs-chartFeaturesItem{
                                        &:nth-child(1){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 1200ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 1300ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 1600ms;
                                                }
                                            }
                                        }
                                        &:nth-child(2){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 1300ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 1400ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 1700ms;
                                                }
                                            }
                                        }
                                        &:nth-child(3){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 1400ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 1500ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 2000ms;
                                                }
                                            }
                                        }
                                        &:nth-child(4){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 1500ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 1600ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 2000ms;
                                                }
                                            }
                                        }
                                    }
                                }
        
                                &--digital,
                                &--uxui,
                                &--development{
                                    .aboutUs-chartTitle{
                                        transition-delay: 1400ms; 
                                    }
        
                                    .aboutUs-chartFeaturesItem{
                                        &:nth-child(1){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 1500ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 1600ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 1900ms;
                                                }
                                            }
                                        }
                                        &:nth-child(2){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 1600ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 1700ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 2000ms;
                                                }
                                            }
                                        }
                                        &:nth-child(3){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 1700ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 1800ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 2100ms;
                                                }
                                            }
                                        }
                                    }
                                }
        
                                &--execution{
                                    .aboutUs-chartTitle{
                                        transition-delay: 1700ms; 
                                    }
        
                                    .aboutUs-chartFeaturesItem{
                                        &:nth-child(1){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 1800ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 1900ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 2200ms;
                                                }
                                            }
                                        }
                                        &:nth-child(2){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 1900ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 2000ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 2300ms;
                                                }
                                            }
                                        }
                                        &:nth-child(3){
                                            .aboutUs-chartFeaturesDot{
                                                span{
                                                    &.dot{
                                                        transition-delay: 2000ms; 
                                                    }
                                                }
                                            }
        
                                            .aboutUs-chartFeaturesInfo{
                                                span{
                                                    &.line{
                                                        transition-delay: 2100ms;
                                                    }
                                                }
                    
                                                p{
                                                    transition-delay: 2400ms;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
        
                            &.aos-animate{
                                .aboutUs-chartMainCircle{
                                    opacity: 1;
                                    transform: translateX(0);
                                }
            
                                .aboutUs-chartCenterLine{
                                    width: 120%;
                                }
            
                                .aboutUs-chartCircle{
                                    opacity: 1;
                                    transform: scale(1);
                                }
            
                                .aboutUs-chartTitle{
                                    opacity: 1;
                                    transform: translateY(-50%);
                                }
            
                                .aboutUs-chartFeaturesItem{
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                opacity: 1;
                                            }
                                        }
                                    }
            
                                    &:nth-child(1){
                                        .aboutUs-chartFeaturesInfo{
                                            span{
                                                &.line{
                                                    height: 6.3em;
                                                }
                                            }
                                        }
                                    }
            
                                    &:nth-child(2){
                                        .aboutUs-chartFeaturesInfo{
                                            span{
                                                &.line{
                                                    height: 3.5em;
                                                }
                                            }
                                        }
                                    }
            
                                    &:nth-child(3){
                                        .aboutUs-chartFeaturesInfo{
                                            span{
                                                &.line{
                                                    height: 7.8em;
                                                }
                                            }
                                        }
                                    }
            
                                    &:nth-child(4){
                                        .aboutUs-chartFeaturesInfo{
                                            span{
                                                &.line{
                                                    height: 2em;
                                                }
                                            }
                                        }
                                    }
            
                                    .aboutUs-chartFeaturesInfo{
                                        p{
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .container{
                @media (min-width: 1260px) {
                    width: 1230px;
                }
            }

            &Block{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }

            &Cont{
                width: 100%;
                position: relative;

                @media (max-width: $breakpoint-sm) {
                    max-width: 47em;
                    margin: -5em auto 0;

                    &:first-child{
                        margin-top: 0;
                    }
                }

                @media (max-width: $breakpoint-s) {
                    max-width: 36em;
                    margin: -2em auto 0;

                    &:first-child{
                        margin-top: 0;
                    }
                }

                @media (max-width: $breakpoint-xxs) {
                    max-width: 32em;
                    margin: -1em auto 0;

                    &:first-child{
                        margin-top: 0;
                    }
                }
            }

            &MainCircles{
                padding: 0 2em;
            }

            &SecondaryCircles{
                width: 100%;
                position: absolute;
                top: 55%;
                transform: translateY(-50%);
                left: 0;

                @media (max-width: $breakpoint-sm) {
                    top: 56%;
                }
            }

            &CenterLine{
                width: 120%;
                height: 1px;
                position: absolute;
                top: 50%;
                left: -10%;
                transform: translateY(-50%);
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(224,224,224,0) 0%, rgba(236,236,236,1) 15%, rgba(255,255,255,1) 50%, rgba(231,231,231,1) 85%, rgba(223,223,223,0) 100%);
            }

            &MainCircles,
            &Circles,
            &Items{
                width: 100%;
                display: flex;
                align-items: center;
                position: relative;

                @media (max-width: $breakpoint-sm) {
                    justify-content: center;
                }
            }          

            &Items{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }

            &MainCircle{
                flex: 0 0 auto;
                margin-left: -7.5%;
                position: relative;
                
                &Cont{
                    padding-top: 100%;
                    background: rgb(0,0,0);
                    background: linear-gradient(192deg, rgba(0,0,0,1) 0%, rgba(63,63,63,0.756827731092437) 35%, rgba(219,219,219,0) 100%);
                    border-radius: 100%;
                    opacity: 0.14;
                }

                &:first-child{
                    margin-left: 0;
                }

                &--creative{
                    width: 43%;

                    @media (max-width: $breakpoint-sm) {
                        width: 100%;
                    }

                    .aboutUs-chartMainTitle {
                        top: 7.5em;

                        @media (max-width: $breakpoint-md) {
                            top: 6.5em;
                        }

                        @media (max-width: $breakpoint-sm) {
                            top: 5.5em;
                        }

                        @media (max-width: $breakpoint-s) {
                            top: 4.5em;
                        }

                        @media (max-width: $breakpoint-xxs) {
                            top: 3.5em;
                        }
                    }
                }

                &--strategy,
                &--technology{
                    width: 36%;

                    @media (max-width: $breakpoint-sm) {
                        width: 100%;
                    }
                }
            }

            &Circle{
                background: rgb(0,0,0);
                background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
                border-radius: 100%;
                opacity: 0.75;
            }

            &Circle,
            &Item{
                flex: 0 0 auto;
                margin-left: -3.5%;

                @media (max-width: $breakpoint-sm) {
                    margin-left: -7%;
                }
                
                &Cont{
                    padding-top: 100%;
                    position: relative;
                }

                &:first-child{
                    margin-left: 0;
                }

                &--creative{
                    width: 18%;

                    @media (max-width: $breakpoint-sm) {
                        width: 52%;
                    }

                    .aboutUs-chartFeaturesItem{
                        &:nth-child(1){
                            padding-left: 2.5em!important;

                            @media (max-width: $breakpoint-md) {
                                padding-left: 1.9em!important;
                            }

                            @media (max-width: $breakpoint-sm) {
                                padding-left: 2.9em!important;
                            }

                            @media (max-width: $breakpoint-sm) {
                                padding-left: 2.6em!important;
                            }

                            @media (max-width: $breakpoint-xxs) {
                                padding-left: 2.2em!important;
                            }
                        }
                    }
                }

                &--digital{
                    width: 20%;

                    @media (max-width: $breakpoint-sm) {
                        width: 52%;
                    }
                }

                &--design{
                    width: 19%;

                    @media (max-width: $breakpoint-sm) {
                        width: 52%;
                    }

                    .aboutUs-chartFeaturesItem{
                        padding-left: 3.2em;

                        @media (max-width: $breakpoint-md) {
                            padding-left: 2.5em;
                        }

                        @media (max-width: $breakpoint-sm) {
                            padding-left: 4.3em;
                        }

                        @media (max-width: $breakpoint-s) {
                            padding-left: 3em;
                        }

                        @media (max-width: $breakpoint-xxs) {
                            padding-left: 2.5em!important;
                        }
                    }
                }

                &--uxui{
                    width: 21%;

                    @media (max-width: $breakpoint-sm) {
                        width: 52%;
                    }
                }

                &--ai{
                    width: 15%;

                    @media (max-width: $breakpoint-sm) {
                        width: 37%;
                    }

                    .aboutUs-chartFeaturesItem{
                        &:nth-child(1){
                            .aboutUs-chartFeaturesInfo{
                                span{
                                    &.line{
                                        height: 3.5em;

                                        @media (max-width: $breakpoint-sm) {
                                            height: 4em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &--development{
                    width: 13%;

                    @media (max-width: $breakpoint-sm) {
                        width: 37%;
                    }

                    .aboutUs-chartFeaturesItem{
                        @media (max-width: $breakpoint-xxs) {
                            padding-left: 3.9em!important;
                        }

                        @media (max-width: $breakpoint-xxs) {
                            padding-left: 3.7em!important;
                        }
                    }
                }

                &--execution{
                    width: 15%;

                    @media (max-width: $breakpoint-sm) {
                        width: 37%;
                    }

                    .aboutUs-chartFeaturesItem{
                        padding-left: 3.2em;

                        @media (max-width: $breakpoint-md) {
                            padding-left: 2.5em;
                        }

                        @media (max-width: $breakpoint-sm) {
                            padding-left: 4.3em;
                        }

                        @media (max-width: $breakpoint-s) {
                            padding-left: 3em;
                        }

                        @media (max-width: $breakpoint-xxs) {
                            padding-left: 2.2em!important;
                        }

                        &:nth-child(1){
                            padding-left: 0.5em!important;

                            @media (max-width: $breakpoint-md) {
                                padding-left: 1em!important;
                            }

                            @media (max-width: $breakpoint-sm) {
                                padding-left: 1.5em!important;
                            }
                        }
                    }
                }
            }

            &MainTitle{
                position: absolute;
                top: 5.5em;
                left: 0;
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                @media (max-width: $breakpoint-md) {
                    top: 3.5em;
                }

                @media (max-width: $breakpoint-sm) {
                    top: 5.5em;
                }

                @media (max-width: $breakpoint-s) {
                    top: 4.5em;
                }

                @media (max-width: $breakpoint-xxs) {
                    top: 3.5em;
                }

                span{
                    display: block;
                    font-size: 1.4em;
                    line-height: 100%;
                    margin: 0;
                    padding: 0;
                    letter-spacing: -0.025em;
                    font-weight: 400;
                    color: rgba(59, 59, 59, 0.5);
                    text-align: center;
                    text-transform: uppercase;
                    padding-bottom: 0.3em;

                    @media (max-width: $breakpoint-sm) {
                        font-size: 1.6em;
                    }

                    @media (max-width: $breakpoint-s) {
                        font-size: 1.4em;
                        padding-bottom: 0;
                    }
                }

                h3{
                    font-size: 3em;
                    line-height: 100%;
                    margin: 0;
                    padding: 0;
                    letter-spacing: -0.02em;
                    font-weight: 400;
                    color: $color-black-main;
                    display: inline-block;
                    text-align: center;

                    @media (max-width: $breakpoint-md) {
                        font-size: 2.5em;
                    }

                    @media (max-width: $breakpoint-sm) {
                        font-size: 3.5em;
                    }

                    @media (max-width: $breakpoint-s) {
                        font-size: 2.5em;
                    }
                }
            }

            &Title{
                position: absolute;
                top: calc(50% - 3.5em);
                left: 0;
                width: 100%;
                text-align: center;
                transform: translateY(-50%);
                min-height: 4.4em;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                @media (max-width: $breakpoint-sm) {
                    top: calc(50% - 4em);
                }

                @media (max-width: $breakpoint-s) {
                    top: calc(50% - 3.5em);
                }

                h4{
                    font-size: 2em;
                    line-height: 110%;
                    margin: 0;
                    padding: 0;
                    font-weight: 400;
                    color: #fff;
                    display: inline-block;
                    text-align: center;

                    @media (max-width: $breakpoint-md) {
                        font-size: 1.6em;
                    }

                    @media (max-width: $breakpoint-sm) {
                        font-size: 2.1em;
                    }

                    @media (max-width: $breakpoint-s) {
                        font-size: 1.6em;
                    }
                }
            }

            &Features{
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);

                &Item{
                    padding-left: 4em;

                    @media (max-width: $breakpoint-md) {
                        padding-left: 3em;
                    }

                    @media (max-width: $breakpoint-sm) {
                        padding-left: 5em;
                    }

                    @media (max-width: $breakpoint-s) {
                        padding-left: 3.4em;
                    }

                    @media (max-width: $breakpoint-xxs) {
                        padding-left: 3em;
                    }

                    &:nth-child(1){
                        padding-left: 0!important;

                        .aboutUs-chartFeaturesInfo{
                            span{
                                &.line{
                                    height: 6.3em;

                                    @media (max-width: $breakpoint-sm) {
                                        height: 8.5em;
                                    }

                                    @media (max-width: $breakpoint-s) {
                                        height: 8em;
                                    }

                                    @media (max-width: $breakpoint-xxs) {
                                        height: 7.5em;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(2){
                        .aboutUs-chartFeaturesInfo{
                            span{
                                &.line{
                                    height: 3.5em;

                                    @media (max-width: $breakpoint-sm) {
                                        height: 4em;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(3){
                        .aboutUs-chartFeaturesInfo{
                            span{
                                &.line{
                                    height: 7.8em;

                                    @media (max-width: $breakpoint-sm) {
                                        height: 8.5em;
                                    }

                                    @media (max-width: $breakpoint-s) {
                                        height: 8em;
                                    }

                                    @media (max-width: $breakpoint-xxs) {
                                        height: 7.5em;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(4){
                        .aboutUs-chartFeaturesInfo{
                            span{
                                &.line{
                                    height: 2em;

                                    @media (max-width: $breakpoint-sm) {
                                        height: 4em;
                                    }
                                }
                            }
                        }
                    }
                }

                &Dot{
                    position: relative;

                    span{
                        &.dot{
                            display: block;
                            width: 0.9em;
                            height: 0.9em;
                            border: 2px solid #dfdfdf;
                            background-color: #000;
                            border-radius: 100%;

                            @media (max-width: $breakpoint-sm) {
                                width: 1.1em;
                                height: 1.1em;
                            }
                        }
                    }
                }

                &Info{
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%); 
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;

                    span{
                        &.line{
                            width: 1px;
                            display: block;
                            margin: 0.5em 0 0.2em;
                            background: rgb(255,255,255);
                            background: linear-gradient(180deg, rgba(160,160,160,0) 0%, rgba(211,211,211,1) 15%, rgba(213,213,213,1) 50%, rgba(211,211,211,1) 85%, rgba(160,160,160,0) 100%);
                        }
                    }

                    p{
                        font-size: 1.3em;
                        line-height: 105%;
                        margin: 0;
                        padding: 0;
                        font-weight: 400;
                        color: $color-black-main;
                        display: inline-block;
                        text-align: center;

                        @media (max-width: $breakpoint-sm) {
                            font-size: 1.6em;
                        }

                        @media (max-width: $breakpoint-s) {
                            font-size: 1.4em;
                        }
                    }
                }
            }

            &.animate{
                &[data-aos]{
                    .aboutUs-chartMainCircle{
                        opacity: 0;
                        transform: translateX(10%);
                        transition: opacity 600ms linear, transform 1000ms ease;

                        &--strategy{
                            transition-delay: 0s;
                        }

                        &--creative{
                            transition-delay: 300ms;
                        }

                        &--technology{
                            transition-delay: 600ms;
                        }
                    }

                    .aboutUs-chartCenterLine{
                        width: 0;
                        transition: width 3000ms ease 800ms;
                    }

                    .aboutUs-chartCircle{
                        opacity: 0;
                        transform: scale(0.4);
                        transition: opacity 600ms linear, transform 600ms cubic-bezier(.47,1.64,.41,.8);

                        &--creative{
                            transition-delay: 1000ms;
                        }

                        &--digital{
                            transition-delay: 1300ms;
                        }

                        &--design{
                            transition-delay: 1600ms;
                        }

                        &--uxui{
                            transition-delay: 1900ms;
                        }

                        &--ai{
                            transition-delay: 2200ms;
                        }

                        &--development{
                            transition-delay: 2500ms;
                        }

                        &--execution{
                            transition-delay: 2800ms;
                        }
                    }

                    .aboutUs-chartItem{
                        .aboutUs-chartTitle{
                            opacity: 0;
                            transform: translateY(calc(-50% - 2em));
                            transition: opacity 600ms linear, transform 600ms ease;
                        }

                        .aboutUs-chartFeaturesDot{
                            span{
                                &.dot{
                                    opacity: 0;
                                    transition: opacity 600ms linear;
                                }
                            }

                            .aboutUs-chartFeaturesInfo{
                                span{
                                    &.line{
                                        height: 0;
                                        transition: height 600ms ease;
                                    }
                                }

                                p{
                                    opacity: 0;
                                    transition: opacity 600ms linear;
                                }
                            }
                        }

                        &--creative{
                            .aboutUs-chartTitle{
                                transition-delay: 1100ms; 
                            }

                            .aboutUs-chartFeaturesItem{
                                &:nth-child(1){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 1200ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 1300ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 1600ms;
                                        }
                                    }
                                }
                                &:nth-child(2){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 1300ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 1400ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 1700ms;
                                        }
                                    }
                                }
                                &:nth-child(3){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 1400ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 1500ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2000ms;
                                        }
                                    }
                                }
                                &:nth-child(4){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 1500ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 1600ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2000ms;
                                        }
                                    }
                                }
                            }
                        }

                        &--digital{
                            .aboutUs-chartTitle{
                                transition-delay: 1400ms; 
                            }

                            .aboutUs-chartFeaturesItem{
                                &:nth-child(1){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 1500ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 1600ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 1900ms;
                                        }
                                    }
                                }
                                &:nth-child(2){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 1600ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 1700ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2000ms;
                                        }
                                    }
                                }
                                &:nth-child(3){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 1700ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 1800ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2100ms;
                                        }
                                    }
                                }
                            }
                        }

                        &--design{
                            .aboutUs-chartTitle{
                                transition-delay: 1700ms; 
                            }

                            .aboutUs-chartFeaturesItem{
                                &:nth-child(1){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 1800ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 1900ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2200ms;
                                        }
                                    }
                                }
                                &:nth-child(2){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 1900ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 2000ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2300ms;
                                        }
                                    }
                                }
                                &:nth-child(3){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 2000ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 2100ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2400ms;
                                        }
                                    }
                                }
                                &:nth-child(4){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 2100ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 2200ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2500ms;
                                        }
                                    }
                                }
                            }
                        }

                        &--uxui{
                            .aboutUs-chartTitle{
                                transition-delay: 2000ms; 
                            }

                            .aboutUs-chartFeaturesItem{
                                &:nth-child(1){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 2100ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 2200ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2500ms;
                                        }
                                    }
                                }
                                &:nth-child(2){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 2200ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 2300ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2600ms;
                                        }
                                    }
                                }
                                &:nth-child(3){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 2300ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 2400ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2700ms;
                                        }
                                    }
                                }
                            }
                        }

                        &--ai{
                            .aboutUs-chartTitle{
                                transition-delay: 2300ms; 
                            }

                            .aboutUs-chartFeaturesItem{
                                &:nth-child(1){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 2400ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 2500ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 2800ms;
                                        }
                                    }
                                }
                            }
                        }

                        &--development{
                            .aboutUs-chartTitle{
                                transition-delay: 2600ms; 
                            }

                            .aboutUs-chartFeaturesItem{
                                &:nth-child(1){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 2700ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 2800ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 3100ms;
                                        }
                                    }
                                }
                                &:nth-child(2){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 2800ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 2900ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 3200ms;
                                        }
                                    }
                                }
                            }
                        }

                        &--execution{
                            .aboutUs-chartTitle{
                                transition-delay: 2900ms; 
                            }

                            .aboutUs-chartFeaturesItem{
                                &:nth-child(1){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 3000ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 3100ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 3400ms;
                                        }
                                    }
                                }
                                &:nth-child(2){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 3100ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 3200ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 3500ms;
                                        }
                                    }
                                }
                                &:nth-child(3){
                                    .aboutUs-chartFeaturesDot{
                                        span{
                                            &.dot{
                                                transition-delay: 3200ms; 
                                            }
                                        }
                                    }

                                    .aboutUs-chartFeaturesInfo{
                                        span{
                                            &.line{
                                                transition-delay: 3300ms;
                                            }
                                        }
            
                                        p{
                                            transition-delay: 3600ms;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.aos-animate{
                        .aboutUs-chartMainCircle{
                            opacity: 1;
                            transform: translateX(0);
                        }
    
                        .aboutUs-chartCenterLine{
                            width: 120%;
                        }
    
                        .aboutUs-chartCircle{
                            opacity: 1;
                            transform: scale(1);
                        }
    
                        .aboutUs-chartTitle{
                            opacity: 1;
                            transform: translateY(-50%);
                        }
    
                        .aboutUs-chartFeaturesItem{
                            .aboutUs-chartFeaturesDot{
                                span{
                                    &.dot{
                                        opacity: 1;
                                    }
                                }
                            }
    
                            &:nth-child(1){
                                .aboutUs-chartFeaturesInfo{
                                    span{
                                        &.line{
                                            height: 6.3em;
                                        }
                                    }
                                }
                            }
    
                            &:nth-child(2){
                                .aboutUs-chartFeaturesInfo{
                                    span{
                                        &.line{
                                            height: 3.5em;
                                        }
                                    }
                                }
                            }
    
                            &:nth-child(3){
                                .aboutUs-chartFeaturesInfo{
                                    span{
                                        &.line{
                                            height: 7.8em;
                                        }
                                    }
                                }
                            }
    
                            &:nth-child(4){
                                .aboutUs-chartFeaturesInfo{
                                    span{
                                        &.line{
                                            height: 2em;
                                        }
                                    }
                                }
                            }
    
                            .aboutUs-chartFeaturesInfo{
                                p{
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-moduleText {
            h3,
            h4 {
                margin-bottom: 1.6rem;
            }

            h3 {
                font-weight: 700;
            }

            h3,
            h4 {
                margin-top: 3rem;
            }

            h4 {
                font-size: 2.2em;
                font-weight: 500;
            }

            p,
            li {
                font-size: 2em;
                font-weight: 200;

                strong {
                    font-weight: 500;
                }
            }

            h2 {
                margin-bottom: 2rem;
                font-size: 4rem;

                & + h4 {
                    margin-top: 1.6rem;
                    margin-bottom: 2.5rem;
                }
            }
        }
    }
}

.modal{
    &-window {
        position: absolute;
        background: #fff;
        width: 36em;
        height: 36em;
        border-radius: 100%;
        display: none;
        transition: transform 300ms ease;
        transform: translate(-50%, -50%) scale(0.3);
    
        &-content {
            display: none;
            margin-top: 2em;
        }
    
        &.revealed {
            position: absolute;
            display: block;
            width: 100%;
            max-width: 70em;
            min-height: 40em;
            border-radius: 1em;
            z-index: 9999;
            transform: translate(-50%, -50%) scale(1);
        }
    
        &.fixed {
            position: fixed;
            top: 50%!important;
            left: 50%!important;
        }
        
        #close {
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;
            border: 0;
            color: #555;
            padding: 1em;
            cursor: pointer;
        }
    }

    &-overlay {
        z-index: 999;
        transition: opacity 0.5s ease 0s;
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0;
        pointer-events: none;
    
        &.active{
            opacity: 1;
        }
    }
}