.client {
    padding: 0 0 8em 0;

    @media (max-width: $breakpoint-sm) {
        padding: 0 0 2em 0;
    }

    @media (max-width: $breakpoint-xs) {
        overflow: hidden;
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
    }

    &__logo {
        color: #555555;
        width: 20%;
        display: flex;
        max-height: 200px;
        position: relative;
        overflow: hidden;
        @media (max-width: $breakpoint-sm) {
            width: 33.33%;
        }
        @media (max-width: $breakpoint-xs) {
            width: calc(50% - 1px);
        }
        @media (max-width: $breakpoint-s) {
          max-height: 125px;
        }
        @media (max-width: $breakpoint-xxs) {
            width: 100%;
        }
    }

    &__logo svg {
        width: 100%;
        height: auto;
        transform-origin: center;
    }

    &__logo svg * {
        fill: #555555;
    }

    &__logo--visa svg {
        transform: scale(0.5);
        margin-top: 1em;
    }

    &__logo--apple svg {
        transform: scale(0.5) translateX(2em);
    }

    &__logo--tradestation svg {
        transform: scale(1.25);
        margin-top: 0.8em;
    }

    &__logo--delta svg {
        transform: scale(0.75) translateX(2em);
        margin-top: -0.3em;

        @media (max-width: $breakpoint-sm) {
            transform: scale(0.8);
        }
    }

    &__logo--qbe svg {
        transform: scale(0.5);
        margin-top: -0.6em;
    }

    &__logo--cybersource svg {
        transform: scale(1.1);
    }

    &__logo--pbs svg {
        transform: scale(0.5);
    }

    &__logo--pg svg {
        transform: scale(0.5);
        margin-top: -0.8em;
    }

    &__logo--yellowpepper svg {
        transform: scale(0.75) translateX(2em);

        @media (max-width: $breakpoint-sm) {
            transform: scale(0.75);
        }
    }

    &__logo--vw svg {
        transform: scale(0.45) translateX(2em);
        margin-top: -0.2em;
    }

    &__logo--microsoft svg {
        transform: scale(0.72);
    }

    &__logo--clarin svg {
        transform: scale(0.55);
        margin-top: -0.3em;
    }

    &__logo--crypto svg {
        transform: scale(0.9);
        margin-top: -1.5em;
    }

    &__logo--adobe svg {
        transform: scale(0.75) translateX(2em);
        margin-top: -0.8em;

        @media (max-width: $breakpoint-sm) {
            transform: scale(0.75);
        }
    }

    &__logo--servicenow svg {
        transform: scale(0.8) translateX(2em);
        margin-top: -0.3em;
    }
}
