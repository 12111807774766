.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 3em 0 2em;
    z-index: 200;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.5s ease 0s, opacity .5s ease .7s, padding .5s ease;

    @media (max-width: $breakpoint-sm) {
        padding: 2.5em 0;
    }

    &--hide {
      opacity: 0;
      transition: opacity .5s ease 0s;
    }

    &--scrolled {
        background-color: rgba(0,0,0,.8);
        position: fixed;
    }

    &--static{
        background-color: transparent!important; 
        position: fixed;
    }

    &--menuOpen {
        background-color: rgba(51,51,51,0);
    }

    &--hidden {
        background-color: rgba(51,51,51,0);
    }

    &__inner {
        position: relative;

        @media (min-width: $breakpoint-sm) {
            display: flex;
        }
    }

    &__iso {
        &Svg{
            width: 1.1em;
            height: auto;
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
            transform: scale(1);
            transform-origin: top right;
            transition: transform 700ms ease 0ms;

            svg{
                width: 100%;
                height: auto;
                display: block;

                &.normal{
                    display: block;
                }

                &.sticky{
                    display: none;
                }
            }

            &--gradient {
                opacity: 0;
            }
        }
    }

    &__number{
        &Svg{
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            z-index: 0;
            opacity: 1;
            transition: transform 700ms ease, opacity 500ms ease;

            svg{
                width: 100%;
                height: auto;
                display: block;
            }

            &--4{
                position: relative;
                transition-delay: 200ms;
            }

            &--5{
                transition-delay: 100ms;
            }

            &--1{
                transition-delay: 0ms;
            }
        }
    }

    &__logo {
        display: inline-block;
        flex: 0 0 auto;
        transition: opacity 1s ease;

        &Svg{
            width: 8.9em;
            height: auto;
            display: block;
            position: relative;

            svg{
                width: 100%;
                height: auto;
                display: block;
                opacity: 1;
                transition: opacity 500ms ease;

                path{
                    transition: fill 1s ease;

                    &.static-path {
                        fill: #FF4E00;
                    }
                }
            }
        }

        &Text {
            font-weight: 300;
            font-size: 17px;
            padding: 0 0 0 15px;
            letter-spacing: -0.015em;
            line-height: 98%;
            display: inline-block;
            vertical-align: middle;
            transition: color 1s ease;

            @media (max-width: $breakpoint-sm) {
                font-size: 16px;
            }

            span {
                font-weight: 500;
            }
        }

        &.noHandler {
            pointer-events: none;
        }
    }

    &--menuOpen {
        @media (max-width: $breakpoint-sm) {  
            .header__logo{
                svg{
                    .change-path {
                        fill: #fff!important;
                        transition-duration: 0s!important;
                    }
                    .static-path {
                        fill: #FF4E00!important;
                        transition-duration: 0s!important;
                    }
                }

                &Text{
                    color: #fff!important;
                    transition-duration: 0s!important;
                }
            }

            .header__toggleMenu:after,
            .header__toggleMenu:before {
                background-color: #fff!important;
                transition: transform 0.3s ease 0s, top 0.3s ease 0s, background-color 0s ease 0s;
            }
            
            &.header--light,   
            &.header--dark,
            &.header--darkLeft{
                .header__logo{
                    svg{
                        .change-path {
                            fill: #fff!important;
                            transition-duration: 0s!important;
                        }
                        .static-path {
                            fill: #FF4E00!important;
                            transition-duration: 0s!important;
                        }
                    }

                    &Text{
                        color: #fff!important;
                        transition-duration: 0s!important;
                    }
                }

                .header__toggleMenu:after,
                .header__toggleMenu:before {
                    background-color: #fff!important;
                    transition: transform 0.3s ease 0s, top 0.3s ease 0s, background-color 0s ease 0s;
                }
            }
        }
    }

    &--dark,
    &--darkLeft {
        .header__logo{
            svg{
                .change-path {
                    fill: $color-black-main;
                }
                .static-path {
                    fill: #FF4E00;
                }
            }
        }
    }

    &--static {
        &.header--dark,
        &.header--darkLeft {
            .header__logo{
                svg{
                    .change-path {
                        fill: $color-black-main;
                    }
                    .static-path {
                        fill: #FF4E00;
                    }
                }
            }
        }
    }

    &--noChangeColor {
        // &.header--dark,
        // &.header--darkLeft {
        //     .header__logo{
        //         svg{
        //             .change-path {
        //                 fill: #fff;
        //             }
        //             .static-path {
        //                 fill: #FF4E00;
        //             }
        //         }
        //     }
        // }

        &.header--light {
            .header__logo{
                svg {
                    .change-path {
                        fill: $color-black-main;
                    }
                    .static-path {
                        fill: #FF4E00;
                    }
                }
            }
        }
    }

    &--hidden {
        .header__logo{
            opacity: 0;
        }
    }

    &__toggleMenu {
        width: 3em;
        height: 3em;
        background-color: transparent;
        border: none;
        outline: none;
        position: absolute;
        right: 1.5em;
        top: 0;
        z-index: 200;
        transition: opacity 0.5s ease 0s;
        @media (min-width: $breakpoint-sm) {
            display: none;
        }
    }

    &--hidden .header__toggleMenu {
        opacity: 0;
    }

    &__toggleMenu:after,
    &__toggleMenu:before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: center;
        transition: transform 0.3s ease 0s, top 0.3s ease 0s, background-color 0.3s ease 0s;
        background-color: #fff;

        @media (max-width: $breakpoint-sm) {
            background-color: #fff;
        }
    }

    &__toggleMenu:before {
        top: 8px;
    }

    &__toggleMenu:after {
        top: 20px
    }

    &--menuOpen .header__toggleMenu:before {
        top: 50%;
        transform: rotate(45deg);
        background-color: #fff;
    }

    &--menuOpen .header__toggleMenu:after {
        top: 50%;
        transform: rotate(-45deg);
        background-color: #fff;
    }

    &--dark, 
    &--darkLeft {
        .header__logoText {
            color: #231f20;
        }

        &.header--scrolled {
            .header__logoText {
            color: #fff;
            }
        }
    }

    &--dark {
        .header__toggleMenu:after,
        .header__toggleMenu:before{
            background-color: $color-black-main;
        }
    }

    &--darkLeft {
        .header__toggleMenu:after,
        .header__toggleMenu:before{
            @media (max-width: $breakpoint-sm) {
                background-color: $color-black-main;
            }
        }
    }

    &--scrolled {
        .header__iso{
            &Svg {  
                transform: scale(3); 
                transition-delay: 170ms;
                animation: opacityToHide 1s both;
                animation-delay: 1s;

                svg{
                    &.normal{
                        display: none;
                    }
                    
                    &.sticky{
                        display: block;
                    }
                }
                
                &--gradient {
                    animation: opacity 1s both;
                    animation-delay: 1s;
                }
            }
        }

        .header__number{
            &Svg{
                opacity: 0;
                transform: translateX(-3em);

                &--4{
                    transition-delay: 0ms;
                }
    
                &--5{
                    transition-delay: 100ms;
                }
    
                &--1{
                    transition-delay: 200ms;
                }
            }
        }

        .header__logo{
            svg{
                .change-path {
                    fill: #fff;
                }
                .static-path {
                    fill: #FF4E00;
                }
            }
        }

        &.header--static {
            &.header--dark,
            &.header--darkLeft {
                .header__logo{
                    svg{
                        .change-path {
                            fill: $color-black-main;
                        }
                        .static-path {
                            fill: #FF4E00;
                        }
                    }
                }
            }

            &.header--noChangeColor {
                &.header--dark,
                &.header--darkLeft {
                    .header__logo{
                        svg{
                            .change-path {
                                fill: #fff;
                            }
                            .static-path {
                                fill: #FF4E00;
                            }
                        }
                    }
                }
            }
        }

        .header__toggleMenu:after,
        .header__toggleMenu:before {
            background-color: #fff;
        }

        &.header--static {
            &.header--dark {
                .header__toggleMenu:after,
                .header__toggleMenu:before {
                    background-color: $color-black-main;
                }
            }

            &.header--noChangeColor {
                &.header--dark {
                    .header__toggleMenu:after,
                    .header__toggleMenu:before {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    &--static {
        .header__iso{
            &Svg {  
                transform: scale(1);  
                animation: none;
                
                &--gradient {
                    animation: none;
                }
            }
        }

        .header__number{
            &Svg{
                opacity: 1;
                transform: translateX(0);
            }
        }

        .header__toggleMenu:after,
        .header__toggleMenu:before {
            background-color: #fff; 
        }

        &.header--dark {
            .header__toggleMenu:after,
            .header__toggleMenu:before {
                background-color: $color-black-main;
            }
        }
    }

    &--noChangeColor  {
        .header__toggleMenu:after,
        .header__toggleMenu:before {
            background-color: $color-black-main;
        }

        &.header--dark {
            .header__toggleMenu:after,
            .header__toggleMenu:before {
                background-color: $color-black-main;
            }
        }
    }
}

