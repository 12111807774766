.footer {
  //background: #272727;
  background: rgb(62,62,62);
  background: linear-gradient(180deg, rgba(62,62,62,1) 0%, rgba(0,0,0,1) 60%);
  color: rgba(255, 255, 255, 0.8);
  padding: 8em 0 2.5em;
  overflow: hidden;
  position: relative;

  @media (max-width: $breakpoint-sm) {
    padding: 7em 0 4em;
  }
  
  @media (max-width: $breakpoint-s) {
      padding-bottom: 3em;
      padding-top: 6em;
  }

  &--hidden {
      display: none;
  }

  &__logo {
    float: right;

    @media(max-width: $breakpoint-sm) {
      float: none;
    }
  }

  &__sub {
    padding-top: 3em;

    @media (max-width: $breakpoint-xs) {
      .row{
        display: flex;
        flex-direction: column;

        & > div:first-child{
          order: 2;
        }

        & > div:last-child{
          order: 1;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          padding-bottom: 0.5em;

          @media (max-width: $breakpoint-xxs) {
            flex-direction: column;
          }
        }
      }
    }
  }

  &__copy {
    font-size: 1.1em;
    color: rgba(255,255,255,1);

    @media (max-width: $breakpoint-xs) {
      font-size: 1.4em;
    }
  }

  &__legal{
    width: 100%;

    &Circle{
      display: block;
      width: 10em;
      height: 10em;

      svg{
        width: 100%;
        height: auto;
        display: block;
      }

      @media (max-width: $breakpoint-xs) {
        margin: 0 auto;
      }
    }

    &Text{
      width: 100%;
      padding-top: 5.2em;

      @media (max-width: $breakpoint-xs) {
        text-align: center;
        padding-top: 3em;
      }
    }
  }

  &__isotype {
      background-image: url('https://assets-website.451.com/assets/img/logo-footer.png');
      background-size: 100%;
      display: inline-block;
      width: 2.2em;
      height: 2.2em;
  }

  &__office {
    font-size: 1.6em;
    margin-bottom: 2em;
    margin-top: 0;
    color: rgba(255,255,255,.6);
    // @media (max-width: $breakpoint-sm) {
    //   display: none;
    // }

    @media (max-width: $breakpoint-xs) {
        text-align: center;
    }
    a {
      &:hover {
        color: #ffffff;
      }
    }
    strong {
      color: #fff;
      font-weight: 400;
      margin: 0 0 .5em;
      display: inline-block;
    }
  }

  &__formInfo{
    width: 100%;
    max-width: 37em;

    @media (max-width: $breakpoint-sm) {
      max-width: 100%;
      padding-bottom: 3em;
    }

    .title{
      font-size: 5.5em;
      letter-spacing: -0.03em;
      font-weight: 100;
      margin: 0 0 0.7em;
      line-height: 100%;
      color: #fff;

      strong{
        color: inherit;
        font-weight: 400;
      }

      @media (max-width: $breakpoint-sm) {
        font-size: 4em;
        margin: 0 0 0.5em;
      }
    }

    .description{
      font-size: 2em;
      line-height: 130%;
      letter-spacing: 0.01em;
      font-weight: 100;
      margin: 0;
      color: #fff;

      strong{
        color: inherit;
        font-weight: 300;
      }

      @media (max-width: $breakpoint-sm) {
        br{
          display: none;
        }
      }
    }
  }

  &__formModule{
    width: 100%;

    .form{
      width: 100%;
      overflow: visible;

      &__fields{
        width: 100%;
        position: relative;
        opacity: 1;
        visibility: visible;
        transition: all 0.5s ease;

        &.sent{
          opacity: 0;
          visibility: hidden;
          transform: translateX(100%);
        }
      }

      label{
        &.error {
          color: red;
          margin-top: 3px;
          margin-bottom: 0;
          display: inline-block;
          font-size: 13px;
          font-weight: 500;
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 100%;
          text-align: right;
          padding-right: 0.4em;

          @media (max-width: $breakpoint-sm) {
            bottom: -25px;
            font-size: 15px;
          }
        }
      }

      &__info {
        top: 0;
        left: 0;
        width: 100%;

        &--error{
          padding-top: 1em;
        }

        .btnLink{
          color: #fff;
        }
      }

      &__icon {
        svg {
          fill: rgba(255, 255, 255, 0.3);
        }

        @media (max-width: $breakpoint-sm) {
          margin: 0 auto;
        }
      }

      &__message{
        p{
          font-size: 2em;
          line-height: 130%;
          letter-spacing: -0.015em;
          font-weight: 100;
          margin: 0;
          color: #fff;

          strong{
            font-size: 1.5em;
            font-weight: 300;
          }

          @media (max-width: $breakpoint-sm) {
            text-align: center;
          }
        }
      }

      &__inputRow{
        margin-bottom: 2.2em;

        &:nth-last-child(2){
          margin-bottom: 1em;
        }

        @media (max-width: $breakpoint-sm) {
          margin-bottom: 3em;

          &:nth-last-child(2){
            margin-bottom: 1em;
          }
        }
      }

      &__inputWrapper{
        width: 100%;
        position: relative;

        .form__select, 
        .form__textInput, 
        .form__textarea {
          width: 100%;
          height: 2.8em;
          border:none;
          font-size: 1.6em;
          padding: 0;
          font-weight: 300;
          letter-spacing: 0;
          color: #fff;
          border-radius: 2em;
          padding: 0 1.2em;
          background: rgba(255, 255, 255, 0.15) !important;
          outline: 2px solid transparent;
          transition: outline 0.3s ease;
          outline-offset: -2px;
          letter-spacing: 0.03em;

          &.error {
            outline-color: red!important;

            &:focus{
              outline-color: red!important;
            }
          }

          &:focus{
            outline-color: #8f8f8f!important;
          }

          &::-webkit-input-placeholder {
            color: #fff;
            opacity: 0.6;
          }

          &::-moz-placeholder {
            color: #fff;
            opacity: 0.6;
          }

          &:-ms-input-placeholder {
            color: #fff;
            opacity: 0.6;
          }

          &:-moz-placeholder {
            color: #fff;
            opacity: 0.6;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &[type=number] {
            -moz-appearance: textfield;
          }

          @media (max-width: $breakpoint-sm) {
            font-size: 1.8em;
          }
        }

        .form__textarea {
          border-radius: 1.4em;
          min-height: 6em;
          padding-top: 0.8em;
          resize: none;
          line-height: 120%;
        }
      }

      &__inputRow{
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        flex-wrap: wrap;

        &--2cols{
          justify-content: space-between;

          @media (max-width: $breakpoint-s) {
            flex-direction: column;
          }

          .form__inputWrapper{
            width: calc(50% - 0.9em);

            @media (max-width: $breakpoint-s) {
              width: 100%;
              margin-bottom: 3em;

              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &__submit {
        margin: 0 0 0 auto;
        outline: none;
        border: none;
        background-color: transparent;
        color: #fff;
        width: auto;
        text-align: left;
        text-transform: uppercase;
        font-size: 1.2em;
        line-height: 100%;
        padding: 0;
        font-weight: 700;
        position: relative;
        letter-spacing: 0.2em;
        transition: none;
        min-height: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &.btnLink--arrowed {
          .arrow-icon {
            top: 0;
          }
        }

        &:hover{
          background-color: transparent;
          color: #fff;
        }
      }
    }
  }

  &__social{
    width: 50%;
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1em 0 0 1em;

    @media (max-width: $breakpoint-xs) {
      width: 25%;
      justify-content: center;
      padding-top: 0;
    }

    @media (max-width: $breakpoint-xxs) {
      width: 100%;
      padding: 1.5em 0 2em 0;
    }

    a{
      display: inline-block;
      margin-right: 1.5em;

      &:last-child{
        margin-right: 0;

        .footer__socialIcon{
          width: 2.3em;
          height: 2.3em;
        }
      }

      &:hover{
        .footer__socialIcon{
          transform: scale(1.15);

          svg{
            path{
              opacity: 1;
            }
          }

          @media (max-width: $breakpoint-sm) {
            transform: none;
  
            svg{
              path{
                opacity: 0.48;
              }
            }
          }
        }
      }
    }

    &Icon{
      width: 2.5em;
      height: 2.5em;
      display: block;
      transition: transform 0.4s ease;

      svg{
        width: 100%;
        height: auto;
        display: block;

        path{
          transition: opacity 0.4s ease;
        }
      }
    }
  }

  &__nav{
    width: 100%;
    
    ul{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      
      li{
        width: 50%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        padding-bottom: 2em;

        @media (max-width: $breakpoint-xxs) {
          text-align: center;
        }
  
        a{
          font-size: 1.6em;
          line-height: 120%;
          letter-spacing: 0.015em;
          font-weight: 300;
          margin: 0;
          color: #fff;
          display: inline-block;
          position: relative;
          padding: 0.2em 0.6em 0.12em;
          transition: color 600ms ease;

          span {
            position: relative;
            z-index: 1;
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 0;
            height: 100%;
            border-radius: 2em;
            background-color: #fff;
            opacity: 0;
            transition: opacity 300ms ease, width 300ms ease;
            z-index: 0;
            transform: translateX(-50%);
          }
    
          &:hover{
            color: $color-black-main;

            &:after{
              opacity: 1;
              width: 100%;
            }

            @media (max-width: $breakpoint-sm) {
              color: #fff;
  
              &:after{
                display: none;
              }
            }
          }
        }
      }
    }

    @media (max-width: $breakpoint-xs) {
      width: 75%;
      margin-bottom: 1.5rem;
    }

    @media (max-width: $breakpoint-xxs) {
      width: 100%;
    }
  }
}
