
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot {
  0% {
    transform: scale(.1);
    opacity: .1
  }
  25% {
    transform: scale(.5);
    opacity: .5
  }
  50% {
    transform: scale(1);
    opacity: 1
  }
  75% {
    transform: scale(.5);
    opacity: .5
  }
  100% {
    transform: scale(.1);
    opacity: .1
  }
}

@keyframes zoom {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes line-1 {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}

@keyframes line-2 {
  0% {
    -webkit-transform: rotate(-120deg) scaleY(0);
            transform: rotate(-120deg) scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-120deg) scaleY(1);
            transform: rotate(-120deg) scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}

@keyframes line-3 {
  0% {
    -webkit-transform: rotate(120deg) scaleY(0);
            transform: rotate(120deg) scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(120deg) scaleY(1);
            transform: rotate(120deg) scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}


@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes expandWidth {
   0% { width: 0; }
   100% { width: 100%; }
}


@keyframes init-circle-anm {
  0% {
    transform: scale(1.05);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes circleBreatheInit {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
}

@keyframes circleBreathe01 {
  0%, 100% {
    transform: translate(-46%,-54%) scale(1);
  }
  50% {
    transform: translate(-50%,-54%) scale(0.95);
  }
}

@keyframes circleBreathe02 {
  0%, 100% {
    transform: translate(-54%,-50%) scale(1);
  }
  50% {
    transform: translate(-54%,-54%) scale(0.95);
  }
}

@keyframes circleBreathe03 {
  0%, 100% {
    transform: translate(-46%,-50%) scale(1);
  }
  50% {
    transform: translate(-50%,-50%) scale(0.95);
  }
}

@keyframes circleBreathe04 {
  0%, 100% {
    transform: translate(-54%,-54%) scale(1); 
  }
  50% {
    transform: translate(-54%,-50%) scale(0.95);
  }
}

@keyframes circleReposition01 {
  0% {
    transform: translate(-50%,-50%);
  }
  100% {
    transform: translate(-46%,-54%);
  }
}

@keyframes circleReposition02 {
  0% {
    transform: translate(-50%,-50%);
  }
  100% {
    transform: translate(-54%,-50%);
  }
}

@keyframes circleReposition03 {
  0% {
    transform: translate(-50%,-50%);
  }
  100% {
    transform: translate(-46%,-50%);
  }
}

@keyframes circleReposition04 {
  0% {
    transform: translate(-50%,-50%);
  }
  100% {
    transform: translate(-54%,-54%);
  }
}

@keyframes line-revealer {
	0% {
		transform: scaleX(0);
		transform-origin: 0 50%;
	}
	100% {
		transform: scaleX(1);
		transform-origin: 0 50%;
	}
}

@keyframes line-hide {
	0% {
		transform: scaleX(1);
		transform-origin: 100% 50%;
	}
	100% {
		transform: scaleX(0);
		transform-origin: 100% 50%;
	}
}


@keyframes circleAbout01 {
  0%, 100% {
    transform: translate(0,0) scale(1);
  }
  50% {
    transform: translate(0,4%) scale(0.95);
  }
}

@keyframes circleAbout02 {
  0%, 100% {
    transform: translate(0,0) scale(1);
  }
  50% {
    transform: translate(4%,-4%) scale(0.95);
  }
}

@keyframes circleAbout03 {
  0%, 100% {
    transform: translate(0,0) scale(1);
  }
  50% {
    transform: translate(-4%,-4%) scale(0.95);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacityToHide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


