$splash_padding: 7em;

.mainSlider {
    height: calc(var(--vh, 1vh) * 100);
    background-color: #111;
    position: relative;
    z-index: 0;

    .mainSlider__slide {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        min-height: calc(var(--vh, 1vh) * 100);
        outline: none !important;
        position: relative;
        overflow: hidden;

        &::after {
            content: ' ';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 70%);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }

        &__video {
            &::after {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0.6) 100%);
            }
        }
    }

    .mainSlider__image {
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .mainSlider__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        overflow: hidden;
        
        video {
            width: 100vw;
            height: 56.25vw;
            min-height: 100vh;
            min-width: 177.77vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &.video-desktop {
                display: block;

                @media(max-width: $breakpoint-sm) {
                    display: none;
                }
            }

            &.video-mobile {
                display: none;

                @media(max-width: $breakpoint-sm) {
                    display: block;
                }
            }
        }
    }

    .mainSlider__img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .mainSlider__text {
        height: calc(var(--vh, 1vh) * 100);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        position: relative;
        z-index: 9;
        padding: $splash_padding 0;
        display: none;

        @media(max-width: $breakpoint-sm) {
            padding: 2.8em 0;
        }

        .title {
            font-size: 4.8em;
            margin: 0 0 .1em;
            line-height: 1.2em;
            letter-spacing: -.04em;
            font-weight: 100;
            color: $color-primary;

            @media(max-width: $breakpoint-sm) {
                font-size: 3.8em;
                margin-bottom: 0.2em;
            }

            strong {
                font-weight: 300;
                letter-spacing: -.04em;
            }
        }

        .description {
            font-size: 2.6em;
            line-height: 1.2em;
            font-weight: 100;
            color: rgba(255,255,255,.4);
            letter-spacing: -.01em;
            margin: 0 0 1.25em;

            @media(max-width: $breakpoint-sm) {
                font-size: 2.2em;
                margin: 0 0 1em;
            }
        }

        &--top {
            .description {
                height: calc(var(--vh, 1vh) * 60);
            }
        }
    }

    .mainSlider__dots {
        position: absolute;
        bottom: $splash_padding;
        left: 0;
        width: 100%;
        pointer-events: none;
        text-align: right;
        z-index: 1;
        display: none;

        @media(max-width: $breakpoint-sm) {
            bottom: 3em;
        }

        .slide__loading {
            position: relative;
            font-size: 0;
            line-height: 0;
            display: block;
            width: 70px;
            height: 1px;
            padding: 0;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: #686867;

            &::after {
                position: absolute;
                left: 0;
                top: -1px;
                content: "";
                font-size: 0;
                line-height: 0;
                display: block;
                width: 70px;
                height: 3px;
                padding: 0;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: $color-primary;
                animation: expandWidth 6s linear;
            }
        }

        ul.slick-dots {
            padding: 0;
            margin: 0;
            list-style: none;
            height: 30px;

            li {
                list-style: none;
                display: inline-block;
                vertical-align: middle;
                pointer-events: all;
                padding: 0;

                button {
                    font-size: 0;
                    line-height: 0;
                    display: block;
                    width: 40px;
                    height: 30px;
                    border: 0;
                    padding: 0;
                    cursor: pointer;
                    background: transparent;
                    color: transparent;
                    outline: none;
                    transition: background-color .2s ease 0s;
                    position: relative;

                    &:after {
                        content: " ";
                        position: absolute;
                        background: rgba(255,255,255,.3);
                        height: 2px;
                        width: 100%;
                        bottom: 14px;
                        left: 0;
                        right: 0;
                        transition: all .2s;
                    }

                    &:hover {
                        &:after {
                            background: rgba(255,255,255,.6);
                        }
                    }
                }

                &.slick-active {
                    button {
                        &:after {
                            background: $color-primary;
                            height: 4px;
                            bottom: 13px;
                        }
                    }
                }

                &:last-child {
                    padding-right: 0;
                }

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
}

.mainSliderIntervalContent{
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;
    z-index: 0;
}

.mainSliderInterval {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: #111;
    position: relative;
    z-index: 0;
    display: none;

    &.active{
        display: block;
    }

    .mainSlider__slide {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        pointer-events: none;
        transition: opacity 1000ms linear 0s;

        &.active {
            opacity: 1;
            pointer-events: all;
        }

        // &::after {
        //     content: ' ';
        //     background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 70%);
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     height: 100%;
        // }
    }

    .mainSlider__image {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;

        &Mob{
            display: none;
            position: relative;
            overflow: hidden;
            height: 100%;
            width: 100%;

            @media (max-width: $breakpoint-md) {
                display: block;
            }
        }

        &--changeMob{
            @media (max-width: $breakpoint-md) {
                display: none;
            }
        } 
    }

    .mainSlider__video {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;

        iframe,
        video {
            width: 100vw;
            height: 56.25vw;
            min-height: 100vh;
            min-width: 177.77vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;

            &.video-desktop {
                display: block;

                @media(max-width: $breakpoint-sm) {
                    display: none;
                }
            }

            &.video-mobile {
                display: none;

                @media(max-width: $breakpoint-sm) {
                    display: block;
                }
            }

            &.iframe-video-mob{
                display: none;

                // @media(max-width: 991px) {
                //     opacity: 1;
                //     visibility: visible;
                // }
            }

            // &.iframe-video-desk{
            //     opacity: 1;
            //     visibility: visible;

            //     @media(max-width: 991px) {
            //         opacity: 0;
            //         visibility: hidden;
            //     }
            // }
        }
    }

    .mainSlider__img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .mainSlider__info{
        position: absolute;
        width: 100%;
        bottom: 8em;
        left: 0;

        .container{
            position: relative;
        }

        &Mod{
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 70em;
            border-radius: 3em;
            overflow: hidden;

            &Blur{
                -webkit-backdrop-filter: blur(15px) brightness(40%) contrast(0.9);
                backdrop-filter: blur(15px) brightness(40%) contrast(0.9);
                width: 100%;
                padding: 0 2em 1.2em 2.7em;
            } 
            
            &.active{
                .btnLink{
                    .arrow-icon{
                        &--lineV{
                            opacity: 0;
                        }
                    }
                }

                .mainSlider__infoBlock{        
                    &:after{
                        opacity: 1;
                    }
                }
            }
        }

        &Block{
            padding: 1.3em 0 0 0;
            display: flex;
            width: 100%;
            align-items: center;
            position: relative;

            &:after{
                content: "";
                top: 0;
                left: 0;
                height: 1px;
                width: 100%;
                background-color: rgba(112, 112, 112, 0.3);
                position: absolute;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }

        &Hide{
            overflow: hidden;
            display: none;
            
            &Cont{
                display: flex;
                padding: 2em 0 0;
                margin-bottom: 1.8em;
                position: relative;
                align-items: center;
                justify-content: space-between;

                @media (max-width: $breakpoint-s) {
                    flex-direction: column;
                }

                p{
                    padding: 0 3.5em 0 0;
                    margin: 0;
                    flex: 1 1 auto;
                    width: 100%;
                    min-width: 0.1em;
                    font-size: 1.4em;
                    letter-spacing: 0.03em;
                    font-weight: 300;
                    line-height: 130%;
                    color: rgba(255, 255, 255, 0.7);

                    @media (max-width: $breakpoint-s) {
                        padding: 0 0 1.3em;
                    }
                }

                a{
                    white-space: nowrap;
                    display: block;
                    flex: 0 0 auto;
                    border: 2px solid #fff;
                    font-size: 1.5em;
                    font-weight: 400;
                    margin: 0;
                    line-height: 120%;
                    color: #fff;
                    border-radius: 2em;
                    padding: 0.5em 1.5em 0.4em;
                    transition: color 500ms ease, background-color 500ms ease;
                    margin: 0 auto;

                    &:hover{
                        background-color: #fff;
                        color: $color-black-main;
                    }
                }
            }
        }    

        &Text{
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            width: 100%;
            min-width: 0.1em;

            @media (max-width: $breakpoint-s) {
                flex-direction: column;
                align-items: flex-start;
            }

            h3{
                font-size: 1.7em;
                font-weight: 400;
                padding: 0;
                margin: 0;
                line-height: 120%;
                color: #fff;
            }

            p{
                font-size: 1.7em;
                font-weight: 300;
                padding: 0 0 0 0.6em;
                margin: 0 0 0 0.6em;
                line-height: 120%;
                color: #fff;
                position: relative;

                &:after{
                    content: "";
                    width: 1px;
                    height: 105%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    border-left: 1px solid #fff;
                }

                @media (max-width: $breakpoint-s) {
                    padding: 0;
                    margin: 0;

                    &:after{
                        display: none;
                    }
                }
            }
        }

        &Link{
            flex: 0 0 auto;
            display: block;
        }

        .btnLink {
            width: 33px;
            height: 32px;
            padding: 0;
            margin: 0;
            background-color: transparent;
            border: none;

            &--arrowed{
                display: block;
            }

            .arrow-icon {
                position: relative;
                top: auto;
                transition: transform 0.3s ease;
                width: 100%;
                height: auto;
                display: block;

                &--lineV{
                    opacity: 1;
                    transition: opacity 0.3s ease;
                }
            }

            &:hover {
                .arrow-icon {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.mainSliderIntervalDots{
    position: absolute;
    bottom: 4em;
    left: 0;
    width: 100%;

    &__items{
        display: block;

        ul{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;

            li{
                margin-right: 10px;
                list-style-type: none;
        
                button{
                    background-color: rgba(255, 255, 255, 0.32);
                    width: 9px;
                    height: 9px;
                    display: block;
                    border-radius: 100%;
                    border: none;
                    padding: 0;
                    margin: 0;
                    transition: background-color 0.5s ease;
                }

                &.active{
                    button{
                        background-color: #fff;
                        pointer-events: none; 
                    }
                }
            }
        }
    }

    &--dark{
        ul{
            li{        
                button{
                    background-color: rgba(0, 0, 0, 0.32)!important;
                }

                &.active{
                    button{
                        background-color: $color-black-main!important;
                    }
                }
            }
        }
    }
}