.timeline {
  .btnLink--back {
    z-index: 10;
    .back {
      opacity: 1;
    }
  }
  &__holder {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    &::after,
    &::before {
      content: " ";
      position: absolute;
      bottom:0;
      top: 0;
      width: 5vw;
      height: 100%;
      z-index: 9;
    }
    &::before {
      left: 0;
      background: linear-gradient(90deg, #d81c41, transparent);
    }
    &::after {
      right: 0;
      background: linear-gradient(-90deg, #d81c41, transparent);
    }
  }
  &__line {
    position: sticky;
    left: 0;
    right: 0;
    top: 50%;
    height: 6px;
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    margin-top: -3px;
  }
  &__items {
    white-space: nowrap;
    text-align: center;
    position: absolute;
    height: 100%;
    display: flex;
    padding: 0 10vw;
    box-sizing: content-box;
  }
  &__item {
    display: inline-block;
    height: calc(50% + 5px);
    position: relative;
    div {
      width: 170px;
      height: 100%;
      display: flex;
      padding: 10px 0;
      margin: 0 1em;
      justify-content: center;
      position: relative;
      &::after {
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: $color-primary;
        border: 2px solid rgba(0, 0, 0, .4);
        position: absolute;
        left: 70px;
        margin-left: -5px;
        transform: scale(1);
        transition: all .3s ease;
      }
      &::before {
        content: ' ';
        position: absolute;
        left: 70px;
        height: 0;
        width: 1px;
        background: #000;
        transition: height .3s ease;
      }
      img {
        width: 60px;
        opacity: 0.3;
        transition: opacity .3s linear;
      }
      strong {
        margin-left: 20px;
        color: #ffffff;
        width: 90px;
        position: relative;
        display: inline-block;
        white-space: normal;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-size: 11px;
        line-height: 120%;
        opacity: 0;
        transition: opacity .3s linear 0s;
      }
      span {
        position: absolute;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        text-align: center;
        width: 140px;
        left: 0;
        color: #fff;
        opacity: .3;
        transition: opacity .3s linear;
      }
    }
    &--top {
      top: 0;
      div {
        align-items: flex-start;
        &::before {
          bottom: 0;
        }
        &::after {
          bottom: 0;
        }
        span {
          bottom: -50px;
        }
      }
    }
    &--bottom {
      top: calc(50% + -5px);
      div {
        align-items: flex-end;
        &::before {
          top: 0;
        }
        &::after {
          top: 0;
        }
        span {
          top: -50px;
        }
      }
    }

    &:hover {
      div {
        strong {
          opacity: 1;
          transition: opacity .3s linear .2s;
        }
        img {
          opacity: 1;
        }
        span {
          opacity: 1;
        }
        &::before {
          height: calc(100% - 10px);
        }
        &::after {
          transform: scale(1.5);
          background: #ffffff;
          border-color: #000000;
        }
      }
    }
  }

}

.touchevents {
  .timeline {
    &__holder {
      overflow-x: scroll;
      overflow-y: hidden;
      &::before,
      &::after {
        display: none;
      }
    }
    &__items {
      margin: 0 !important;
    }
    &__item {
      div {
        strong {
          opacity: 1;
          transition: opacity .3s linear .2s;
        }
        img {
          opacity: 1;
        }
        span {
          opacity: 1;
        }
        &::before {
          height: calc(100% - 10px);
        }
        &::after {
          transform: scale(1.5);
          background: #ffffff;
          border-color: #000000;
        }
      }
    }
  }
}
