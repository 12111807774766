.policiesListing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  
  &__text {
    height: 100%;
    min-height: 0.1em;
    flex: 1 1 auto;
  }

  &__tag {
    width: 100%;
    color: $color-black-highlight;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: 1.1em;
    margin: 0 0 1em;
  }

  &__title {
    width: 100%;
    font-weight: 100;
    font-size: 2.4em;
    min-height: 1.2em;
    line-height: 1.25em;
    letter-spacing: 0.03em;
    transition: color 0.2s ease;
    margin: 0 0 1.2em;

    @media (max-width: $breakpoint-md) {
      min-height: 2.4em;
    }

    @media (max-width: $breakpoint-sm) {
      min-height: auto;
      margin: 0 0 0.8em;
    }
  }

  &__date {
    width: 100%;
    color: #a5a5a5;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: 1.1em;
    margin: 0 0 1em;
  }

  &__description {
    width: 100%;
    font-weight: 300;
    font-size: 1.55em;
    line-height: 1.45em;
    color: rgba(0,0,0,.5);
    margin: 0 0 1.2em;

    @media (max-width: $breakpoint-sm) {
      margin: 0 0 0.5em;
    }
  }

  &__link {
    width: 100%;
    flex: 0 0 auto;

    &Arrow {
      display: inline-block;
      font-size: 1.1em;
      text-transform: uppercase;
      font-weight: 700;
      position: relative;
      padding: 0.5em 0.5em 0 0;
    }
  }

  .md-br{
    display: none;

    @media (max-width: $breakpoint-md) {
      display: inline;
    }

    @media (max-width: $breakpoint-sm) {
      display: none;
    }
  }
}

.policies-flex {
  padding-top: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;  

  @media (max-width: $breakpoint-sm) {
    padding-top: 0;
  }
}

.policies-item{

  &:before{
    display: none;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 1px;
    background-color: #f4f4f4;

    @media (max-width: $breakpoint-sm) {
      display: block;
    }
  }

  &:last-child{
    &:before{
      display: none;
    }
  }

  @media (max-width: $breakpoint-sm) {
    margin-bottom: 4em;
    padding-bottom: 3em;
  }

  @media(max-width: $breakpoint-s) {
    margin-bottom: 3.5em;
    padding-bottom: 2.5em;
  }
}

.title.title--policies {
  width: 100%;
  margin: 0 0 0.5em;

  @media (max-width: $breakpoint-sm) {
    margin: 0 0 0.2em;
  }
}

.description.description--policies {
  font-size: 2.2em;
  font-weight: 100;
  line-height: 1.4em;
  letter-spacing: -0.01em;
  color: $color-black-main;
  padding: 0;
  margin: 0 0 2.5em;
  max-width: 40em;
  width: 100%;

  @media(max-width: $breakpoint-s) {
    font-size: 1.8em;
    line-height: 1.3em;
  }
}
