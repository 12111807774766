.header{
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    //background: $gradient-orange;
    //z-index: 100;
    z-index: -1;
    display: flex;
    align-items: center;
    transform: translateY(-120%);
    transition: transform 0.5s cubic-bezier(0.01, 0.38, 0.07, 1.01) 0s;
    box-shadow: none;
    justify-content: center;
    flex: 1 1 auto;
    min-width: 0.1em;
    background: #3e3e3e;
    background: linear-gradient(180deg, #3e3e3e 0%, black 60%);

    @media (min-width: $breakpoint-sm) {
      position: static;
      background: transparent;
      transform: none;
      height: auto;
      display: flex;
      justify-content: flex-end;
      z-index: 100;
    }

    &__background{
      background-image: url('https://assets-website.451.com/assets/img/home/back-menu.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: left center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 1;
    }

    &--active {
      transform: translateY(0);
      transition-delay: 0;
    }

    &__inner {
      display: flex;
      position: relative;
      z-index: 2;

      @media (min-width: $breakpoint-sm) {
        justify-content: flex-end;
        padding-top: 0;
        align-items: center;
        width: 100%;
        margin-right: -15px;
      }
    }

    &__logoImg {
      width: 16.5em;
      height: auto;
    }

    &__links {
      @media (max-width: $breakpoint-xs) {
        position: absolute;
        bottom: 1em;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }

    &__menu {
      text-align: center;
      width: 100%;

      @media (min-width: $breakpoint-sm) {
        width: auto;
      }

      &--dark {
        .nav__menu__link {
          @media (min-width: $breakpoint-sm) {
            color: $color-black-main!important;

            &:after {
              background-color: $color-black-main!important;
            }
          }
        }

        .nav__menu__link--active,
        .nav__menu__link:hover {
          @media (min-width: $breakpoint-sm) {
            color: #fff!important;
          }
        }
      }

      &__links {
        list-style: none;
        padding: 0;
        margin: 0;

        @media (min-width: $breakpoint-sm) {
          display: flex;
        }
      }

      &__item:not(:last-child) {
        margin-bottom: 2.5em;
        margin-bottom: 4em;

        @media (min-width: $breakpoint-sm) {
          margin-right: 0.5em;
          margin-bottom: 0;
        }
      }

      &__item {
        &:last-child {
          @media (min-width: $breakpoint-sm) {
            margin-left: 2.5rem;
          }
        }
      }

      &__link {
        padding: 0.25em 0.8em;
        font-size: 4.5em;
        line-height: 1em;
        font-weight: 100;
        letter-spacing: 0.025em;
        color: #fff;
        transition: color 600ms ease;
        position: relative;

        span{
          position: relative;
          z-index: 1;
        }

        @media (min-width: $breakpoint-sm) {
          font-size: 1.9em;
          font-weight: 400;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 0;
            height: 100%;
            border-radius: 2em;
            background-color: #fff;
            opacity: 0;
            transition: opacity 300ms ease, width 300ms ease;
            z-index: 0;
            transform: translateX(-50%);
          }
        }
      }

      &__link--active,
      &__link:hover {
        color: #fff;
        
        @media (min-width: $breakpoint-sm) {
          color: $color-black-main;
          
          &:after {
            opacity: 1;
            width: 100%;
          }
        }
      }

      &__link--active {
        font-weight: 300;

        @media (min-width: $breakpoint-sm) {
          font-weight: 400;
        }
      }
    }
  }

  &--scrolled {
    .nav__menu {        
      &--dark {
        .nav__menu__link {
          @media (min-width: $breakpoint-sm) {
            color: #fff!important;

            &:after {
              background-color: #fff!important;
            }
          }
        }

        .nav__menu__link--active,
        .nav__menu__link:hover {
          @media (min-width: $breakpoint-sm) {
            color: $color-black-main!important;
          }
        }
      }
    }

    &.header--static{
      .nav__menu {        
        &--dark {
          .nav__menu__link {
            @media (min-width: $breakpoint-sm) {
              color: $color-black-main!important;
  
              &:after {
                background-color: $color-black-main!important;
              }
            }
          }
  
          .nav__menu__link--active,
          .nav__menu__link:hover {
            @media (min-width: $breakpoint-sm) {
              color: #fff!important;
            }
          }
        }
      }

      &.header--noChangeColor {
        .nav__menu {        
          &--dark {
            .nav__menu__link {
              @media (min-width: $breakpoint-sm) {
                color: #fff!important;
    
                &:after {
                  background-color: #fff!important;
                }
              }
            }
    
            .nav__menu__link--active,
            .nav__menu__link:hover {
              @media (min-width: $breakpoint-sm) {
                color: $color-black-main!important;
              }
            }
          }
        }
      }
    }
  }

  &--static{
    .nav__menu {        
      &--dark {
        .nav__menu__link {
          @media (min-width: $breakpoint-sm) {
            color: $color-black-main!important;

            &:after {
              background-color: $color-black-main!important;
            }
          }
        }

        .nav__menu__link--active,
        .nav__menu__link:hover {
          @media (min-width: $breakpoint-sm) {
            color: #fff!important;
          }
        }
      }
    }
  }

  &--noChangeColor {
    .nav__menu {
      &--light {
        .nav__menu__link {
          @media (min-width: $breakpoint-sm) {
            color: $color-black-main !important;

            &:after {
              background-color: $color-black-main !important;
            }
          }
        }

        .nav__menu__link--active,
        .nav__menu__link:hover {
          @media (min-width: $breakpoint-sm) {
            color: $color-white !important;
          }
        }
      }
    }
  }
}