.intro {
    min-height: calc(var(--vh, 1vh) * 100);
    padding-top: 17em;
    position: relative;
    @media (max-width: $breakpoint-xs) {
        padding-top: 12em;
        display: flex;
        flex-direction: column;
    }

    &__description {
        line-height: 1.25em;
        //padding-right: 1em;
        //margin: 2.15em 0 3.85em;
        letter-spacing: -.04em;
        @media (max-width: $breakpoint-md) {
            margin-bottom: 1.2em;
            margin-top: 0;
            max-width: 18em;
        }
    }

    &__description strong {
        font-weight: 100;
        color: $color-primary;
    }

    &__text {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      min-height: calc(calc(var(--vh, 1vh) * 100) - 26em);

      @media (max-width: $breakpoint-xs) {
          min-height: 0;
          // margin: 0 0 5em;
      }

      &.intro__text--multi{
        justify-content: flex-start;
      }
    }

    &__nav{
        @media (max-width: $breakpoint-md) {
            margin-bottom: 5em;
        }
    }

    &__navTitle{
      font-weight: 300;
      font-size: 1.8em;
      margin: 0 0 3em;
    }

    &__navList{
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__navList li{
        margin: 0 0 1.2em;
    }

    &__navList li:last-child{
        margin: 0;
    }

    &__navLink{
      text-indent: 0;
      line-height: 1em;
      span {
        color: $color-gray-secondary;
        width: 100%;
        display: inline-block;
        font-weight: 400;
      }
    }

    &__image {
        background: $gradient-orange;
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        padding: 5em;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: stretch;

        @media (max-width: $breakpoint-sm) {
            position: static;
            width: 100%;
            height: auto;
            text-align: center;
        }
        @media (max-width: $breakpoint-xs) {
          flex: 1;
        }

        &--white {
          background-color: #ffffff;
        }
    }

    &__pattern {
        background: #000;
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        .pattern {
          height: 100%;
          width: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          opacity: .1;
          &--01 {
            background-image: url('https://assets-website.451.com/assets/img/patterns/pattern01-white.svg');
          }
          &--02 {
            background-image: url('https://assets-website.451.com/assets/img/patterns/pattern02-white.svg');
          }
          &--03 {
            background-image: url('https://assets-website.451.com/assets/img/patterns/pattern03-white.svg');
          }
          &--04 {
            background-image: url('https://assets-website.451.com/assets/img/patterns/pattern04-white.svg');
          }
          &--05 {
            background-image: url('https://assets-website.451.com/assets/img/patterns/pattern05-white.svg');
          }
          &--06 {
            background-image: url('https://assets-website.451.com/assets/img/patterns/pattern06-white.svg');
          }
          &--07 {
            background-image: url('https://assets-website.451.com/assets/img/patterns/pattern07-white.svg');
          }
        }

        @media (max-width: $breakpoint-sm) {
            display: none;
        }
        @media (max-width: $breakpoint-xs) {
          display: none;
        }

        &--white {
          background-color: #ffffff;
        }
    }

    &__img {
        width: 30em;
        height: auto;
        @media (max-width: $breakpoint-sm) {
            position: static;
            margin: 4em 0 3em 3em;
        }
        @media (max-width: $breakpoint-xs) {
            //width: 40%;
        }
        &--lg {
          width: 100%;
          margin: 0 0 0em -10em;
        }
    }
}
