//your less code
$breakpoint-xl: 1921px;
$breakpoint-lg: 1600px;
$breakpoint-md: 1200px;
//$breakpoint-md: 1170px;
$breakpoint-sm: 991px;
$breakpoint-xs: 767px;
$breakpoint-s: 480px;
$breakpoint-xxs: 359px;
$headerHeight: 23em;
$homeCasesHeight: 25em;
$white-opacity-color: rgba(255,255,255,0.7);

$gradient-orange: linear-gradient(45deg, #c64100, #ff6600);

//COLORS
//$color-primary: #f11f48;
$color-white: #fff;
$color-black: #000;
$color-primary: #ff5400;
$color-black-main: #231f20;
$color-black-highlight: #B4B4B4;
$color-gray-highlight: #808285;
$color-gray-secondary: #383838;
$blue-color: #11357e;

//FONTS
$font-family-main: "Fahrenheit Sans UI";