.policiesArticle {
  width: 100%;
  padding-right: 7em;

  @media (max-width: $breakpoint-sm) {
    padding-right: 0;
  }

  &__header {
    padding: 3em 0 2em;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dddddd;
    margin: 0 0 5em;
  }

  &__tag {
    color: $color-black-main;
    letter-spacing: .2em;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    margin: 0 0 1em;
    color: #a5a5a5;
  }

  &__title {
    font-size: 3.6em;
    font-weight: 300;
    letter-spacing: -0.03em;
    margin: 0 0 .25em;
    line-height: 135%;
    text-indent: -0.05em;
    color: $color-black-main;

    @media (max-width: $breakpoint-xs) {
        font-size: 3em;
    }

    strong{
        font-weight: 100;
        color: $color-black-highlight;
    }
  }

  &__date {
    color: #a5a5a5;
    letter-spacing: .2em;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 700;
    margin: 2em 0 0;
  }

  &__subtitle {
    font-size: 2.2em;
    font-weight: 100;
    line-height: 1.4em;
    margin: .5em 0;
    letter-spacing: 0.01em;

    @media (max-width: $breakpoint-xs) {
        font-size: 2em;
    }
    strong{
        font-weight: 100;
        color: $color-black-highlight;
    }
  }

  &__content {
    margin: 0 0 5em;
    
    @media(max-width: $breakpoint-sm) {
      margin: 0 0 6em;
    }

    h3 {
      font-size: 2.5em;
      font-weight: 300;
      line-height: 100%;
      margin: 0 0 0.5em;
      padding-top: 1em;
      color: rgba(0, 0, 0, 0.8);
    }

    h4 {
      font-size: 2.1em;
      font-weight: 300;
      line-height: 100%;
      margin: 0 0 0.5em;
      padding-top: 1em;
      color: rgba(0, 0, 0, 0.6);
    }

    p, 
    li {
      font-size: 1.7em;
      line-height: 1.77em;
      color: rgba(0,0,0,0.6);
      font-weight: 300;
      letter-spacing: 0.01em;
      margin: 0 0 1.5em;
    }

    p {
      strong {
        font-weight: 400;
      }
    }

    a {
      color: #777;
      text-decoration: underline;
    }

    ul {
      padding: 0 0 0 3.4em;
      margin: 0 0 2.55em;
      
      li{
        list-style: none;
        margin: 0;
        padding: 0 0 10px 16px;
        position: relative;
        line-height: 150%;

        & > ul{
          padding: 1em 0 0 1.5em;

          li{
            font-size: 1em;
            line-height: 1.5em;
            margin: 0;
            padding: 0 0 10px 19px;

            &:before {
              content: ' ';
              background-color: transparent;
              border: 2px solid $color-black-main;
              width: 7px;
              height: 7px;
              display: inline-block;
              border-radius: 100%;
              position: absolute;
              top: 8.4px;
              left: 0;
            }
          }
        }
        
        strong{
          color: rgba(0, 0, 0, 0.9);
          font-weight: 300;
        }

        &:before {
          content: ' ';
          background-color: $color-black-main;
          width: 5px;
          height: 5px;
          display: inline-block;
          border-radius: 100%;
          position: absolute;
          top: 10px;
          left: 0;
        }

        &:last-child{
          padding-bottom: 0;
        }
      }
    }

    *:last-child{
      margin: 0;
    }
  }

  &__outsideImg {
      width: calc(15vw + 100%);
      height: auto;
      margin: 0 0 2em 0;
      max-width: 100%;

      @media (max-width: $breakpoint-sm) {
          width: calc(100% + 4em);
          margin-left: -2em;
      }
  }

  &__form{
    padding-left: 11.55%;
    margin: 5em 0;

    @media (max-width: $breakpoint-sm) {
        padding: 0;
    }
    
    p {
        font-size: 1.7em;
        line-height: 1.77em;
        color: rgba(0,0,0,0.5);
        font-weight: 300;
        letter-spacing: -.01em;
    }
  }
}


.policiesSideBar {
  padding-top: 3em;

  @media (max-width: $breakpoint-sm) {
    padding-top: 0;
  }

  .policies-item{
    margin-bottom: 4em;

    &:last-child{
      margin-bottom: 0;

      &:before{
        display: none;
      }
    }

    &:before{
      @media (max-width: $breakpoint-sm) {
        display: none;
      }

      @media (max-width: $breakpoint-xs) {
        display: block;
      }
    }
  
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  
    @media(max-width: $breakpoint-xs) {
      margin-bottom: 2.5em;
      padding-bottom: 1.5em;
    }
  }

  .policiesListing {
    &__tag {
      color: #a5a5a5;
    }

    &__title {
      margin: 0 0 0.2em;

      @media (max-width: $breakpoint-sm) {
        min-height: 0;
      }

      @media(max-width: $breakpoint-xs) {
        min-height: 0;
      }
    }
  }
}