/* Text Animation */
.progressLineText {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  // background-color: #1A1A1A;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: opacity 1000ms ease-in-out;
  opacity: 1;
  pointer-events: all;

  .container{
    height: 100%;
  }

  &__inner{
    height: 100%;
    position: relative;
  }

  &__animation{
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  &__phase{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 0;
    pointer-events: none;
    margin-top: -5em;

    &Inner{
      width: 100%;
      max-width: 99em;

      .numberPhase{
        opacity: 0;
        display: block;
        margin-bottom: 1.5em;

        span{
          &.number{
            background: rgb(251,67,20);
            background: -moz-linear-gradient(40deg, rgba(251,67,20,1) 0%, rgba(253,212,41,1) 100%);
            background: -webkit-linear-gradient(40deg, rgba(251,67,20,1) 0%, rgba(253,212,41,1) 100%);
            background: linear-gradient(40deg, rgba(251,67,20,1) 0%, rgba(253,212,41,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fb4314",endColorstr="#fdd429",GradientType=1);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
            color: #fff;
            font-weight: 300;
            letter-spacing: -0.07em;
            line-height: 105%;
            font-size: 4em;
            padding: 0 2px 0 0;
          }
        }
      }

      p{
        color: #fff;
        font-weight: 100;
        letter-spacing: -0.04em;
        line-height: 100%;
        font-size: 9em;
        margin: 0;
        padding: 0;
        width: 100%;
        flex: 0 0 auto;
        opacity: 0;

        span{
          display: block;
          opacity: 0;
        }

        strong{
          display: block;
          font-weight: 800;
          letter-spacing: -0.035em;
          opacity: 0;
        }

        &.nowrap{
          white-space: nowrap;

          span{
            display: inline;
          }
  
          strong{
            display: inline;
            font-weight: 800;
            letter-spacing: -0.05em;
          }
        }

        &.big{
          font-size: 11em;
          line-height: 106%;

          strong{
            letter-spacing: -0.015em;
          }

          @media(max-width: $breakpoint-md) {
            font-size: 7.5vw;
          }
        }
      }

      .attach{
        flex: 1 1 auto;
        width: 100%;
        min-width: 0.1em;
        display: flex;
        align-items: center;
        padding-bottom: 1.6em;

        .line{
          flex: 1 1 auto;
          width: 100%;
          height: 0.5px;
          min-width: 0.1em;
          padding: 0 3em;
          margin-top: 1.3em;
          opacity: 0.7;

          span{
            width: 0;
            height: 100%;
            display: block;
            background-color: #fff;
          }
        }

        span{
          &.text{
            font-size: 2.5em;
            color: #808285;
            font-weight: 200;
            letter-spacing: -0.04em;
            line-height: 105%;
            display: block;
            flex: 0 0 auto;
            white-space: nowrap;
            opacity: 0;
  
            strong{
              font-weight: 700;
            }
          }
        }
      }

      &.flex{
        display: flex;
        align-items: flex-end;
        max-width: 100%;

        p{
          width: auto;
        }
      }
    }

    &.active{
      pointer-events: all;
      opacity: 1;
      transition: opacity 1s ease;
    }

    &.inactive{
      animation: animOpacity 1s ease 0s forwards;

      @keyframes animOpacity {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }

    &--01{
      &.active{
        p{
          animation: animPhase01 1s ease 0s forwards;

          span,
          strong{
            opacity: 1;
          }
        }

        .attach{  
          .line{  
            span{
              animation: animPhase01Line 1.2s cubic-bezier(0.65, 0.05, 0.36, 1) 500ms forwards;
            }
          }
  
          span{
            &.text{
              animation: animPhase01LineText 1s ease 1.4s forwards;
            }
          }
        }
        
        @keyframes animPhase01 {
          0% {
            transform: translateY(7rem);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        @keyframes animPhase01Line {
          0% {
            width: 0;
          }
          100% {
            width: 100%;
          }
        }

        @keyframes animPhase01LineText {
          0% {
            transform: translateX(-3rem);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }

    &--02,
    &--03{
      &.active{
        .numberPhase{
          animation: animNumber 1s ease 300ms forwards;
        }

        p{
          opacity: 1;

          span,
          strong{
            animation: animPhases 1s ease 0s forwards;
          }

          strong{
            animation-delay: 300ms;
          }
        }

        @keyframes animNumber {
          0% {
            transform: translateY(-3rem);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        @keyframes animPhases {
          0% {
            transform: translateY(7rem);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }

  &__progress{
    position: absolute;
    bottom: 5em;
    left: 0;
    width: 100%;
    z-index: 2;
    opacity: 0;

    .text{
      width: 100%;
      padding-bottom: 4em;

      ul{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li{
          list-style-type: none;
          margin: 0;
          padding: 0;

          span{
            font-size: 1.5em;
            font-weight: 200;
            color: $color-gray-highlight;
            line-height: 105%;
            letter-spacing: 0.01em;
          }
        }
      }
    }

    .progressLine{
      width: 100%;
      height: 0.4em;
      background-color: rgba(255, 255, 255, 0.14);
      overflow: hidden;
      position: relative;
      border-radius: 0.4em;

      span{
        height: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: rgb(251,67,20);
        background: linear-gradient(90deg, rgba(251,67,20,1) 0%, rgba(253,147,32,1) 100%);
        width: 0%;
        display: block;
        border-radius: 0.4em;
      }
    }

    &.active{
      animation: revealProgressLine 400ms linear 0s forwards;

      @keyframes revealProgressLine {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .progressLine {
        span{
          animation: progressLine 9.5s linear 0s forwards;

          @keyframes progressLine {
            0% {
              width: 0%;
            }
            100% {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.soon30 {
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4em;
    
    @media (max-width: $breakpoint-sm) and (orientation: portrait) {
      margin-top: -8rem;
    }

    @media (max-width: $breakpoint-sm) and (orientation: landscape) {
      padding-top: 0;
    }

    @media (min-width: $breakpoint-md) {
      padding-top: 8em;
    }

    .letter {
      font-size: 42.5vw;
      color: $color-black-main;
      position: relative;
      letter-spacing: -0.04em;
      font-weight: 200;
      animation: opacity 1s both;
      line-height: 0.8;

      @media (max-width: $breakpoint-sm) and (orientation: landscape) {
        margin-top: 7rem;
      }

      @media (min-width: $breakpoint-xs) {
          font-size: 36.8em;
      }
      
      @media (min-width: $breakpoint-sm) {
        font-size: 43.2em;
      }

      @media (min-width: $breakpoint-md) {
        font-size: 52.6em;
      }

      &:nth-child(1) {
        animation-delay: 1.5s;
      }
      &:nth-child(2) {
        animation-delay: 2s;
      }
      &:nth-child(3) {
        animation-delay: 2.5s;
      }
      &:nth-child(4) {
        animation-delay: 3s;
      }

      &:nth-child(2),
      &:nth-child(3) {
        color: $color-white;
      }

      &:last-child {
        margin-left: -0.035em;
      }

      .soon-thirty {
        font-size: 4vw;
        font-weight: 400;
        position: absolute;
        top: 41%;
        left: 5.5vw;
        display: inline-block;
        text-align: center;
        line-height: 1.1;        
        max-width: 135px;
        color: $color-black-main;

        @media (max-width: $breakpoint-sm) and (orientation: landscape) {
          font-size: 2.2rem;
          max-width: initial;
        }

        @media (min-width: $breakpoint-sm) {
          font-size: 3rem;
          left: 30%;
          max-width: initial;
        }

        @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
          font-size: 2.8rem;
          max-width: initial;
          left: 25%;
        }

        @media (min-width: $breakpoint-md) {
          font-size: 3.5rem;
        }

        span {
          animation: opacity 1.1s both;
          letter-spacing: -0.02rem;

          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              animation-delay: 3.2 + ($i * 0.1s);
            }
          }
        }
      }
    }
  }

  &__description {
    @media (max-width: $breakpoint-sm) and (orientation: landscape) {
      display: none;
    }

    h1 {
      text-align: right;
      font-weight: 200;
      color: $color-white;
      line-height: 1.1;
      letter-spacing: -0.01em;
      font-size: 2.5em;
      margin-bottom: 0.5em;
      animation: opacity 1s both;
      animation-delay: 5s;
      margin: 0;
      display: flex;
      gap: 3rem;
      justify-content: center;
      margin: 2rem 0;
      
      @media (min-width: $breakpoint-md) {
        gap: 4rem;
        font-size: 3.2em;
        max-width: 500px;
        margin: 4rem auto 3rem;
      }

      span {
        color: $color-black;
        position: relative;
        font-weight: 600;
        text-align: left;
        
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: -1.5rem;
          width: 1px;
          height: 100%;
          background-color: $color-white;
          
          @media (min-width: $breakpoint-md) {
            left: -2rem;
          }
        }
      }

    }

    p {
      text-align: center;
      font-size: 1.9em;
      font-weight: 200;
      line-height: 1.3;
      margin: 0 auto;
      animation: opacity 1s both;
      animation-delay: 6s;
      
      @media (min-width: $breakpoint-md) {
        max-width: 90%;
        font-size: 2.5em;
      }
    }
  }

  &__thumbs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    overflow: hidden;
    margin: 5rem 0 0;
    animation: opacity 1s both;
    animation-delay: 7s;
    position: absolute;
    bottom: 0;
    left: 0;

    @media (max-width: $breakpoint-sm) and (orientation: landscape) {
      display: none;
    }
    
    @media (min-width: $breakpoint-sm) {
      grid-template-columns: repeat(8, 1fr);
      border-radius: 1rem;
      position: relative;
      bottom: auto;
      left: auto;
    }

    a {
      overflow: hidden;
      position: relative;

      &:hover {
        .soon30__thumbs-info {
          opacity: 1;
        }

        img {
          transform: scale(1.2);
        }
      }
      
      img {
        width: 100%;
        height: 70px;
        object-fit: cover;
        transition: all 0.5s ease;
        
        @media (min-width: $breakpoint-md) {
          height: 100px;
        }
      }

    }

    &-info {
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      transition: all 0.3s ease;
      background-color: rgba(0, 0, 0, 0.65);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: end;
      justify-content: start;
      padding: 1rem;

      @media (min-width: $breakpoint-md) {
        padding: 1rem 1.5rem;
      }

      .btnLink {
        font-size: 0.95rem !important;
        
        @media (min-width: $breakpoint-md) {
          font-size: 1.25rem !important;
        }

        .arrow-icon {
          width: 1.4rem;
          height: 1.4rem;
          
          @media (min-width: $breakpoint-md) {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }

  }

  // &__btn{
  //   width: 100%;
  //   padding-top: 3em;
  //   animation: opacity 1s both;
  //   animation-delay: 7s;
  //   text-align: center;

  //   a{
  //     border: 2px solid $color-white;
  //     background-color: transparent;
  //     border-radius: 5em;
  //     width: 100%;
  //     max-width: 15em;
  //     padding: 1.1em 1em 0.9em;
  //     margin: 0;
  //     transition: background-color 300ms ease;
  //     display: inline-flex;
  //     flex-direction: row;
  //     align-items: center;
  //     justify-content: center;

  //     span{
  //       font-size: 1.5em;
  //       color: $color-white;
  //       line-height: 100%;
  //       font-weight: 400;
  //       transition: color 300ms ease;
  //       display: inline-block;
  //       padding-left: 0.5em;
  //     }

  //     .arrow-icon {
  //       position: relative;
  //       top: 0;
  //       transition: transform 0.3s ease;
  //       vertical-align: middle;
        
  //       g{
  //         stroke: $color-black;
  //         transition: stroke 400ms ease;

  //         @media (min-width: 992px) {    
  //           stroke: $color-white;
  //         }
  //       }
        
  //       &--circle {
  //           transition: stroke-dashoffset 0.3s ease;
  //           stroke-dasharray: 95;
  //           stroke-dashoffset: 95;
  //       }
  //     }

  //     &:hover{
  //       background-color: $color-white;
        
  //       span{
  //         color: $color-black-main;
  //       }

  //       .arrow-icon {
  //         transform: translate3d(5px, 0, 0);

  //         g{
  //           stroke: $color-primary;
  //         }

  //         &--circle {
  //             stroke-dashoffset: 0;
  //         }
  //       }
  //     }
  //   }
  // }
}