.page {
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  background-color: #fff;
  color: $color-black-main;
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  &--fixed{
    overflow: visible;
  }

  &-fixed-content{
    background-color: #fff;
    position: relative;
    //box-shadow: 0px -15px 17px 6px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  .backgroundFixed{
    background-color: #000;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 3;
    pointer-events: none;
  }

  &--padding {
    padding-top: 20em;
    padding-bottom: 5em;
    @media(max-width: $breakpoint-xs) {
        padding-top: 13em;
        padding-bottom: 3em;
    }
  }

  &--pattern {
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .015;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    &--01 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern01.svg');}
    }
    &--02 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern02.svg');}
    }
    &--03 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern03.svg');}
    }
    &--04 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern04.svg');}
    }
    &--05 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern05.svg');}
    }
    &--06 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern06.svg');}
    }
    &--07 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern07.svg');}
    }
  }

  &--patternFix {
    position: relative;
    
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .015;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom;
    }

    &--01 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern01.svg');}
    }
    &--02 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern02.svg');}
    }
    &--03 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern03.svg');}
    }
    &--04 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern04.svg');}
    }
    &--05 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern05.svg');}
    }
    &--06 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern06.svg');}
    }
    &--07 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern07.svg');}
    }
    &--08 {
      &::before {background-image: url('https://assets-website.451.com/assets/img/patterns/pattern08.svg');}
    }
  }

}

.pageChangeColor{
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  background-color: rgb(255, 255, 255);
  color: $color-black-main;
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  .page,
  footer{
    background: transparent;
  }
}